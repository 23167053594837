import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import { Text } from "@ui/typography";

import { ERoutes } from "@utils/constants";

import { Button, Link } from "@/ui-kit";

import * as Styled from "../styles";
import { THandleNextStepProp } from "../utils";

export const Final: FC<Pick<THandleNextStepProp, "type"> & { onClose: () => void }> = ({ type, onClose }) => {
  const { t: tCaption } = useTranslation("questionFlow", { keyPrefix: `finalSteps` });
  const { t } = useTranslation("questionFlow", { keyPrefix: `finalSteps.${type}` });
  const navigate = useNavigate();

  const handleClose = (route: ERoutes) => {
    onClose();
    setTimeout(() => navigate(route), 300);
  };

  return (
    <Styled.ContentWrapper>
      <Text isHeading size={1.5} isFullWidth textAlign="center" mb="28px" lineHeight={140}>
        {t("title")}
      </Text>
      <Styled.StyledDivider />
      <Text isFullWidth textAlign="center" mb="32px">
        {t("description")}
      </Text>
      {type === "giveCredit" ? (
        <Stack direction="row" justifyContent="space-between" sx={{ mb: "32px" }}>
          <Button isBigButton width={271} onClick={() => handleClose(ERoutes.FreeUseTerms)}>
            {t("firstButtonText")}
          </Button>
          <Button isBigButton width={271} onClick={() => handleClose(ERoutes.Catalog)}>
            {t("secondButtonText")}
          </Button>
        </Stack>
      ) : (
        <Button isBigButton isFullWidth onClick={() => handleClose(ERoutes.Licensing)} sx={{ mb: "32px" }}>
          {t("buttonText")}
        </Button>
      )}
      <Text isFullWidth textAlign="center" size={0.875} color="#fff">
        <Trans
          t={tCaption}
          i18nKey="caption"
          components={{ contactLink: <Link href={ERoutes.Contact} target="_self" /> }}
        />
      </Text>
    </Styled.ContentWrapper>
  );
};
