import { DefaultRootState } from 'react-redux';

import { createSelector } from '@reduxjs/toolkit';

import { TermsState } from '@/store/reducers/terms';

export const getTermsText = createSelector(
  (state: DefaultRootState) => state.terms,
  ({ terms }: TermsState) => terms
);

export const isLoadingTerms = createSelector(
  (state: DefaultRootState) => state.terms,
  ({ loading }: TermsState) => loading
);
