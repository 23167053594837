import { createSlice } from "@reduxjs/toolkit";

import { IFilters, ITags } from "@/models/models";
import { tags } from "@/store/actions/filters";

export interface FiltersState {
  filters: IFilters;
  tags: ITags[];
  loading: boolean;
  loadingFilters: boolean;
}

const initialFilters: IFilters = {
  composer: [],
  tags: [],
  length: {},
  bpm: {},
};

const initialTags: ITags[] = [];

const initialState: FiltersState = {
  filters: initialFilters,
  tags: initialTags,
  loading: true,
  loadingFilters: true,
};

const { reducer } = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetFiltersState: (state) => {
      state.filters = { ...initialFilters };
    },
    setFiltersState: (state, { payload }) => {
      state.filters = { ...payload };
      state.loadingFilters = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tags.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.tags = payload as ITags[];
      state.loading = false;
    });
  },
});

export default reducer;
