import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import { Button as GradientButton } from "@ui/index";

import { DescriptionTypography } from '@/components/LoginDialog/components';
import {
  AdditionalTextTypography,
  MainTextTypography,
} from '@/components/RecoveryDialog/components';
import { useIsDesktop } from '@/hooks/layout';
import { sendVerificationLink } from '@/store/actions/user';
import { useAppDispatchUnwrap } from '@/store/hooks';

import { formatTime, ONE_SECOND, THRITY_SECONDS } from '../utils';

const SecondStep = () => {
  const { t } = useTranslation('login', {
    keyPrefix: 'passwordRecovery.secondStep',
  });

  const isDesktop = useIsDesktop();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(THRITY_SECONDS);

  const userEmail = localStorage.getItem('email');

  const dispatch = useAppDispatchUnwrap();

  const handleClick = async () => {
    setIsButtonDisabled(true);

    await dispatch(sendVerificationLink({ email: userEmail }));

    setTimer(THRITY_SECONDS);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            setIsButtonDisabled(false);
            clearInterval(interval);
          }
          return prevTimer - 1;
        });
      }, ONE_SECOND);
    }

    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  return (
    <Stack
      spacing={isDesktop ? '40px' : '24px'}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={isDesktop ? '24px' : '16px'} height="100%" width="100%">
        <Stack spacing="16px" justifyContent="center" alignItems="center">
          <MainTextTypography>{t('mainText')}</MainTextTypography>
          <DescriptionTypography>
            <Trans t={t} i18nKey="description" />
          </DescriptionTypography>
        </Stack>
      </Stack>

      <Stack
        width="100%"
        spacing="16px"
        justifyContent="center"
        alignItems="center"
      >
        <AdditionalTextTypography>
          <Trans
            t={t}
            i18nKey="receive"
            components={[
              <span style={{ color: '#FFDCA8', fontWeight: 500 }} />,
            ]}
            values={{ timer: formatTime(timer) }}
          />
        </AdditionalTextTypography>

        <Stack width="100%">
          <GradientButton isFullWidth onClick={handleClick} isDisabled={isButtonDisabled}>
            {t('buttonText')}
          </GradientButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SecondStep;
