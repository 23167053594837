import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Logout } from "@mui/icons-material";
import { AppBar, Divider, Drawer, Grid, Stack, Toolbar, Typography } from "@mui/material";

import { Button as GradientButton, IconButton } from "@ui/index";

import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { ERoutes } from "@utils/constants";

import MainMenuBg from "@/assets/png/MainMenu/MainMenuBG.png";
import { BurgerMenu, CloseIcon, QualificationTestIcon, SettingsIcon, ShopIcon } from "@/assets/svg";
import { StyledDivider } from "@/components/components";
import { useAuth, useLogOut } from "@/hooks/auth";
import { SecondaryBurgerMenuNavLink } from "@/layouts/components/components";
import { ProfileAvatar } from "@/pages/Profile/components";
import { useAppSelector } from "@/store/hooks";
import { getFavoritesState } from "@/store/selectors/getFavorites";

import { FavoritesHeartIcon, GeneralLogo, MobileMenuLogo } from "../../assets/svg";

interface IBurgerMenuProps {
  burgerMenu: boolean;
  openBurgerMenu: () => void;
  closeBurgerMenu: () => void;
  handleOpenDialog: (isPage: boolean) => void;
}

const SecondaryBurgerMenu: FC<IBurgerMenuProps> = ({
  openBurgerMenu,
  closeBurgerMenu,
  burgerMenu,
  handleOpenDialog,
}) => {
  const { t } = useTranslation("navigation");
  const { isLoggedIn, isLoading, myData } = useAuth();

  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const [isAccountMenu, setIsAccountMenu] = useState(false);

  const { logoutUser, resetUser } = useLogOut();

  const navigate = useNavigate();

  const handleOpenAccountMenu = () => {
    setIsAccountMenu(true);
    openBurgerMenu();
  };

  const handleOpenBurgerMenu = () => {
    setIsAccountMenu(false);
    openBurgerMenu();
  };

  const handleNavigate = (route: ERoutes) => {
    closeBurgerMenu();
    setIsAccountMenu(false);
    setTimeout(() => {
      navigate(route);
    }, 500);
  };

  const handleClose = () => {
    closeBurgerMenu();
    setIsAccountMenu(false);
    setTimeout(() => {
      logoutUser();
      resetUser();
    }, 500);
  };

  const favoritesState = useAppSelector(getFavoritesState);

  return (
    <AppBar
      position="relative"
      sx={{
        background: `#59412E`,
        backgroundPosition: "center",
        boxShadow: 0,
      }}
    >
      <Toolbar sx={{ px: "1rem", py: "1rem" }}>
        <Stack sx={{ maxHeight: "24px" }}>
          <Link to={isLoggedIn ? ERoutes.Catalog : ERoutes.Home}>
            <GeneralLogo style={{ height: "24px", maxWidth: "176px" }} />
          </Link>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: "100%" }}>
          <Grid item md="auto">
            <Stack direction="row" width="100%" alignItems="center" justifyContent="flex-end">
              {isLoggedIn && !isLoading ? (
                <Stack
                  sx={{
                    marginRight: "12px",
                  }}
                >
                  <IconButton
                    isTransparent
                    onClick={handleOpenAccountMenu}
                    size="small"
                    sx={{ padding: 0, justifyContent: "space-around" }}
                  >
                    <ProfileAvatar
                      sx={{
                        cursor: "pointer !important",
                        width: `32px !important`,
                        height: `32px !important`,
                        fontSize: `1.25rem !important`,
                        "&:hover": {
                          borderColor: "#ffdca8",
                          backgroundColor: "#5C412A",
                          transition: "border 0.3s ease-in-out, background-color 0.3s ease-in-out",
                        },
                      }}
                      alt={myData?.firstName.toUpperCase()}
                      src="#"
                    />
                  </IconButton>
                </Stack>
              ) : (
                <GradientButton sx={{ marginRight: "16px" }} isIconButtonLike onClick={() => handleOpenDialog(false)}>
                  {t("signIn")}
                </GradientButton>
              )}
            </Stack>
          </Grid>
          <IconButton
            isTransparent
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpenBurgerMenu}
            sx={{ p: 0 }}
          >
            <BurgerMenu />
          </IconButton>
        </Stack>

        <Drawer
          anchor="right"
          variant="temporary"
          open={burgerMenu}
          onClose={closeBurgerMenu}
          PaperProps={{
            sx: {
              backgroundImage: `url(${MainMenuBg})`,
              backgroundColor: "#302317",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              padding: "1rem",
            },
          }}
        >
          {isAccountMenu ? (
            <>
              <Stack gap={"32px"}>
                <Stack direction="row" justifyContent="flex-end">
                  <IconButton isTransparent onClick={closeBurgerMenu}>
                    <CloseIcon width="18px" />
                  </IconButton>
                </Stack>
                <Stack direction="column" justifyContent="center" alignItems={"center"} gap={"8px"}>
                  <ProfileAvatar
                    sx={{
                      cursor: "pointer !important",
                      width: `48px !important`,
                      height: `48px !important`,
                      fontSize: `26px !important`,
                    }}
                    alt={myData?.firstName.toUpperCase()}
                    src="#"
                  />
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontFamily: "Kaisei Opti",
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "textColor.subtitle",
                    }}
                  >
                    {myData?.firstName}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "14px",
                      textAlign: "center",
                      color: "textColor.subtitle",
                    }}
                  >
                    {myData?.email}
                  </Typography>
                </Stack>
                <GradientButton
                  isOutlined
                  isFullWidth
                  isBigButton
                  onClick={() => {
                    closeBurgerMenu();
                    setIsAccountMenu(false);
                    setTimeout(() => {
                      setOpenQuestionFlow(true);
                    }, 500);
                  }}
                >
                  <Stack direction="row" spacing="8px" alignItems="center" justifyContent="center">
                    <QualificationTestIcon />
                    <div>{t("freeUseQualificationTest")}</div>
                  </Stack>
                </GradientButton>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems={"center"}
                  gap={"8px"}
                  onClick={() => {
                    handleNavigate(ERoutes.Favorites);
                  }}
                >
                  <Stack
                    sx={{
                      position: "relative",
                      "& svg path": {
                        stroke: "#E0D2C1",
                      },
                    }}
                  >
                    <FavoritesHeartIcon />
                  </Stack>

                  <Stack direction={"row"} gap={"8px"}>
                    <Typography
                      sx={{
                        fontFamily: "Raleway",
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "34px",
                        textAlign: "left",
                        color: "textColor.common",
                      }}
                    >
                      {t("favorites").toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Kantumruy",
                        fontSize: "24px",
                        fontWeight: "300",
                        lineHeight: "34px",
                        textAlign: "left",
                        color: "textColor.common",
                      }}
                    >
                      {"( " + favoritesState.length + " )"}
                    </Typography>
                  </Stack>
                </Stack>

                <Divider
                  sx={{
                    maxWidth: "80%",
                    borderColor: "#635041",
                    marginLeft: "10%",
                  }}
                />

                <Stack gap={"32px"}>
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "20px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "textColor.common",
                    }}
                    onClick={() => handleNavigate(ERoutes.Deals)}
                  >
                    {t("bestDeals").toUpperCase()}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "20px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "textColor.common",
                    }}
                  >
                    {t("musicCreatorProgram").toUpperCase()}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "20px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "textColor.common",
                    }}
                    onClick={() => handleNavigate(ERoutes.DownloadInstruction)}
                  >
                    {t("downloadInstructions").toUpperCase()}
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    maxWidth: "80%",
                    borderColor: "#635041",
                    marginLeft: "10%",
                  }}
                />

                <Stack direction="column" justifyContent="center" alignItems={"center"} gap={"32px"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"8px"}
                    onClick={() => handleNavigate(ERoutes.Profile)}
                  >
                    <Stack
                      sx={{
                        "& svg": {
                          width: "32px",
                          height: "32px",
                        },
                      }}
                    >
                      <SettingsIcon />
                    </Stack>
                    <Typography
                      sx={{
                        fontFamily: "Raleway",
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "34px",
                        textAlign: "left",
                        color: "textColor.common",
                      }}
                    >
                      {t("mobileSettings").toUpperCase()}
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap="8px" onClick={handleClose}>
                    <Logout
                      sx={{
                        width: "32px",
                        height: "32px",
                        color: "textColor.common",
                      }}
                      fontSize="small"
                    />
                    <Typography
                      sx={{
                        fontFamily: "Raleway",
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "34px",
                        textAlign: "left",
                        color: "textColor.common",
                      }}
                    >
                      {t("logOut").toUpperCase()}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" justifyContent="flex-end">
                <IconButton isTransparent onClick={closeBurgerMenu}>
                  <CloseIcon width="18px" />
                </IconButton>
              </Stack>
              <Stack direction="row" justifyContent="center">
                <MobileMenuLogo
                  onClick={() => {
                    handleNavigate(ERoutes.Home);
                  }}
                  style={{ height: "40px", width: "auto", marginTop: "32px" }}
                />
              </Stack>

              <Divider
                sx={{
                  maxWidth: "60%",
                  borderColor: "#635041",
                  marginLeft: "20%",
                  marginBottom: "32px",
                  marginTop: "32px",
                }}
              />

              <Stack
                alignItems="center"
                justifyContent="space-around"
                height={"100%"}
                spacing="24px"
                direction="column"
                sx={{
                  paddingTop: "0",
                  paddingBottom: "80px",
                }}
              >
                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    handleNavigate(ERoutes.Catalog);
                  }}
                >
                  {t("music")}
                </SecondaryBurgerMenuNavLink>
                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    handleNavigate(ERoutes.FreeUseTerms);
                  }}
                >
                  {t("Free Use")}
                </SecondaryBurgerMenuNavLink>
                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    handleNavigate(ERoutes.OurArtists);
                  }}
                >
                  {t("ourArtists")}
                </SecondaryBurgerMenuNavLink>
                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    handleNavigate(ERoutes.Licensing);
                  }}
                >
                  {t("licensing")}
                </SecondaryBurgerMenuNavLink>
                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    handleNavigate(ERoutes.Submit);
                  }}
                >
                  {t("submit")}
                </SecondaryBurgerMenuNavLink>
                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    handleNavigate(ERoutes.Contact);
                  }}
                >
                  {t("contacts")}
                </SecondaryBurgerMenuNavLink>

                <SecondaryBurgerMenuNavLink
                  onClick={() => {
                    window.open(ERoutes.Shop);
                  }}
                >
                  <Stack sx={{ alignItems: "center", flexDirection: "row", gap: "4px" }}>
                    {t("shop")}
                    <ShopIcon />
                  </Stack>
                </SecondaryBurgerMenuNavLink>
              </Stack>
            </>
          )}
        </Drawer>
      </Toolbar>
      <StyledDivider $isHeader />
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
    </AppBar>
  );
};

export default SecondaryBurgerMenu;
