export const formatBpmSlider = (value: string[] | number[]) => {
  if (Array.isArray(value) && value.length === 2) {
    const [startString, endString] = value;
    const start = Number(startString);
    const end = Number(endString);

    return `${start} - ${end} bpm`;
  }

  return "";
};
