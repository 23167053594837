import { TBottomPlayer } from "./index";

const TRACK_INFO = "as:track-info";

export const setTrack = (value: TBottomPlayer) => {
  localStorage.setItem(TRACK_INFO, JSON.stringify(value));
};

export const getTrack = () => {
  return JSON.parse(localStorage.getItem(TRACK_INFO));
};
