import { FC, ReactNode } from "react";

import { Dialog, DialogContent, Stack } from "@mui/material";

import { useIsDesktop } from "@/hooks/layout";
import { CloseButton } from "@/ui-kit";

import { StyledBackDrop } from "./components";

interface IGradientDialog {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
  scroll?: "body" | "paper";
  isClosable?: boolean;
}

const GradientDialog: FC<IGradientDialog> = ({ open, children, handleClose, scroll, isClosable = true }) => {
  const isDesktop = useIsDesktop();

  return (
    <Dialog open={open} BackdropComponent={StyledBackDrop} scroll={scroll}>
      <Stack
        sx={{
          background: "linear-gradient(to right bottom, rgba(51,38,28,1) 0%, rgba(79,57,38,1) 100%)",
        }}
        width="100%"
      >
        <DialogContent>
          <Stack
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
            padding={isDesktop ? "40px" : "24px"}
          >
            {isClosable && (
              <Stack
                width="100%"
                sx={{ top: 0, transform: "translateZ(0)" }}
                alignItems="end"
                justifyContent="start"
                position="absolute"
                padding={isDesktop ? "16px" : "8px"}
                zIndex={10}
              >
                <Stack position="fixed">
                  <CloseButton onClick={handleClose} />
                </Stack>
              </Stack>
            )}
            {children}
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default GradientDialog;
