import { createSlice } from "@reduxjs/toolkit";

import { searchAlbum } from "../../actions/album";
import { searchComposer, TComposersResponse } from "../../actions/composers";
import { searchTrack, TAlbum, TTrack } from "../../actions/track";

export type SearchResultsState = {
  albums?: TAlbum[];
  tracks?: TTrack[];
  composers?: TComposersResponse[];
};

const initialState: SearchResultsState = {};

const { reducer } = createSlice({
  name: "searchResults",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchAlbum.fulfilled, (state, { payload }) => {
        state.albums = payload;
      })
      .addCase(searchTrack.fulfilled, (state, { payload }) => {
        state.tracks = payload;
      })
      .addCase(searchComposer.fulfilled, (state, { payload }) => {
        state.composers = payload;
      })
      .addCase(searchAlbum.rejected, (state) => {
        state.albums = initialState.albums;
      })
      .addCase(searchTrack.rejected, (state) => {
        state.tracks = initialState.tracks;
      })
      .addCase(searchComposer.rejected, (state) => {
        state.composers = initialState.composers;
      });
  },
});

export default reducer;
