import { Typography } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

export const Logo = styled.img`
  width: 80px;
  height: auto;

  ${isSmallDesktop} {
    width: 80px;
  }
`;

export const DialogTitleTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  letter-spacing: 0.01125rem;
  text-transform: uppercase;

  ${isSmallDesktop} {
    font-size: 1.25rem;
    line-height: 140%;
    letter-spacing: 0.0125rem;
  }
`;

export const HaveAnAccountTypography = styled.p`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
`;

export const DescriptionTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  text-align: center;
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */

  ${isSmallDesktop} {
    font-size: 1rem;
    line-height: 150%;
  }
`;
