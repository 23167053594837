import PatrickLenkAvatar from "@assets/webp/composers/patrick_lenk_avatar.webp";
import SimonDaumAvatar from "@assets/webp/composers/simon_daum_avatar.webp";

import { ComposersName } from "@utils/constants";

export const getComposerAvatar = (composerName: string): string | undefined => {
  const map: Record<ComposersName, string> = {
    [ComposersName.PATRICK_LENK]: PatrickLenkAvatar,
    [ComposersName.SIMON_DAUM]: SimonDaumAvatar,
  };

  return map[composerName] ?? undefined;
};
