import { getAllTracks, TTrack } from "@store/actions/track";

import { createSlice } from "@reduxjs/toolkit";

import { ICatalogue, ICatalogueFilters } from "@/models/models";
import { catalogue, catalogueFilters } from "@/store/actions/catalogue";

export interface CatalogueState {
  catalogue: { data: ICatalogue[]; pageCount: number };
  tracks: { data: TTrack[]; pageCount: number };
  catalogueFilters: ICatalogueFilters;
  catalogSearchResults: ICatalogue[];
  loading: boolean;
}

const InitialCatalogue: { data: ICatalogue[]; pageCount: number } = { data: [], pageCount: 0 };
const InitialTracks: { data: TTrack[]; pageCount: number } = { data: [], pageCount: 0 };
const InitialCatalogueFilters: ICatalogueFilters = {} as ICatalogueFilters;
const InitialSearchResults: ICatalogue[] = [];

const initialState: CatalogueState = {
  catalogue: InitialCatalogue,
  tracks: InitialTracks,
  catalogueFilters: InitialCatalogueFilters,
  catalogSearchResults: InitialSearchResults,
  loading: true,
};

const { actions, reducer } = createSlice({
  name: "catalogue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(catalogue.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.catalogue = payload as { data: ICatalogue[]; pageCount: number };
        state.loading = false;
      })
      .addCase(catalogue.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTracks.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.tracks = payload;
        state.loading = false;
      })
      .addCase(getAllTracks.pending, (state) => {
        state.loading = true;
      })
      .addCase(catalogueFilters.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.catalogueFilters = payload as ICatalogueFilters;
      });
  },
});

export const {} = actions;
export default reducer;
