import { createSlice } from '@reduxjs/toolkit';

import { ITerms } from '@/models/models';
import { terms } from '@/store/actions/terms';

export interface TermsState {
  loading: boolean;
  terms: ITerms | null;
}

const initialState: TermsState = {
  loading: true,
  terms: null,
};

const { actions, reducer } = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(terms.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      // @ts-ignore
      state.terms = { ...state.terms, [payload.type]: payload.text as string };

      state.loading = false;
    });
  },
});

export const {} = actions;
export default reducer;
