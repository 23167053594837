enum EWaveSurferPlayer {
  IsFavorite = "isFavorite",
  OpenModal = "openModal",
  ActiveStep = "activeStep",
  IsLoading = "isLoading",
  Progress = "progress",
  IsPlaying = "isPlaying",
  IsCopyright = "isCopyright",
  Volume = "volume",
  IsMuted = "isMuted",
}

export type WaveSurferPlayerAction =
  | {
      type:
        | EWaveSurferPlayer.IsPlaying
        | EWaveSurferPlayer.IsLoading
        | EWaveSurferPlayer.OpenModal
        | EWaveSurferPlayer.IsFavorite
        | EWaveSurferPlayer.IsCopyright
        | EWaveSurferPlayer.IsMuted;
      payload: boolean;
    }
  | {
      type: EWaveSurferPlayer.ActiveStep | EWaveSurferPlayer.Volume;
      payload: number;
    };

export type TWaveSurferPlayerState = {
  isFavorite: boolean;
  openModal: boolean;
  activeStep: number;
  isLoading: boolean;
  isPlaying: boolean;
  isCopyright: boolean;
  isMuted: boolean;
  volume: number;
};

const waveSurferPlayerReducer = (state: TWaveSurferPlayerState, action: WaveSurferPlayerAction) => {
  const { type, payload } = action;
  switch (type) {
    case EWaveSurferPlayer.IsFavorite:
      return {
        ...state,
        isFavorite: payload,
      };
    case EWaveSurferPlayer.OpenModal:
      return {
        ...state,
        openModal: payload,
      };
    case EWaveSurferPlayer.ActiveStep:
      return {
        ...state,
        activeStep: payload,
      };
    case EWaveSurferPlayer.IsLoading:
      return {
        ...state,
        isLoading: payload,
      };
    case EWaveSurferPlayer.IsPlaying:
      return {
        ...state,
        isPlaying: payload,
      };
    case EWaveSurferPlayer.IsCopyright:
      return {
        ...state,
        isCopyright: payload,
      };
    case EWaveSurferPlayer.Volume:
      return {
        ...state,
        volume: payload,
      };
    case EWaveSurferPlayer.IsMuted:
      return {
        ...state,
        isMuted: payload,
      };
    default:
      return state;
  }
};

export { EWaveSurferPlayer, waveSurferPlayerReducer };
