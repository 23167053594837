import { AvatarGroup } from "@mui/material";

import styled from "styled-components";

export const AvatarGroupWrapper = styled(AvatarGroup)<{ $isSmall: boolean }>`
  && {
    .MuiAvatarGroup-avatar {
      border: 1px solid #ffdca8;
      width: ${({ $isSmall }) => ($isSmall ? "24px" : "34px")};
      height: ${({ $isSmall }) => ($isSmall ? "24px" : "34px")};
      color: #ffdca8;
      font-family: "Kaisei Opti";
      font-size: 0.75rem;
      cursor: pointer;
    }
  }
`;
