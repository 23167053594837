import styled from "styled-components";

export const CookiesAnchor = styled.a`
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  text-transform: capitalize !important;
  line-height: 135%;
  font-family: Raleway;
  font-weight: 400;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  color: ${({ theme }) => theme.palette.textColor.highlighted};

  transition: color 250ms ease-in-out;

  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;
