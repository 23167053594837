import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { TAlbum, TTrack } from "@store/actions/track";

import { ERoutes } from "@utils/constants";

import CopyrightProtected from "@/components/CopyrightProtected";
import { Link } from "@/ui-kit";

import GradientDialog from "../Dialog";
import Stepper from "../SignUpDialog/components/Stepper";
import CopyPage from "./components/CopyPage";
import DownloadPage, { EDownloadModalType } from "./components/DownloadPage";

interface INewDownloadModal {
  open: boolean;
  onClose: () => void;
  activeStep: number;
  handleNext: () => void;
  item?: TAlbum;
  song?: TTrack;
  composer: string;
  videoUrl?: string;
  copyrightModal?: boolean;
  cover?: string;
}

interface IRenderStepContent {
  step: number;
  onClose: () => void;
  handleNext: () => void;
  album?: TAlbum;
  song?: TTrack;
  composer?: string;
  videoUrl?: string;
  copyrightModal?: boolean;
  cover?: string;
}

export enum EDownloadModal {
  FirstStep = 1,
  SecondStep,
}

const RenderStepContent: FC<IRenderStepContent> = ({
  album,
  step,
  videoUrl = "",
  song,
  composer = "",
  handleNext,
  copyrightModal = false,
  cover,
  onClose,
}) => {
  if (copyrightModal) {
    return album ? (
      <CopyPage item={album} composer={composer} videoUrl={videoUrl} copyrightModal={copyrightModal} />
    ) : (
      <CopyPage composer={composer} videoUrl={videoUrl} copyrightModal={copyrightModal} />
    );
  }

  switch (step) {
    case EDownloadModal.FirstStep:
      return album ? (
        <DownloadPage handleNext={handleNext} item={album} type={EDownloadModalType.Album} handleClose={onClose} />
      ) : (
        <DownloadPage
          handleNext={handleNext}
          song={song}
          type={EDownloadModalType.Song}
          name={composer}
          cover={cover}
          handleClose={onClose}
        />
      );
    case EDownloadModal.SecondStep:
      return album ? (
        <CopyPage item={album} composer={composer} videoUrl={videoUrl} />
      ) : (
        <CopyPage composer={composer} videoUrl={videoUrl} />
      );
    default:
      return null;
  }
};

const NewDownloadModal: FC<INewDownloadModal> = ({
  open,
  onClose,
  item,
  song,
  handleNext,
  activeStep,
  composer,
  videoUrl,
  copyrightModal = false,
  cover,
}) => {
  const { t } = useTranslation("downloadModal");
  return (
    <GradientDialog open={open} handleClose={onClose}>
      <Stack spacing="24px" alignItems="center">
        {!copyrightModal && (
          <Stack alignSelf="center" justifyContent="center" sx={{ width: "33%" }}>
            <Stepper activeStep={activeStep === 1 ? 0 : 1} steps={["01", "02"]} />
          </Stack>
        )}

        <Stack sx={{ marginTop: `${copyrightModal && "32px"}` }} width="100%">
          <RenderStepContent
            step={activeStep}
            onClose={onClose}
            handleNext={handleNext}
            album={item}
            composer={composer}
            song={song}
            videoUrl={videoUrl}
            copyrightModal={copyrightModal}
            cover={cover}
          />
        </Stack>

        {(activeStep === EDownloadModal.FirstStep || copyrightModal) && (
          <Stack
            sx={{
              backgroundColor: "rgba(99, 68, 45, 0.4)",
              borderRadius: "8px",
              padding: "8px 16px",
            }}
          >
            <CopyrightProtected variant="body1" />
          </Stack>
        )}

        {activeStep === EDownloadModal.FirstStep && !copyrightModal && (
          <Stack width="fit-content">
            <Link href={ERoutes.DownloadInstruction}>{t?.("downloadInstruction", { ns: "downloadModal" })}</Link>
          </Stack>
        )}
      </Stack>
    </GradientDialog>
  );
};

export default NewDownloadModal;
