import React, { FC } from "react";

import GradientDialog from "@components/Dialog";
import { ESignUp } from "@layouts/SecondRootLayout";

import AcceptTerms from "./components/AcceptTerms";
import ClassifyContent from "./components/ClassifyContent";
import Links from "./components/Links";
import Newsletter from "./components/Newsletter";
import { UseCases } from "./components/UseCases";
import VerifyEmail from "./components/VerifyEmail";
import Welcome from "./components/Welcome";

interface ISignUpDialog {
  open: boolean;
  handleClose: () => void;
  step: ESignUp;
  handleNextStep: () => void;
}

const renderDialogContent = (step: number, handleClose: () => void, handleNextStep: () => void) => {
  switch (step) {
    case ESignUp.Links:
      return <Links />;
    case ESignUp.ClassifyContent:
      return <ClassifyContent />;
    case ESignUp.UseCases:
      return <UseCases />;
    case ESignUp.AcceptTerms:
      return <AcceptTerms />;
    case ESignUp.Newsletter:
      return <Newsletter />;
    case ESignUp.VerifyEmail:
      return <VerifyEmail handleNextStep={handleNextStep} />;
    case ESignUp.Welcome:
      return <Welcome handleClose={handleClose} />;
    default:
      return null;
  }
};

const SignUpDialog: FC<ISignUpDialog> = ({ step, handleClose, open, handleNextStep }) => {
  return (
    <GradientDialog open={open} handleClose={handleClose} isClosable={false}>
      {renderDialogContent(step, handleClose, handleNextStep)}
    </GradientDialog>
  );
};

export default SignUpDialog;
