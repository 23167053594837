import { FC, PropsWithChildren } from "react";

import { TypographyProps } from "@mui/material";

import * as Styled from "./styles";

type TText = PropsWithChildren &
  TypographyProps & {
    isHeading?: boolean;
    //size in rem
    size?: number;
    isFullWidth?: boolean;
    isBold?: boolean;
    color?: string;
    fontWeight?: number;
    lineHeight?: number;
  };

export const Text: FC<TText> = (props) => {
  const {
    isHeading = false,
    isFullWidth = false,
    isBold = false,
    size = 1,
    color = "",
    children,
    fontWeight = 0,
    lineHeight = 0,
    ...rest
  } = props;
  return (
    <Styled.Text
      {...rest}
      $isHeading={isHeading}
      $isFullWidth={isFullWidth}
      $isBold={isBold}
      size={size}
      $fontWeight={fontWeight}
      color={color}
      $lineHeight={lineHeight}
    >
      {children}
    </Styled.Text>
  );
};
