import { DefaultRootState } from "react-redux";

import { createSelector } from "@reduxjs/toolkit";

import { TrackState } from "../../reducers/track";

export const getBottomTrackSelector = createSelector(
  (state: DefaultRootState) => state.tracks,
  ({ bottomTrack, isBottomLoading }: TrackState) => ({
    bottomTrack,
    isBottomLoading,
  }),
);
