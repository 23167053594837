import { MenuItem } from "@mui/material";

import styled from "styled-components";

export const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;

  svg,
  img {
    width: 20px;
    height: 20px;
  }
`;
