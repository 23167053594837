import { Components } from "@mui/material/styles/components";

import { isSmallDesktop } from "@/hooks/layout";

const components: Components = {
  MuiToolbar: {
    defaultProps: {
      disableGutters: true,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: "#000",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: "#000",
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        color: "#FBF4EC",
        ".MuiFormControlLabel-root": {
          alignItems: "start",
          [isSmallDesktop]: {
            alignItems: "center",
          },
          marginLeft: "0",
        },
        ".MuiFormControlLabel-label": {
          fontSize: "1rem",
          fontFamily: "Raleway",
          fontWeight: 500,
          lineHeight: "150%",
          paddingLeft: "8px",
          textAlign: "start",
          [isSmallDesktop]: {
            textAlign: "center",
          },

          "&.Mui-disabled": {
            color: "#AC8D75 !important",
          },
        },
        ".MuiFormLabel-root": {
          color: "#FBF4EC",
          fontSize: "1rem",
          fontFamily: "Raleway",
          fontWeight: 400,
          lineHeight: "150%",
          paddingLeft: "8px",
          "&.Mui-focused": {
            color: "#FBF4EC",
          },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        color: "#FFDCA8",
        "&.Mui-checked": {
          color: "#FFDCA8",

          "&.Mui-disabled": {
            color: "#AC8D75 !important",
          },
        },
        "& .MuiSvgIcon-root": { scale: "1.3" },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: "none",
        margin: 0,
        width: "100%",
        [isSmallDesktop]: {
          overflow: "hidden",
          width: "70%",
          maxWidth: "640px",
        },

        borderRadius: "10px",

        display: "inline-block",
        position: "relative",
        zIndex: 0,

        border: "double 2px transparent",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        backgroundImage:
          "linear-gradient(#3d2e24, #3d2e24), linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        background: "transparent",
        overflowY: "hidden",
        padding: 0,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        background: "transparent",
        overflowY: "hidden",
        paddingBottom: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        zIndex: 5,
        ".MuiOutlinedInput-root": {
          fontFamily: "Raleway !important",
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: "120%",
          letterSpacing: "0.00875rem",
          color: "#FBF4EC",
          borderRadius: "8px",
          paddingRight: "8px",
          border: "2px solid #deb081",

          backgroundColor: "#453426",

          transition: "background-color 0.3s ease-out, border 0.3s ease-out",

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "0",
          },

          "&.Mui-focused": {
            border: "2px solid #FFDCA8",
            backgroundColor: "#3C2D23",
          },

          "&.Mui-error": {
            color: "#FF5935",
            border: "2px solid #FF5935",
          },
        },
        ".MuiOutlinedInput-input": {
          padding: "10px 18px",
          "&:-webkit-autofill, -webkit-autofill:hover, -webkit-autofill:focus, -webkit-autofill:active": {
            caretColor: "#FBF4EC",
            WebkitBoxShadow: "inset 0 100vw #453426",
            WebkitTextFillColor: "#FBF4EC",
            marginRight: "-8px",
          },
          "&::placeholder": {
            color: "#C8AA86",
            opacity: 1,
          },

          "&.Mui-disabled": {
            "-webkit-text-fill-color": "initial !important",
          },

          textarea: {
            height: "100%",
          },
        },

        fieldset: {
          border: "none",
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        position: "absolute",
        bottom: "-22px",

        fontFamily: "Raleway",

        fontWeight: 500,

        fontSize: "0.75rem",

        marginLeft: 0,

        "&.Mui-error": {
          color: "#E0D2C1",
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderRadius: "8px",

          border: "2px solid #BA905C",

          background: "linear-gradient(180deg, rgba(59, 45, 33, 0.50) 0%, rgba(59, 45, 33, 0.50) 100%)",

          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        paddingInline: "16px",
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        paddingInline: "16px",
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        fontFamily: "Raleway",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1rem",
        letterSpacing: "0.01rem",
        color: "#FBF4EC",

        minWidth: "69px",

        minHeight: "48px",
        padding: 0,

        display: "inline-block",
        position: "relative",
        zIndex: 0,

        border: 0,

        transition: "background-color .3s ease-out",

        "&::before": {
          content: "''",
          position: "absolute",
          zIndex: 0,
          inset: 0,
          padding: "2px",
          borderRadius: "8px",
          background:
            "linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",
          mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          "-webkit-mask-composite": "xor",
          "mask-composite": "exclude",
        },

        "&.Mui-selected": {
          color: "#191A1A",
          background:
            "linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",

          div: {
            backgroundColor: "transparent",
          },
        },

        "&:hover": {
          backgroundColor: "rgba(57, 43, 30, 0.8)",
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        "& .MuiToggleButtonGroup-grouped": {
          margin: "4px",
          "&:not(:first-of-type)": {
            borderRadius: "8px",
            marginLeft: "4px",
          },
          "&:first-of-type": {
            borderRadius: "8px",
          },
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        height: "4px",
        padding: "13px 0",
        "& .MuiSlider-thumb": {
          height: "16px",
          width: "16px",
          backgroundImage:
            "linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",
          border: "1px solid currentColor",

          "&:hover": {
            boxShadow: "0 0 0 8px rgba(183, 139, 86, 0.16)",
          },
        },
        "& .MuiSlider-track": {
          height: "4px",
          backgroundImage:
            "linear-gradient(88deg, rgba(235, 191, 126, 0.00) -50.5%, #FFD29B 52.8%, rgba(235, 191, 126, 0.00) 146.18%)",
        },
        "& .MuiSlider-valueLabel": {
          fontFamily: "Kantumruy Pro",
          fontWeight: 300,
        },

        "& .MuiSlider-rail": {
          color: "#453426",
        },
      },
      valueLabel: {
        background: "rgba(99, 68, 45, 0.7)",
        color: "#FBF4EC",
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        border: "2px solid #ad8d75",
        zIndex: 5,
        cursor: "pointer",
        backgroundColor: "#453426",
        color: "#FFDCA8",
        transition: "border 0.3s ease-in-out, background-color 0.3s ease-in-out",
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      outlined: {
        borderRadius: "4px",
        background: "#4B3727",
        color: "#FFDCA8",
        fieldset: {
          border: 0,
        },
      },
      icon: {
        top: "calc(50% - 1rem)",
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        transform: "none",
        backgroundColor: "rgba(186, 156, 126, 0.11)",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      text: {
        color: "#FFDCA8",
        fontFamily: "Kaisei Opti",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "100%",
        letterSpacing: "0.00875rem",
        textTransform: "capitalize",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        padding: "8px 16px",
        marginBottom: "8px !important",
        backgroundColor: "#FFDCA8",
        borderRadius: "8px",
        color: "#3C2D23",
        textAlign: "center",
        fontFamily: "Raleway",
        fontSize: "0.8125rem",
        fontStyle: "normal",
        fontWeight: "300",
        lineHeight: "150%",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&:hover, &.Mui-focusVisible": { backgroundColor: "#59412E" },
      },
    },
  },
};

export default components;
