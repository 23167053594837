import React, { ChangeEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { InnerBorderStack, OuterBorderStack } from "@components/FreeUseTermsComponent/components";
import { ESignUp } from "@layouts/SecondRootLayout";

import CustomTooltip from "@/components/CustomTooltip";
import FreeUseTermsComponent from "@/components/FreeUseTermsComponent";
import { DescriptionTypography } from "@/components/LoginDialog/components";
import { MainTextTypography } from "@/components/RecoveryDialog/components";
import Stepper from "@/components/SignUpDialog/components/Stepper";
import { useIsDesktop } from "@/hooks/layout";
import { updateMy } from "@/store/actions/user";
import { useAppDispatchUnwrap } from "@/store/hooks";

import { UseTermsStack } from "./components";

const AcceptTerms = () => {
  const { t } = useTranslation("signup", {
    keyPrefix: "acceptTerms",
  });

  const isDesktop = useIsDesktop();

  const dispatch = useAppDispatchUnwrap();

  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsTermsChecked(event.target.checked);
  };

  return (
    <UseTermsStack spacing={isDesktop ? "32px" : "24px"} width="100%" justifyContent="center" alignItems="center">
      <Stepper activeStep={ESignUp.AcceptTerms} />
      <Stack spacing={isDesktop ? "24px" : "16px"} width="100%">
        <Stack spacing="16px" height="100%" width="100%">
          <Stack spacing="16px" justifyContent="center" alignItems="center">
            <MainTextTypography>{t("mainText")}</MainTextTypography>

            <DescriptionTypography>
              <Trans t={t} i18nKey="description" />
            </DescriptionTypography>
          </Stack>
        </Stack>

        <OuterBorderStack>
          <InnerBorderStack>
            <Stack
              height="100%"
              width="100%"
              spacing={isDesktop ? "40px" : "24px"}
              alignItems="center"
              sx={{ overflowX: "hidden", zIndex: 999 }}
            >
              <FreeUseTermsComponent isPage={false} />
            </Stack>
          </InnerBorderStack>
        </OuterBorderStack>
      </Stack>

      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              checked={isTermsChecked}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: isDesktop ? "1.5rem" : "2rem",
                },
              }}
            />
          }
          label={t("checkBoxLabel")}
          labelPlacement="end"
          sx={{ margin: "0", textAlign: "start" }}
        />
      </FormControl>

      <Stack width="100%" zIndex={10}>
        <CustomTooltip disableHoverListener={isTermsChecked} title={<Trans t={t} i18nKey="toolTip" />}>
          <GradientButton
            isDisabled={!isTermsChecked}
            onClick={async () => {
              await dispatch(updateMy({ hasAcceptedTerms: true }));
            }}
            isFullWidth
          >
            {t("buttonText")}
          </GradientButton>
        </CustomTooltip>
      </Stack>
    </UseTermsStack>
  );
};

export default AcceptTerms;
