import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Divider, Stack } from "@mui/material";

import { Text } from "@ui/typography";

import { getAlbumLink, getTrackLink } from "@store/actions/downloads";
import { TAlbum, TTrack } from "@store/actions/track";
import { useAppDispatch, useAppDispatchUnwrap, useAppSelector } from "@store/hooks";
import { setOptimisticDownloadCount } from "@store/reducers/downloads";
import { getDownloadsSelector } from "@store/selectors/downloads/getDownloadsSelector";

import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { download, downloadZip } from "@utils/download";

import { useIsDesktop } from "@/hooks/layout";
import { Button, Link } from "@/ui-kit";

import * as Styled from "./styles";

interface IDownloadPageProps {
  handleNext: () => void;
  item?: TAlbum;
  song?: TTrack;
  type: EDownloadModalType;
  name?: string;
  cover?: string;
  handleClose?: () => void;
}

export enum EDownloadModalType {
  Song = "Song",
  Album = "Album",
}

const DownloadPage: React.FC<IDownloadPageProps> = ({ handleNext, item, song, type, name, cover, handleClose }) => {
  const { t } = useTranslation("downloadModal");
  const isDesktop = useIsDesktop();

  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatchUnwrap();

  const syncDispatch = useAppDispatch();

  const { downloadsLimit, tracksCount, albumsCount } = useAppSelector(getDownloadsSelector);

  const availableDownloads = downloadsLimit - (type === EDownloadModalType.Album ? albumsCount : tracksCount);

  return (
    <>
      <Stack justifyContent="center" alignItems="center" spacing="24px" width="100%">
        {type === EDownloadModalType.Album ? (
          <Styled.Title>{t("firstStepAlbum", { album: item?.name })}</Styled.Title>
        ) : (
          <Styled.Title>{t("firstStepSong", { song: song?.name })}</Styled.Title>
        )}
        <Divider sx={{ zIndex: 20, height: "1px", width: "90%", borderColor: "#45392e" }} color="#f5f5f5" />
        <Text fontWeight={500} isFullWidth textAlign="center">
          <Trans
            t={t}
            i18nKey="quickTest"
            components={{
              openQuestionFlow: (
                <Link
                  fontSize="1rem !important"
                  fontFamily="Kaisei Opti !important"
                  component="button"
                  fontWeight="500 !important"
                  underline="always"
                  onClick={() => setOpenQuestionFlow(true)}
                />
              ),
            }}
          />
        </Text>
        <Styled.AlbumCover src={item?.coverUrl || cover} />
        <Stack
          direction={isDesktop ? "row" : "column"}
          width="100%"
          spacing="24px"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            isFullWidth
            isBigButton
            isDisabled={availableDownloads === 0 || isLoading}
            onClick={async () => {
              if (type === EDownloadModalType.Album) {
                setIsLoading(true);
                try {
                  const result = await dispatch(
                    getAlbumLink({
                      id: item?._id,
                      isLowQuality: false,
                    }),
                  );
                  await downloadZip(result?.zipUrl, item.name).then(() => {
                    syncDispatch(setOptimisticDownloadCount({ isAlbum: true }));
                  });
                } catch (error) {
                  console.log("Error fetching link:", error);
                }
              } else {
                try {
                  const result = await dispatch(
                    getTrackLink({
                      id: song?._id,
                      isLowQuality: false,
                    }),
                  );

                  await download(result?.fileUrl, name).then(() => {
                    syncDispatch(setOptimisticDownloadCount({ isAlbum: false }));
                    setIsLoading(false);
                  });
                } catch (error) {
                  console.log("Error fetching link:", error);
                }
              }
              handleNext();
            }}
          >
            <Stack>
              <Trans
                t={t}
                i18nKey="downloadWav"
                components={[<div className="additional-text" />]}
                values={{ count: availableDownloads }}
              />
            </Stack>
          </Button>
          <Button
            isFullWidth
            isBigButton
            isDisabled={availableDownloads === 0 || isLoading}
            onClick={async () => {
              setIsLoading(true);
              if (type === EDownloadModalType.Album) {
                try {
                  const result = await dispatch(
                    getAlbumLink({
                      id: item?._id,
                      isLowQuality: true,
                    }),
                  );
                  await downloadZip(result?.lowQualityZipUrl, item.name).then(() => {
                    syncDispatch(setOptimisticDownloadCount({ isAlbum: true }));
                    setIsLoading(false);
                  });
                } catch (error) {
                  console.log("Error fetching link:", error);
                }
              } else {
                try {
                  const result = await dispatch(
                    getTrackLink({
                      id: song?._id,
                      isLowQuality: true,
                    }),
                  );

                  await download(result?.lowQualityFileUrl, name).then(() => {
                    syncDispatch(setOptimisticDownloadCount({ isAlbum: false }));
                    setIsLoading(false);
                  });
                } catch (error) {
                  console.log("Error fetching link:", error);
                }
              }
              handleNext();
            }}
          >
            <Stack>
              <Trans
                t={t}
                i18nKey="downloadMP3"
                components={[<div className="additional-text" />]}
                values={{ count: availableDownloads }}
              />
            </Stack>
          </Button>
        </Stack>
      </Stack>
      <QuestionFlowDialog
        open={openQuestionFlow}
        onClose={() => {
          handleClose();
          setOpenQuestionFlow(false);
        }}
      />
    </>
  );
};

export default DownloadPage;
