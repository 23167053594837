import { FC, PropsWithChildren } from "react";

import { ERoutes } from "@utils/constants";

import * as Styled from "./styles";

type TNavLink = {
  to: ERoutes;
  isFooter?: boolean;
  isDisabled?: boolean;
  end?: boolean;
} & PropsWithChildren &
  Partial<Pick<HTMLAnchorElement, "target" | "id" | "onclick">>;

export const NavLink: FC<TNavLink> = ({
  isFooter = false,
  to,
  children,
  isDisabled = false,
  end = false,
  ...restProps
}) => {
  return (
    <Styled.NavLink
      to={to}
      $isFooter={isFooter}
      $isDisabled={isDisabled}
      onClick={(e) => isDisabled && e.preventDefault()}
      end={end}
      {...restProps}
    >
      {children}
    </Styled.NavLink>
  );
};
