import { StepConnector, stepConnectorClasses, Stepper } from '@mui/material';

import styled from 'styled-components';

export const MuiStepper = styled(Stepper)(({ theme }) => ({
  '& .Mui-active:not(.MuiStepConnector-root)': {
    borderRadius: '50%',
    color: 'transparent !important',
    backgroundImage: theme.palette.gradientButton.mainGradient,
  },
  '& .MuiSvgIcon-root:not(.MuiStepLabel-iconContainer.Mui-completed svg)': {
    borderRadius: '50%',
    border: '1px solid transparent',
    background:
      'linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%) border-box',
  },
  '& .MuiSvgIcon-root:not(.Mui-active)': {
    color: '#4B3628',
  },
  '& .MuiStepLabel-iconContainer.Mui-completed svg': {
    color: '#4F3926',
  },
  '& .MuiStepLabel-iconContainer.Mui-completed svg text': {
    fill: theme.palette.textColor.subtitle,
  },
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px',
  },
  '& .Mui-active .MuiStepIcon-text': {
    fill: '#4F3926',
    fontWeight: '700',
  },
  '& .MuiStepIcon-text': {
    fill: theme.palette.textColor.highlighted,
    fontWeight: '400',
    fontSize: '10px',
    fontFamily: 'Kaisei Opti',
  },
  '& .MuiStep-root': {
    padding: 0,
  },
  '& .MuiStepLabel-iconContainer': {
    padding: 0,
  },
}));

export const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#4B3628',
      borderWidth: '1px',
      minWidth: '40px',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#4B3628',
      borderWidth: '1px',
      minWidth: '40px',
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderImageSlice: 1,
      borderWidth: '1px',
      borderImageSource: theme.palette.gradientButton.mainGradient,
      minWidth: '40px',
    },
  },
}));
