import { Typography } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

export const NewsLetterTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
`;

export const NavigationTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
`;

export const AudioSanctumTypography = styled(Typography)`
  font-family: Kaisei Opti;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01rem;

  text-transform: uppercase;

  color: ${({ theme }) => theme.palette.textColor.highlighted};

  ${isSmallDesktop} {
    font-size: 1.125rem;
    line-height: 100%;
    letter-spacing: 0.01125rem;
  }
`;

export const AudioSanctumDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.subtitle};
  font-family: Kaisei Opti;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 0.825rem */
  letter-spacing: 0.00688rem;

  text-transform: uppercase;

  ${isSmallDesktop} {
    font-size: 0.75rem;
    line-height: 120%;
    letter-spacing: 0.0075rem;
  }
`;
