import React, { FC } from "react";

import { CheckboxProps } from "@mui/material";

import * as Styled from "./styles";

type TCheckbox = CheckboxProps;

export const Checkbox: FC<TCheckbox> = (props) => {
  return <Styled.Checkbox {...props} />;
};
