import { IconButton } from "@mui/material";

import styled from "styled-components";

export const Button = styled(IconButton)`
  width: 32px;
  height: 32px;

  background-color: transparent;

  transition: background-color 0.3s ease-in-out;

  border-radius: 50%;

  &:hover {
    background-color: #59412e;
  }
`;
