import { setWithExpiry } from './localStorage';

type ISession = {
  accessToken: string;
  accessTokenSecondToExpiration: number;
  refreshToken?: string;
  refreshTokenSecondToExpiration?: number;
};

const setSession = (session: ISession) => {
  // set access token;
  setWithExpiry(
    'accessToken',
    session.accessToken,
    session.accessTokenSecondToExpiration
  );
  // set refresh token
  if (session.refreshToken && session.refreshTokenSecondToExpiration) {
    setWithExpiry(
      'refreshToken',
      session.refreshToken,
      session.refreshTokenSecondToExpiration
    );
  }
};

export { setSession };
