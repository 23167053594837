import { createSlice } from "@reduxjs/toolkit";

import { album } from "../../actions/album";
import { TAlbum } from "../../actions/track";

export type AlbumState = {
  album?: TAlbum;
  isLoading: boolean;
};

const initialState: AlbumState = {
  isLoading: true,
};

const { reducer } = createSlice({
  name: "albums",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(album.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.album = payload;
      state.isLoading = false;
    });
    builder.addCase(album.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export default reducer;
