import { createContext, Dispatch, RefObject, SetStateAction } from "react";

import { TUseWavesurfer } from "@hooks/wafeform";

export type TBottomPlayer = {
  id: string;
  isPlaying?: boolean;
};

export type TPlayerContext = {
  data: TBottomPlayer;
  setData: (song: TBottomPlayer) => void;
  setPlay: (isPlaying: boolean) => void;
  media?: HTMLMediaElement;
  setMedia: Dispatch<SetStateAction<HTMLMediaElement>>;
  openLoginDialog: boolean;
  setOpenLoginDialog: () => void;
  handleMediaControl: (controls: Partial<TUseWavesurfer>) => void;
  containerRef: RefObject<HTMLDivElement>;
};

export const PlayerContext = createContext<TPlayerContext | null>(null);
