// src/utils/customParams.ts
import { QueryParamConfig } from "use-query-params";

export const customRangeQueryParam: QueryParamConfig<{ min?: number; max?: number }> = {
  encode: (obj) => {
    if (!obj || (obj.min === undefined && obj.max === undefined)) {
      return undefined;
    }

    const parts: string[] = [];
    if (obj.min !== undefined) parts.push(`min-${obj.min}`);
    if (obj.max !== undefined) parts.push(`max-${obj.max}`);

    return parts.join("_");
  },

  decode: (strValue) => {
    if (!strValue || Array.isArray(strValue)) {
      return { min: undefined, max: undefined };
    }

    const parts = strValue.split("_");
    const obj: { min?: number; max?: number } = {};

    parts.forEach((part) => {
      const [key, value] = part.split("-");
      if (key === "min") obj.min = parseFloat(value);
      if (key === "max") obj.max = parseFloat(value);
    });

    return obj;
  },
};
