import { Divider } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled, { css } from "styled-components";

type TContentWrapper = {
  $isChoiceStep?: boolean;
};

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const ContentWrapper = styled.div<TContentWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $isChoiceStep }) =>
    $isChoiceStep &&
    css`
      row-gap: 32px;
    `}
`;

export const ChoiceButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 16px;
`;

export const ChoiceButton = styled.button`
  flex: 1;
  position: relative;
  border: 1px solid #4e4034;
  background: transparent;
  border-radius: 10px;

  padding: 24px 16px;
  cursor: pointer;

  &:active {
    border: 1px solid transparent;
    p {
      z-index: 2;
      color: #3c2d23;
    }
    &::before {
      opacity: 1;
    }
  }

  ${isSmallDesktop} {
    &:hover {
      border: 1px solid transparent;
      p {
        z-index: 2;
        color: #3c2d23;
      }
      &::before {
        opacity: 1;
      }
    }
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background: ${({ theme }) => theme.palette.gradientButton.mainGradient};
    z-index: 1;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
`;

export const StyledDivider = styled(Divider)`
  width: 80px;
  height: 2px;
  z-index: 5;

  margin: 0 auto 24px;

  background: linear-gradient(
    93deg,
    rgba(186, 144, 92, 0.5) 0.82%,
    #ffd29b 33.73%,
    #ffe0b1 47.65%,
    #ffd29b 61.53%,
    rgba(186, 144, 92, 0.5) 99.48%
  );
`;
