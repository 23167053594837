const handleResponseHeaders = (responseHeader?: string) => {
  if (!responseHeader) return 1;
  const total = Number(responseHeader.split("/")[1]);
  if (!total) return 1;
  return total;
};

export const getPageCount = (range: string, limit: number) => {
  return Math.ceil(handleResponseHeaders(range) / limit);
};
