import { ERoutes } from "./constants";

export const makePath = (route: ERoutes, id: string) => {
  return route.replace(":id", id);
};

export const makePathForAlbum = (route: ERoutes, id: string) => {
  return `${route}?albumsId=${id}`;
};

export const makePathForTrack = (route: ERoutes, id: string) => {
  return `${route}?tracksId=${id}`;
};
