import { FC, PropsWithChildren } from "react";

import { TabsProps } from "@mui/material";

import * as Styled from "./styles";

type TTabs = TabsProps &
  PropsWithChildren & {
    isCatalog?: boolean;
    isFirstTabSelected?: boolean;
  };

export const Tabs: FC<TTabs> = (props) => {
  const { children, isCatalog = false, isFirstTabSelected, ...restProps } = props;
  return <Styled.Tabs $isCatalog={isCatalog} $isFirstTabSelected={isFirstTabSelected} {...restProps}>{children}</Styled.Tabs>;
};
