import { IError } from "@models/inner-models";
import { createAsyncThunk } from "@reduxjs/toolkit";

import instance from "../../middleware/api";
import { TDownloadAlbumLinkResponse, TDownloadLink, TDownloadTrackLinkResponse } from "./types";

enum EDownloads {
  GetDownloadsLimit = "GET_DOWNLOADS_LIMIT",
  GetAlbumLink = "GET_ALBUM_LINK",
  GetTrackLink = "GET_Track_LINK",
}

const BASE_URL = "/downloads";

export const limitDownloadsAmount = createAsyncThunk<number>(EDownloads.GetDownloadsLimit, async (_, thunkAPI) => {
  try {
    const resp = await instance.get(`/limit-downloads`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAlbumLink = createAsyncThunk(EDownloads.GetAlbumLink, async (data: TDownloadLink, thunkAPI) => {
  try {
    const resp = await instance.get<TDownloadAlbumLinkResponse>(`${BASE_URL}` + "/album", { params: data });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const getTrackLink = createAsyncThunk(EDownloads.GetTrackLink, async (data: TDownloadLink, thunkAPI) => {
  try {
    const resp = await instance.get<TDownloadTrackLinkResponse>(`${BASE_URL}` + "/track", { params: data });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});
