import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { login, register } from "@store/actions/user";
import { useAppDispatchUnwrap } from "@store/hooks";

import FormikInput from "@components/FormikInput";
import { IError } from "@models/inner-models";
import { Formik } from "formik";
import { object, string } from "yup";

interface IForm {
  isSignUp: boolean;
  handleClose: () => void;
  handleOpenRecoveryDialog: () => void;
}

const validationSchema = (t: (key: string) => string, isSignUp: boolean) => {
  const commonSchema = object({
    email: string().email(t("emailValidation")).required(t("fieldRequired")),
    password: string().min(8, t("passwordValidation")).required(t("fieldRequired")),
  });

  if (isSignUp) {
    return commonSchema.shape({
      firstName: string().required(t("fieldRequired")),
    });
  }

  return commonSchema;
};

const Form: FC<IForm> = ({ isSignUp, handleClose }) => {
  const { t } = useTranslation("login");
  const { t: tValidation } = useTranslation("validation");

  const [showPassword, setShowPassword] = useState(false);

  const initialValues = useMemo(() => {
    if (isSignUp) {
      return {
        firstName: "",
        email: "",
        password: "",
      };
    } else {
      return {
        email: "",
        password: "",
      };
    }
  }, [isSignUp]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const dispatch = useAppDispatchUnwrap();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={() => validationSchema(tValidation, isSignUp)}
      onSubmit={async (values, { setFieldError }) => {
        if (isSignUp) {
          try {
            await dispatch(register(values));
            handleClose();
          } catch (err) {
            const message = (err as IError).message;
            setFieldError("email", message);
          }
        }

        if (!isSignUp) {
          try {
            await dispatch(login(values));
            handleClose();
          } catch (err) {
            const message = (err as IError).message;
            setFieldError("email", message);
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%", zIndex: 5 }}>
          <Stack spacing="24px" alignItems="center" width="100%">
            <Stack spacing="24px" width="100%">
              {isSignUp && (
                <FormikInput
                  fieldName="firstName"
                  label={t("firstName")}
                  placeholder={t("firstName") ?? ""}
                  autoComplete="name"
                />
              )}

              <FormikInput
                fieldName="email"
                label={t("email")}
                placeholder={t("email") ?? ""}
                autoComplete="email"
                type="email"
              />

              <FormikInput
                fieldName="password"
                label={t("password")}
                placeholder={isSignUp ? t("password") + (t("minPasswordLength") ?? "") : t("password") ?? ""}
                autoComplete={isSignUp ? "new-password" : "password"}
                type={showPassword ? "text" : "password"}
                InputAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "textColor.highlighted" }} />
                      ) : (
                        <Visibility sx={{ color: "textColor.highlighted" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Stack>

            <GradientButton isBigButton type="submit" isDisabled={!(isValid && dirty && !isSubmitting)} isFullWidth>
              {t(isSignUp ? "getAccess" : "login")}
            </GradientButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default Form;
