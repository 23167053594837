import { DefaultRootState } from "react-redux";

import { createSelector } from "@reduxjs/toolkit";

import { DownloadsState } from "../../reducers/downloads";

export const getDownloadsSelector = createSelector(
  (state: DefaultRootState) => state.downloads,
  ({ downloadsLimit, tracksCount, albumsCount }: DownloadsState) => ({
    downloadsLimit,
    tracksCount,
    albumsCount,
  }),
);
