import { FC } from "react";

import { Text } from "@ui/typography";

import * as Styled from "../styles";

type TMenuItem = {
  handleClick: () => void;
  text: string;
  icon: JSX.Element;
};

export const MenuItem: FC<TMenuItem> = ({ text, handleClick, icon }) => {
  return (
    <Styled.MenuItemWrapper onClick={handleClick}>
      {icon}
      <Text size={0.9}>{text}</Text>
    </Styled.MenuItemWrapper>
  );
};
