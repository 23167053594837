import { Pagination } from "@mui/material";

import styled from "styled-components";

export const Wrapper = styled(Pagination)`
  && {
    font-family: Kantumruy Pro;
    font-size: 1rem;

    .MuiButtonBase-root {
      width: 48px;
      height: 48px;
    }

    .MuiSvgIcon-root {
      scale: 1.3;
    }

    & .MuiPaginationItem-page:not(.Mui-selected) {
      color: #e3bda0;
      border: 1px solid #ffdca8;
      background-color: rgb(75 55 39 / 50%);
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        color: #fbf4ec;
        background-color: #4b3727;
      }
    }

    & .Mui-selected {
      color: #fbf4ec;
      background-color: #4b3727;
    }

    .MuiPaginationItem-icon {
      fill: #ffdca8;
    }

    .MuiPaginationItem-text {
      font-size: 1rem;
    }

    .Mui-disabled {
      .MuiPaginationItem-icon {
        fill: #4b3727;
      }

      opacity: 0.5;
    }
  }
`;
