import { isSmallDesktop } from "@hooks/layout";

import styled, { keyframes } from "styled-components";

const SLIDE_ANIMATION = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 24px 0;
  white-space: nowrap;
  position: relative;
  border-radius: 8px;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 22.5%,
    rgb(0, 0, 0) 77.5%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const ContentWrapper = styled.div`
  display: inline-block;
  animation: ${SLIDE_ANIMATION} 35s infinite linear;

  svg,
  img,
  span {
    height: 24px;
    margin: 0 16px;
    transform: translateY(1%);
    overflow: visible;

    ${isSmallDesktop} {
      height: 28px;
    }
  }
`;
