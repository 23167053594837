import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "@ui/typography";

import { ChoiceButtons } from "@components/QuestionFlowDialog/components/ChoiceButtons";

import * as Styled from "../styles";
import { formatButtonsData, THandleNextStepProp } from "../utils";

export const ChoiceSection: FC<THandleNextStepProp> = ({ handleLeftChoice, handleRightChoice, type }) => {
  const { t } = useTranslation("questionFlow", { keyPrefix: `basicSteps.${type}` });
  return (
    <Styled.ContentWrapper $isChoiceStep>
      <Text isHeading size={1.5} isFullWidth textAlign="center" lineHeight={140}>
        {t("title")}
      </Text>
      <ChoiceButtons buttons={formatButtonsData(type, handleLeftChoice, handleRightChoice)} />
    </Styled.ContentWrapper>
  );
};
