import { IconButton } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled, { css } from "styled-components";

type TChildrenWrapper = {
  $isBigSize: boolean;
  $isTransparent: boolean;
  $isActive: boolean;
  $isFooter: boolean;
  $isCircleHover: boolean;
  $isHeartIcon: boolean;
  $backgroundColor: string;
  $isBottomPlayer: boolean;
};

export const Button = styled(IconButton)<TChildrenWrapper>`
  &.MuiIconButton-root {
    display: inline-block;
    position: relative;
    z-index: 0;

    width: ${({ $isBigSize }) => ($isBigSize ? "48px" : "32px")};
    height: ${({ $isBigSize }) => ($isBigSize ? "48px" : "32px")};

    ${({ $isFooter }) =>
      $isFooter &&
      css`
        width: 40px;
        height: 40px;
      `}
    border-radius: 2px;

    padding: 0;

    transition: all 0.3s ease-in-out;

    ${({ $isTransparent, $isActive, $backgroundColor, $isHeartIcon }) =>
      $isTransparent
        ? css`
            background-color: ${$backgroundColor};
            border-radius: 8px;

            path {
              stroke: ${$isActive ? "#ffdca8" : "#AC8D75"};

              transition: stroke 0.3s ease-in-out;
            }

            &:hover {
              path {
                stroke: ${$isActive ? "#AC8D75" : "#ffdca8"};
              }
            }

            ${$isHeartIcon &&
            css`
              path {
                stroke: ${$isActive ? "transparent" : "#AC8D75"};

                transition: stroke 0.3s ease-in-out;
              }

              &:hover {
                path {
                  stroke: ${$isActive ? "transparent" : "#ffdca8"};
                }
              }
            `}
          `
        : css`
            background-color: ${({ theme }) => theme.palette.backgroundColors.iconButton};

            path {
              fill: #ffdca8;

              transition: fill 0.3s ease-in-out;
            }

            &:hover::after {
              opacity: 1;
            }

            &:hover {
              background-color: #ffdca8;

              path {
                fill: #4b3628;
              }
            }

            &::after {
              content: '""';
              background: #ffdca8;
              position: absolute;
              z-index: 0;
              inset: 0;
              padding: 2px;
              border-radius: 2px;
              mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
              transition: opacity 300ms ease-in-out;
              opacity: 0;
            }

            &::before {
              content: "";
              position: absolute;
              z-index: -1;
              inset: 0;
              padding: 1px;
              border-radius: 2px;
              background: ${({ theme }) => theme.palette.gradientButton.mainGradient};
              mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
            }
          `}

    ${({ $isCircleHover }) =>
      $isCircleHover &&
      css`
        border-radius: 50%;

        &:hover {
          background-color: #59412e;
        }
      `}

        ${({ $isHeartIcon, $isBottomPlayer }) =>
      $isHeartIcon &&
      $isBottomPlayer &&
      css`
        ${isSmallDesktop} {
          width: 23px;
          height: 23px;
        }
      `}
  }
`;
