import { FC, PropsWithChildren, useEffect, useRef } from "react";

import * as Styled from "./styles";

type TInfinityLogoLoop = PropsWithChildren & {
  backgroundGradient?: string;
  background?: string;
};

export const InfinityLogoLoop: FC<TInfinityLogoLoop> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const copy = document.querySelector(".logos-slide").cloneNode(true);
    document.querySelector(".logos").appendChild(copy);
  }, []);

  return (
    <Styled.Wrapper ref={contentRef} className="logos">
      <Styled.ContentWrapper className="logos-slide">{children}</Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};
