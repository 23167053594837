import { Avatar, Stack, Typography } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop } from "@/hooks/layout";

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  text-align: center;
  font-family: Kaisei Opti;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.01125rem;
  text-transform: capitalize;

  ${isSmallDesktop} {
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 500;
    letter-spacing: 0.015rem;
  }
`;

export const TextFieldLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.00875rem;
  text-transform: capitalize;
  text-align: start;

  min-width: 160px;

  ${isSmallDesktop} {
    font-size: 1rem;
    letter-spacing: 0.01rem;
  }
`;

export const NameTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  text-align: center;
  font-family: Kaisei Opti;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  letter-spacing: 0.00875rem;
  text-transform: capitalize;

  ${isSmallDesktop} {
    font-size: 1.125rem;
    letter-spacing: 0.01125rem;
  }
`;

export const LastChangeTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  text-align: center;
  font-family: Raleway;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.3125rem */

  ${isSmallDesktop} {
    font-size: 0.875rem;
    line-height: 150%;
  }
`;

export const SubDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  text-align: center;
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */

  ${isSmallDesktop} {
    font-size: 1rem;
  }
`;

export const AdditionalTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};

  padding-left: 28px;

  ${isSmallDesktop} {
    font-size: 0.875rem;
    line-height: 21px;
    font-weight: 400;
  }

  text-align: start;
  font-family: Raleway;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
`;

export const ProfileAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  cursor: auto;

  text-align: center;
  font-family: Kaisei Opti;
  font-size: 1.19319rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.67044rem */
  letter-spacing: 0.01194rem;
  text-transform: capitalize;

  ${isSmallDesktop} {
    width: 88px;
    height: 88px;
    font-size: 1.875rem;
    letter-spacing: 0.01875rem;
  }
`;

export const GPDRStack = styled(Stack)`
  width: 100%;
  border-radius: 8px;

  padding: 24px 40px;

  z-index: 1;

  background: linear-gradient(0deg, rgba(59, 45, 33, 0.38) 0%, rgba(58, 44, 36, 0.75) 100%);

  ${isSmallDesktop} {
    width: 50%;
  }
`;

export const GPDRTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.01rem;
`;
