import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import Form from "@components/SignUpDialog/components/Links/Form";
import { ESignUp } from "@layouts/SecondRootLayout";

import { DescriptionTypography } from "@/components/LoginDialog/components";
import { MainTextTypography } from "@/components/RecoveryDialog/components";
import Stepper from "@/components/SignUpDialog/components/Stepper";
import { useIsDesktop } from "@/hooks/layout";

const Links = () => {
  const { t } = useTranslation('signup', {
    keyPrefix: 'links',
  });

  const isDesktop = useIsDesktop();

  return (
    <Stack
      spacing={isDesktop ? '32px' : '24px'}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Stepper activeStep={ESignUp.Links}/>
      <Stack spacing={isDesktop ? '24px' : '16px'} width="100%">
        <Stack spacing="16px" height="100%" width="100%">
          <Stack spacing="16px" justifyContent="center" alignItems="center">
            <MainTextTypography>{t('mainText')}</MainTextTypography>

            <DescriptionTypography>
              <Trans t={t} i18nKey="description" />
            </DescriptionTypography>
          </Stack>
        </Stack>

        <Form />
      </Stack>
    </Stack>
  );
};

export default Links;
