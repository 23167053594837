import instance from "@store/middleware/api";

import { IError } from "@models/inner-models";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { DealTypes, TDealResponse, TDealsParams, TDealTagsResponse, TSliderDealResponse } from "./types";

enum DealsPrefix {
  GET_DEALS = "GET_DEALS",
  GET_SLIDER_DEALS = "GET_SLIDER_DEALS",
  GET_DEAL_TAGS = "GET_DEAL_TAGS",
}

const BASE_URL = "/deals";
const BASE_URL_TAGS = "/deal-tags";

export const getDeals = createAsyncThunk(DealsPrefix.GET_DEALS, async (params: TDealsParams, thunkAPI) => {
  try {
    let modifiedParams = params;
    if (!params) {
      modifiedParams = { filter: { types: [DealTypes.CODE, DealTypes.LINK] } };
    }
    const resp = await instance.get<TDealResponse>(BASE_URL, { params: modifiedParams });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const getSliderDeals = createAsyncThunk(DealsPrefix.GET_SLIDER_DEALS, async (_, thunkAPI) => {
  try {
    const params: TDealsParams = { filter: { type: DealTypes.SLIDER } };
    const resp = await instance.get<TSliderDealResponse>(BASE_URL, { params });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const getDealTags = createAsyncThunk(DealsPrefix.GET_DEAL_TAGS, async (_, thunkAPI) => {
  try {
    const resp = await instance.get<TDealTagsResponse>(BASE_URL_TAGS);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});
