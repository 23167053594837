import { Final } from "@components/QuestionFlowDialog/components/Final";

import { ChoiceSection, FindOut } from "../../components";
import { Steps } from "../constants";
import { THandleNextStep } from "../types";

export const renderStepContent = (activeStep: Steps, handleNextStep: THandleNextStep, onClose: () => void) => {
  switch (activeStep) {
    case Steps.START:
      return <FindOut handleLeftChoice={() => handleNextStep()} />;
    case Steps.MUSIC_USE:
      return (
        <ChoiceSection
          handleLeftChoice={() => handleNextStep()}
          handleRightChoice={() => handleNextStep(Steps.FINAL_NEED_LICENSE)}
          type="musicUse"
        />
      );
    case Steps.BROADCAST_NETWORK:
      return (
        <ChoiceSection
          handleLeftChoice={() => handleNextStep(Steps.FINAL_NEED_LICENSE)}
          handleRightChoice={() => handleNextStep()}
          type="broadcastNetwork"
        />
      );
    case Steps.MUSIC_CONTENT:
      return (
        <ChoiceSection
          handleLeftChoice={() => handleNextStep(Steps.FINAL_NEED_BECOME_CREATOR)}
          handleRightChoice={() => handleNextStep()}
          type="musicContent"
        />
      );
    case Steps.ADVERTISEMENT:
      return (
        <ChoiceSection
          handleLeftChoice={() => handleNextStep(Steps.FINAL_NEED_LICENSE)}
          handleRightChoice={() => handleNextStep()}
          type="advertisement"
        />
      );
    case Steps.GIVE_CREDIT:
      return (
        <ChoiceSection
          handleLeftChoice={() => handleNextStep()}
          handleRightChoice={() => handleNextStep(Steps.FINAL_NEED_LICENSE)}
          type="giveCredit"
        />
      );
    case Steps.FINAL_GIVE_CREDIT:
      return <Final type="giveCredit" onClose={onClose} />;
    case Steps.FINAL_NEED_LICENSE:
      return <Final type="needLicence" onClose={onClose} />;
    case Steps.FINAL_NEED_BECOME_CREATOR:
      return <Final type="becomeCreator" onClose={onClose} />;
    default:
      return null;
  }
};
