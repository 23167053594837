export interface ILogin {
  email: string;
  password: string;
}

export interface IRegister {
  firstName: string;
  email: string;
  password: string;
}

export interface ILogout {
  accessToken: string | null;
  refreshToken: string | null;
}

export interface IUpdatePassword {
  currentPassword: string;
  newPassword: string;
}

export interface IUpdateEmail {
  code: number;
  email: string;
}

export interface IFavorite {
  trackId?: string;
  albumId?: string;
}

export interface IResetPassword {
  resetToken: string;
  password: string;
}

export interface IContact {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
}

export interface INewsletter {
  firstName: string;
  email: string;
  fullNewsletter: boolean;
}

export interface ISubmit {
  firstName: string;
  lastName: string;
  artistName: string;
  email: string;
  links: string;
  message: string;
}

export type TMusicSelection = {
  trackIds?: string[];
  albumIds?: string[];
};

export interface ILicensing {
  name: string;
  email: string;
  company: string;
  subject: string;
  details: string;
  musicSelection: TMusicSelection;
  website?: string;
  youtubeUrl?: string;
  numberOfViews?: number;
  numberOfFollowers?: number;
  distribution: string;
  totalBudget: number;
  musicBudget: number;
  numberOfProducedItems?: number;
}

export interface IError {
  message: string;
  error?: string;
}

export enum ETermsType {
  HEADER = "HEADER",
  POPUP = "POPUP",
  GLOBAL = "GLOBAL",
  GDPR = "GDPR",
  COOKIES = "COOKIES",
}

