import { useEffect, useState } from "react";

export const useIsClicked = (closeMenu?: () => void) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    if (isClicked) {
      const timeoutId = setTimeout(() => {
        if (closeMenu) {
          return closeMenu();
        }
        setIsClicked(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isClicked]);

  const handleClick = () => {
    setIsClicked(true);
  };

  return { isClicked, handleClick };
};
