import { Link, Stack, Typography } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled, { css } from "styled-components";

export const AlbumTitle = styled(Typography)`
  color: #fff;
  font-family: Kaisei Opti;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-line: underline;
  }

  cursor: pointer;

  ${isSmallDesktop} {
    color: #fff;
    font-size: 1.5rem;
  }
`;

export const AlbumAuthorName = styled.span`
  color: #e3bda0;
  font-family: Kaisei Opti;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-line: underline;
  }

  cursor: pointer;

  ${isSmallDesktop} {
    font-size: 0.75rem;
  }
`;

export const AlbumAuthorDetails = styled(Link)`
  color: ${({ theme }) => theme.palette.textColor.highlighted};
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  cursor: pointer;

  ${isSmallDesktop} {
    font-size: 1rem;
  }
`;

export const AlbumDescription = styled(Typography)<{ $isCollapsed?: boolean }>`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $isCollapsed }) => ($isCollapsed ? 10 : 12)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvaibleForTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.225rem */
`;

export const AlbumCover = styled.img<{
  isLoading?: boolean;
}>`
  border-radius: 8px;
  border: none;

  aspect-ratio: 1 / 1;
  object-fit: contain;

  width: clamp(280px, 100%, 409px);
  height: auto;

  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 29px 0px;
  }

  cursor: pointer;

  display: ${({ isLoading }) => (isLoading ? "none" : "inline-block")};
`;

export const FiltersContainer = styled(Stack)`
  border-radius: 8px;
  border: 2px solid #ba905c;

  padding: 24px 16px 16px;

  background: linear-gradient(180deg, #4b3727 0%, rgba(75, 55, 39, 0.5) 100%);

  ${isSmallDesktop} {
    border: none;
    background: rgba(75, 55, 39, 0.5);
  }
`;

export const FilterName = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Kantumruy Pro;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
`;

export const SongStack = styled(Stack)<{ $isSemiTransparent: boolean; $isActive: boolean }>`
  padding: 8px;
  background: ${({ $isSemiTransparent }) => ($isSemiTransparent ? "rgba(75, 55, 39, 0.8)" : "#4b3628")};
  border-radius: 8px;
  position: relative;

  ${({ $isActive }) =>
    $isActive &&
    css`
      box-shadow: 0 0 20px #fdd09a57;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 2px;
        background: linear-gradient(
          270deg,
          rgba(235, 191, 126, 0.5) 15%,
          rgba(255, 210, 155, 1) 50%,
          rgba(235, 191, 126, 0.5) 85%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    `}

  min-height: 56px;

  ${isSmallDesktop} {
    padding: 8px 16px 8px 8px;
    min-height: 64px;
  }
`;

export const SongTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.highlighted};
  font-family: Kaisei Opti;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-line: underline;
  }

  cursor: pointer;
`;

export const SongAuthor = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.subtitle};
  font-family: Kaisei Opti;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  .composer {
    text-decoration-thickness: 1px;
    text-decoration-skip-ink: auto;
    text-underline-offset: 2px;

    &:hover {
      text-decoration-line: underline;
    }

    cursor: pointer;
  }
`;

export const FiltersTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 1.125rem */
  letter-spacing: 0.01125rem;
  text-transform: capitalize;
`;

export const SearchElement = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  background-color: transparent;
  padding: 4px 8px 4px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #5c412a;
  }
`;
