import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { Formik } from "formik";
import { object, string } from "yup";

import CustomTooltip from "@/components/CustomTooltip";
import FormikInput from "@/components/FormikInput";
import { useIsDesktop } from "@/hooks/layout";
import { TextFieldLabel } from "@/pages/Profile/components";
import { HANDLE_REGEX } from "@/pages/Profile/components/constants";
import { updateMy } from "@/store/actions/user";
import { useAppDispatchUnwrap } from "@/store/hooks";
import { FACEBOOK_REGEX, INSTAGRAM_REGEX, TIKTOK_REGEX } from "@/utils/constants";

const initialValues = {
  instagram: '',
  facebook: '',
  youtube: '',
  tiktok: '',
  business: '',
};

const validationSchema = (t: (key: string) => string) => {
  return object().shape(
    {
      instagram: string().matches(INSTAGRAM_REGEX, t('urlValidation')),
      facebook: string().matches(FACEBOOK_REGEX, t('urlValidation')),
      youtube: string().matches(HANDLE_REGEX, t('handleValidation')),
      tiktok: string().matches(TIKTOK_REGEX, t('urlValidation')),
      business: string().url(t('urlValidation')),
    },
    []
  );
};

const Form = () => {
  const { t } = useTranslation('signup', {
    keyPrefix: 'links',
  });
  const { t: tValidation } = useTranslation('validation');

  const isDesktop = useIsDesktop();

  const dispatch = useAppDispatchUnwrap();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => validationSchema(tValidation)}
      onSubmit={async (values, { setErrors }) => {
        if (
          !values.instagram &&
          !values.facebook &&
          !values.youtube &&
          !values.tiktok &&
          !values.business
        ) {
          setErrors({
            instagram: tValidation('atLeastOneField') || '',
            facebook: tValidation('atLeastOneField') || '',
            youtube: tValidation('atLeastOneField') || '',
            tiktok: tValidation('atLeastOneField') || '',
            business: tValidation('atLeastOneField') || '',
          });
        } else {
          await dispatch(
            updateMy({
              socialLinks: {
                ...values,
                youtube: `https://www.youtube.com/${values.youtube}`,
              },
            })
          );
        }
      }}
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%', zIndex: 5 }}>
          <Stack
            spacing={isDesktop ? '40px' : '24px'}
            alignItems="center"
            width="100%"
          >
            <Stack spacing={isDesktop ? '16px' : '24px'} width="100%">
              <Stack
                direction={isDesktop ? 'row' : 'column'}
                alignItems={isDesktop ? 'center' : 'start'}
                justifyContent={isDesktop ? 'space-around' : 'start'}
                spacing={isDesktop ? '16px' : '8px'}
                width="100%"
                marginRight="36px"
              >
                <TextFieldLabel sx={{ minWidth: '76px !important' }}>
                  {t('youtube')}
                </TextFieldLabel>
                <FormikInput
                  label="youtube"
                  fieldName="youtube"
                  placeholder={t('youtubePlaceholder') || ''}
                />
              </Stack>

              <Stack
                direction={isDesktop ? 'row' : 'column'}
                alignItems={isDesktop ? 'center' : 'start'}
                justifyContent={isDesktop ? 'space-around' : 'start'}
                spacing={isDesktop ? '16px' : '8px'}
                width="100%"
              >
                <TextFieldLabel sx={{ minWidth: '76px !important' }}>
                  {t('facebook')}
                </TextFieldLabel>
                <FormikInput
                  label="facebook"
                  fieldName="facebook"
                  placeholder={t('facebookPlaceholder') || ''}
                />
              </Stack>

              <Stack
                direction={isDesktop ? 'row' : 'column'}
                alignItems={isDesktop ? 'center' : 'start'}
                justifyContent={isDesktop ? 'space-around' : 'start'}
                spacing={isDesktop ? '16px' : '8px'}
                width="100%"
              >
                <TextFieldLabel sx={{ minWidth: '76px !important' }}>
                  {t('instagram')}
                </TextFieldLabel>
                <FormikInput
                  label="instagram"
                  fieldName="instagram"
                  placeholder={t('instagramPlaceholder') || ''}
                />
              </Stack>

              <Stack
                direction={isDesktop ? 'row' : 'column'}
                alignItems={isDesktop ? 'center' : 'start'}
                justifyContent={isDesktop ? 'space-around' : 'start'}
                spacing={isDesktop ? '16px' : '8px'}
                width="100%"
              >
                <TextFieldLabel sx={{ minWidth: '76px !important' }}>
                  {t('tiktok')}
                </TextFieldLabel>
                <FormikInput
                  label="tiktok"
                  fieldName="tiktok"
                  placeholder={t('tiktokPlaceholder') || ''}
                />
              </Stack>

              <Stack
                direction={isDesktop ? 'row' : 'column'}
                alignItems={isDesktop ? 'center' : 'start'}
                justifyContent={isDesktop ? 'space-around' : 'start'}
                spacing={isDesktop ? '16px' : '8px'}
                width="100%"
              >
                <TextFieldLabel sx={{ minWidth: '76px !important' }}>
                  {t('business')}
                </TextFieldLabel>
                <FormikInput
                  label="business"
                  fieldName="business"
                  placeholder={t('businessPlaceholder') || ''}
                />
              </Stack>
            </Stack>

            <Stack width="100%">
              <CustomTooltip
                disableHoverListener={isValid && dirty}
                title={<Trans t={t} i18nKey="toolTip" />}
              >
                <GradientButton
                  type="submit"
                  isDisabled={!(isValid && dirty && !isSubmitting)}
                  isFullWidth
                >
                  {t('buttonText')}
                </GradientButton>
              </CustomTooltip>
            </Stack>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default Form;
