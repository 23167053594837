import { ISortBy } from "@pages/Catalog/components/utils";

export const REGEX_EXP_FOR_LENGTH = /^(([0]?[0-5][0-9]|[0-9]):([0-5][0-9])) - (([0]?[0-5][0-9]|[0-9]):([0-5][0-9]))$/;

export const REGEX_EXP_FOR_BPM = /\d{1,3} - \d{1,3} bpm/;

export const FACEBOOK_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi;

export const INSTAGRAM_REGEX = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim;

export const YOUTUBE_REGEX = /@[\w-]+/gm;

export const TIKTOK_REGEX = /^https?:\/\/(?:www\.)?tiktok\.com\/@([A-Za-z0-9_\-]+)$/i;

export const lengthOfId = 24;

export const NUMBER_OF_CODE_DIGITS = 6;

export enum ERoutes {
  Home = "/",
  Catalog = "/catalog",
  ContactLicensing = "/contact-licensing/:name",
  Licensing = "/contact-licensing/licensing",
  Contact = "/contact-licensing/contact",
  FreeUseTerms = "/free-use",
  Submit = "/submit",
  OurArtists = "/our-artists",
  Favorites = "/favorites",
  Search = "/search",
  Profile = "/profile",
  Conditions = "/conditions",
  Newsletter = "/newsletter",
  Privacy = "/privacy",
  Imprint = "/imprint",
  TransitPage = "/transit-page",
  CatalogLanding = "/catalog-landing/:name",
  CatalogLandingLicensing = "/catalog-landing/licensing",
  CatalogLandingFreeBackground = "/catalog-landing/free-background",
  Shop = "https://www.sanctum.shop/",
  FaceBook = "https://www.facebook.com/profile.php?id=100090626700207",
  Instagram = "https://www.instagram.com/audio_sanctum",
  Twitter = "https://twitter.com/_audiosanctum",
  Youtube = "https://www.youtube.com/@audio-sanctum",
  YoutubeFreeUsePlaylist = "https://www.youtube.com/watch?v=WPRBx6K02z8&list=PLYhbl-MCBKQeE4Bmh3QKXoWUjKHE1j-0A&ab_channel=AudioSanctum",
  Spotify = "https://open.spotify.com/user/31k5gubg4hn5s6jsbitmbm6yck5q",
  SoundCloud = "https://soundcloud.com/audiosanctum",
  Bandcamp = "https://audiosanctum.bandcamp.com/",
  AppleMusic = "https://music.apple.com/us/artist/audio-sanctum/1660603052",
  DownloadInstruction = "/download-instruction",
  Track = "/track/:id",
  Album = "/catalog/:id",
  MUSIC_CREATOR_PROGRAM = "/music-creator",
  FAQ = "/faq",
  Deals = "/deals",
}

enum EContentClassification {
  Art = "Art",
  Cars = "Cars & Vehicles",
  Education = "Educating & Teaching",
  Entertainment = "Entertainment",
  Films = "Film & Animation",
  Gaming = "Gaming",
  Tutorials = "Howto & Tutorials",
  Travel = "Travel & Events",
  Livestreaming = "Livestreaming",
  Meditation = "Meditation & Subliminals",
  Music = "Music",
  News = "News & Politics",
  People = "People & Vlogs",
  Religion = "Religion",
  Science = "Science & Technology",
  Spirituality = "Spirituality",
  Sports = "Sports",
}

enum EUseCases {
  App = "App",
  Clients = "Client Service",
  Commercials = "Commercials & Ads",
  Game = "Game",
  Movie = "Movie, Film & TV",
  Gaming = "Gaming",
  Narrative = "Narrated Content",
  Locations = "Physical Location",
  Podcasts = "Podcast & Radio",
  Events = "Public Event",
  Webinar = "Webinar & Online Course",
}

export const METADATA_OTHER = "Other";

export const CONTENT_CLASSIFICATION = [...Object.values(EContentClassification), METADATA_OTHER];
export const USE_CASES = [...Object.values(EUseCases), METADATA_OTHER];

export enum EMetaDataType {
  UseCases = "USE_CASES",
  ClassifyContent = "CLASSIFY_CONTENT",
}

export enum SoundExamplesPage {
  Home = "Home",
  FreeUseLanding = "Free use landing",
  Licensing = "Licensing",
}

export enum AllFilter {
  ALL = "All",
}

export const sortValues = (t: (key: string) => string): ISortBy[] => [
  {
    value: "recentlyAdded",
    title: t("sortBy.recentlyAdded"),
    sortValue: { updatedAt: "ASC" },
  },
  {
    value: "mostDownloads",
    title: t("sortBy.mostDownloads"),
    sortValue: { downloads: "DESC" },
  },
  {
    value: "mostViews",
    title: t("sortBy.mostViews"),
    sortValue: { views: "DESC" },
  },
  {
    value: "lengthShortest",
    title: t("sortBy.lengthShortest"),
    sortValue: { "summary.length": "ASC" },
  },
  {
    value: "lengthLongest",
    title: t("sortBy.lengthLongest"),
    sortValue: { "summary.length": "DESC" },
  },
  {
    value: "releaseNewest",
    title: t("sortBy.releaseNewest"),
    sortValue: { createdAt: "ASC" },
  },
  {
    value: "releaseOldest",
    title: t("sortBy.releaseOldest"),
    sortValue: { createdAt: "DESC" },
  },
  {
    value: "tempoFastest",
    title: t("sortBy.tempoFastest"),
    sortValue: { "summary.bpm": "DESC" },
  },
  {
    value: "tempoSlowest",
    title: t("sortBy.tempoSlowest"),
    sortValue: { "summary.bpm": "ASC" },
  },
];

export enum ComposersName {
  PATRICK_LENK = "Patrick Lenk",
  SIMON_DAUM = "Simon Daum",
}
