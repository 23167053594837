import { DefaultRootState } from "react-redux";

import { createSelector } from "@reduxjs/toolkit";

import { FavoritesState } from "@/store/reducers/favorites";

export const getFavoritesState = createSelector(
  (state: DefaultRootState) => state.favorites,
  ({ favorites }: FavoritesState) => favorites,
);

export const getFavoritesPageState = createSelector(
  (state: DefaultRootState) => state.favorites,
  ({ isFavoritesPageLoading, favoritesPage }: FavoritesState) => ({ favoritesPage, isFavoritesPageLoading }),
);
