import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useIsDesktop } from "@/hooks/layout";

import { InfoLink } from "../InfoStack/components";

interface ICreditTextProps {
  composer: string;
  songName: string;
  videoUrl: string;
}

const CreditText: FC<ICreditTextProps> = ({ songName, composer, videoUrl }) => {
  const { t } = useTranslation("downloadModal");

  const isDesktop = useIsDesktop();

  return (
    <Trans
      i18nKey="creditTextCopyright"
      t={t}
      components={[
        <InfoLink
          sx={{
            textDecoration: "none !important",
            fontSize: `${isDesktop ? "16px" : "12px"} !important`,
          }}
        />,
      ]}
      values={{
        composer: composer,
        songName: songName,
        videoUrl: videoUrl || "[Paste link to Audio Sanctum YT video]",
      }}
    />
  );
};

export default CreditText;
