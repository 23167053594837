import { createSlice } from "@reduxjs/toolkit";

import { ICategories, IUser } from "@/models/models";
import { setSession } from "@/utils/jwt";

import {
  deleteMy,
  login,
  logout,
  my,
  refreshToken,
  register,
  resetPassword,
  updateEmail,
  updateMy,
  verifyCode,
} from "../actions/user";

export interface UserState {
  loading: boolean;
  user: IUser | null;
  categories: ICategories[];
}

const InitialCategories: ICategories[] = [];

const initialState: UserState = {
  loading: true,
  user: null,
  categories: InitialCategories,
};
const { actions, reducer } = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: () => ({
      ...initialState,
    }),
    setUserEmail: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.user = payload.user as IUser;
        state.loading = false;

        setSession({
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
          accessTokenSecondToExpiration: payload.accessTokenExpiresAt,
          refreshTokenSecondToExpiration: payload.refreshTokenExpiresAt,
        });
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.user = payload.user as IUser;
        state.loading = false;

        setSession({
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
          accessTokenSecondToExpiration: payload.accessTokenExpiresAt,
          refreshTokenSecondToExpiration: payload.refreshTokenExpiresAt,
        });
      })
      .addCase(refreshToken.fulfilled, (_state, { payload }) => {
        if (!payload) {
          return;
        }
        setSession({
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
          accessTokenSecondToExpiration: payload.accessTokenExpiresAt,
          refreshTokenSecondToExpiration: payload.refreshTokenExpiresAt,
        });
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.loading = false;

        localStorage.clear();
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMy.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.user = payload as IUser;
        state.loading = false;
      })
      .addCase(verifyCode.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.user = { ...state.user, isEmailVerified: true };
      })
      .addCase(my.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.user = payload as IUser;
        state.loading = false;
      })
      .addCase(updateEmail.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.user = payload as IUser;
        state.loading = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.user = null;
        state.loading = false;

        localStorage.clear();
      })
      .addCase(deleteMy.fulfilled, (state) => {
        state.user = null;
        state.loading = false;

        localStorage.clear();
        localStorage.setItem("isAccountDeleted", JSON.stringify(true));
      });
  },
});

export const { resetUserState  } = actions;
export default reducer;
