import { Stack } from '@mui/material';

import styled from 'styled-components';

export const UseTermsStack = styled(Stack)`
  & ::-webkit-scrollbar-thumb {
    height: 51px;
    width: 4px;
    background-color: #AC8D75;
  }
    
  & ::-webkit-scrollbar {
    width: 4px;
  }
    
  & ::-webkit-scrollbar-track {
    background-color: #5C412A;
    margin-bottom: 16px;
  }
`