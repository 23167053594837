export const creditText = (songName: string, videoUrl: string) =>
  `Background Music: ${
    songName ? songName : '[Paste Artist - Track Title]'
  }\n` +
  '\n© Provided by Audio Sanctum\n' +
  `\nWatch: ${
    videoUrl ? videoUrl : '[Paste link to Audio Sanctum YT video]'
  }\n` +
  '\nListen: https://spotify.audiosanctum.com\n' +
  '\nFree Use Terms → https://audiosanctum.com/free-use\n';
