import { Checkbox as CheckboxLib } from "@mui/material";

import styled from "styled-components";

export const Checkbox = styled(CheckboxLib)`
  && {
    background-color: #4b3727;
    padding: 0;
    border-radius: 0;

    & .MuiSvgIcon-root {
      font-size: 1.25rem;
    }

    &.Mui-disabled {
      border-color: transparent;
    }

    &:hover {
      background-color: #3c2d23;
    }
  }
`;
