import { Tabs as LibTabs } from "@mui/material";

import styled, { css } from "styled-components";

type TTabs = {
  $isCatalog: boolean;
  $isFirstTabSelected: boolean;
};
export const Tabs = styled(LibTabs)<TTabs>`
  &.MuiTabs-root {
    height: 100%;
  }
  && {
    .MuiTabs-flexContainer {
      height: 100%;
      .MuiTab-root {
        height: 100%;
        position: relative;
        flex: 1;
        max-width: 100%;

        border-bottom: 1px solid #ffdca8;

        ${({ $isCatalog }) =>
          $isCatalog &&
          css`
            border-bottom: 1px solid #9b8166;
          `}

        font: 1.125rem/135% "Kaisei Opti", sans-serif;
        color: #e3bda0;
        text-transform: capitalize;

        border-radius: 8px 8px 0 0;

        background-color: rgba(75, 55, 39, 0.5);

        min-width: 200px;
        min-height: 49px;

        &[aria-selected="true"] {
          color: #fbf4ec;
          border: 1px solid #ffdca8;
          border-bottom: none;
          background-color: rgba(59, 45, 33, 0.2);
          ${({ $isCatalog, $isFirstTabSelected }) =>
            $isCatalog &&
            css`
              border: 1px solid #9b8166;
              border-bottom: none;
              ${$isFirstTabSelected ? "border-left" : "border-right"}: none;
            `}
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
`;
