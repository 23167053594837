import { IError } from "@models/inner-models";
import { TFilters } from "@models/models";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPageCount } from "@utils/handleResponseHeaders";

import instance from "../../middleware/api";
import { TGetAllTracksParams, TTrackAlbumResponse, TTrackResponse } from "./types";

enum ETracks {
  GetTrack = "GET_TRACK",
  GetBottomTrack = "GET_BOTTOM_TRACK",
  GetTracksAlbum = "GET_TRACK_ALBUMS",
  GetTrackByName = "GET_TRACK_BY_NAME",
  GetAllTracks = "GET_ALL_TRACKS",
}

const BASE_URL = "/tracks";

export const track = createAsyncThunk(ETracks.GetTrack, async (trackId: string, thunkAPI) => {
  try {
    const resp = await instance.get<TTrackResponse>(`${BASE_URL}` + `/${trackId}`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const trackAlbum = createAsyncThunk(ETracks.GetTracksAlbum, async (albumId: string, thunkAPI) => {
  try {
    const resp = await instance.get<TTrackAlbumResponse>(`/albums` + `/${albumId}`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const bottomTrack = createAsyncThunk(ETracks.GetBottomTrack, async (trackId: string, thunkAPI) => {
  try {
    const resp = await instance.get<TTrackResponse>(`${BASE_URL}` + `/${trackId}`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const searchTrack = createAsyncThunk(ETracks.GetTrackByName, async (filter: TFilters, thunkAPI) => {
  try {
    const resp = await instance.get<TTrackResponse[]>(BASE_URL + "/search-by-name", { params: filter });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllTracks = createAsyncThunk(ETracks.GetAllTracks, async (data: TGetAllTracksParams, thunkAPI) => {
  try {
    const resp = await instance.get<TTrackResponse[]>(BASE_URL + "/catalog", { params: data });

    return { data: resp.data, pageCount: getPageCount(resp.headers["content-range"], data.limit) };
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});
