import { FC } from "react";

import { SxProps } from "@mui/material";

import { LeftText, RightText, StyledButton, Wrapper } from "./components";

interface IToggleSwitch {
  isToggleFirstElement: boolean;
  rightText: string;
  leftText: string;
  handleClick: () => void;
  isCatalog?: boolean;
  isCatalogLanding?: boolean;
  sx?: SxProps;
}

export const ToggleSwitch: FC<IToggleSwitch> = ({
  isToggleFirstElement,
  leftText,
  rightText,
  handleClick,
  isCatalog = false,
  isCatalogLanding = false,
  sx,
}) => {
  return (
    <Wrapper
      $isCatalog={isCatalog}
      $isCatalogLanding={isCatalogLanding}
      alignItems="center"
      justifyContent="space-between"
      sx={sx}
    >
      <StyledButton>
        <LeftText
          onClick={!isToggleFirstElement ? handleClick : undefined}
          $isCatalog={isCatalog}
          $isToggleFirstElement={isToggleFirstElement}
        >
          {leftText}
        </LeftText>
        <RightText
          onClick={isToggleFirstElement ? handleClick : undefined}
          $isCatalog={isCatalog}
          $isToggleFirstElement={isToggleFirstElement}
        >
          {rightText}
        </RightText>
      </StyledButton>
    </Wrapper>
  );
};
