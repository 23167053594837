import { Link as LibLink } from "@mui/material";

import styled, { css } from "styled-components";

export const Link = styled(LibLink)<{ size: string | number }>`
  color: ${({ theme }) => theme.palette.textColor.highlighted};

  font-family: Raleway;
  font-size: 0.875rem;

  cursor: pointer;

  font-weight: 400;

  ${({ size }) =>
    size &&
    css`
      font-size: ${size};
    `}
  &[disabled] {
    color: ${({ theme }) => theme.palette.textColor.subtitle};
    pointer-events: none;
  }
`;
