import { Switch, Typography } from "@mui/material";

import styled from "styled-components";

type TSwitchLabel = {
  $isChecked: boolean;
};

export const SwitchLib = styled(Switch)`
  && {
    width: 92px;
    height: 36px;
    border-radius: 200px;
    background-color: #453426;
    padding: 0;
    
    .MuiSwitch-root {
    }

    .MuiButtonBase-root {
      padding: 2px;
      &:hover {
        background-color: transparent;
      }
      &.Mui-checked {
        transform: translateX(48px);
      }
    }

    .MuiSwitch-track {
      background-color: transparent;
    }

    .MuiSwitch-thumb {
      width: 40px;
      height: 32px;

      border-radius: 200px;
      background-color: ${({ theme }) => theme.palette.textColor.highlighted};
    }
  }
`;

export const SwitchLabel = styled(Typography)<TSwitchLabel>`
  text-transform: uppercase;

  font-size: 0.875rem;

  color: ${({ $isChecked, theme }) => $isChecked ? theme.palette.textColor.header : "rgba(251, 244, 236, 0.2)"};
`;
