import instance from "@store/middleware/api";

import { IError, IFavorite } from "@models/inner-models";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { TFavoritesByPageResponse } from "./types";

enum Favorites {
  GET_ALL = "GET_ALL_FAVORITES",
  GET_PAGE = "GET_PAGE_FAVORITES",
  CREATE = "CREATE_FAVORITES",
  DELETE = "DELETE_FAVORITES",
}

const BASE_URL = "/favorites";

const getFavorites = createAsyncThunk(Favorites.GET_ALL, async (_, thunkAPI) => {
  try {
    const resp = await instance.get(BASE_URL);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const getFavoritesPage = createAsyncThunk(Favorites.GET_PAGE, async (_, thunkAPI) => {
  try {
    const resp = await instance.get<TFavoritesByPageResponse>(`${BASE_URL}/page`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const createFavorites = createAsyncThunk(Favorites.CREATE, async (favoritesInfo: IFavorite, thunkAPI) => {
  try {
    const resp = await instance.post(BASE_URL, favoritesInfo);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const deleteFavorites = createAsyncThunk(Favorites.DELETE, async (favoritesInfo: IFavorite, thunkAPI) => {
  try {
    const resp = await instance.delete(BASE_URL, { data: favoritesInfo });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export { getFavorites, createFavorites, deleteFavorites, getFavoritesPage };
