import { Link } from "@mui/material";

import he from "he";
import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

export const formatHtml = (htmlString: string) => {
  const unescapedHtmlString = he.decode(htmlString);

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const el = domNode as Element;
      if (el.name === "a") {
        return (
          <Link
            href={el.attribs?.href || "#"}
            target="_blank"
            underline="hover"
            sx={{ color: "textColor.highlighted", fontFamily: "Raleway" }}
          >
            {domToReact(el.children, options)}
          </Link>
        );
      }
    },
  };

  return parse(unescapedHtmlString, options);
};
