import React, { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import { BackToCatalogueIcon } from "@assets/svg";

import { ERoutes } from "@utils/constants";

import * as Styled from "./styles";

export const BackButton: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Styled.Button onClick={() => navigate(ERoutes.Catalog)}>
      <Stack direction="row" alignItems="center" spacing="16px">
        <BackToCatalogueIcon />
        {children}
      </Stack>
    </Styled.Button>
  );
};
