import { createSlice } from '@reduxjs/toolkit';

import { contact, licensing } from '@/store/actions/contactUs';

export interface ContactUsState {
  loading: boolean;
}

const initialState: ContactUsState = {
  loading: true,
};

const { reducer } = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contact.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.loading = false;
      })
      .addCase(licensing.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.loading = false;
      });
  },
});

export default reducer;
