import DownloadService from '@/services/download.service';

export const download = async (url: string, titleForDownloading: string) => {
  try {
    const blob = await DownloadService.downloadFile(url);

    const a = document.createElement('a');
    a.style.display = 'none';

    const blobURL = window.URL.createObjectURL(blob);
    a.href = blobURL;
    a.download = titleForDownloading;

    a.click();

    window.URL.revokeObjectURL(blobURL);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const downloadZip = async (url: string, titleForDownloading: string) => {
  const blob = await DownloadService.downloadFile(url);
  const csvURL = window.URL.createObjectURL(blob);

  const tempLink = document.createElement('a');

  tempLink.href = csvURL;
  tempLink.setAttribute('download', `${titleForDownloading}.zip`);
  tempLink.click();
};
