import styled from "styled-components";

export const InputWrapper = styled.div`
  margin-bottom: 48px !important;

  .container {
    width: fit-content;
    height: fit-content;
    gap: 16px;
  }

  .character {
    width: 48px;
    height: 48px;

    font-size: 2.25rem;
    line-height: 130%;
    font-family: "Kantumruy Pro";
    font-weight: 300;

    border-radius: 8px;

    background-color: #453426;

    color: ${({ theme }) => theme.palette.textColor.common};

    border: 2px solid #ffdca8;
  }

  .character-selected {
    outline: 1px solid ${({ theme }) => theme.palette.textColor.header};
  }
`;
