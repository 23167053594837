const PAGE_CONTAINER_GRADIENT = "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(113,81,55,1) 95%)";

export const setBackgroundImageStyle = (isPage: boolean, backgroundImage: string) => {
  if (!isPage) {
    return {
      backgroundImage: `url(${backgroundImage})`,
    };
  }

  return { backgroundImage: `${PAGE_CONTAINER_GRADIENT}, url(${backgroundImage})` };
};
