import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AsyncThunkAction } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppDispatchUnwrap = () => {
  const dispatch = useAppDispatch();

  async function dispatchUnwrapped<R extends any>(
    action: AsyncThunkAction<R, any, any>
  ): Promise<R> {
    return dispatch(action).unwrap();
  }

  return dispatchUnwrapped;
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
