import { isDesktop, isSmallDesktop } from "@hooks/layout";

import styled, { css } from "styled-components";

type TContainer = {
  $isPage: boolean;
  $withBanner: boolean;
  $isHero: boolean;
  $minHeight: number;
  $isBackgroundPositionBottom: boolean;
};

type TContentContainer = {
  $isCentered: boolean;
} & Pick<TContainer, "$isHero">;

export const Container = styled.div<TContainer>`
  position: relative;
  width: 100%;
  height: ${({ $isPage }) => ($isPage ? "auto" : "100%")};
  min-height: ${({ $isPage }) => ($isPage ? "50dvh" : "812px")};

  overflow-x: hidden;

  ${({ $minHeight }) =>
    !!$minHeight &&
    css`
      min-height: ${`${$minHeight}px`};
    `}

  padding-top: 48px;
  padding-bottom: 48px;

  background-position-y: ${({ $isPage }) => ($isPage ? "top" : "center")};
  background-position-x: center;

  background-size: ${({ $isPage }) => ($isPage ? "cover" : "cover")};

  background-color: #715137;

  background-repeat: no-repeat;

  display: flex;

  ${({ $isHero }) =>
    $isHero &&
    css`
      height: calc(100dvh - 72px);
      width: 100dvw;
    `}
  ${isSmallDesktop} {
    padding-top: ${({ $isPage }) => ($isPage ? "120px" : "48px")};
    padding-bottom: ${({ $isPage }) => ($isPage ? "80px" : "48px")};
    ${({ $withBanner, $isPage }) =>
      $isPage
        ? css`
            padding: ${$withBanner ? "72px 0 176px" : "96px 0 176px"};
            background-position-x: 65%;
          `
        : css`
            min-height: 821px;
            padding-block: 160px;
          `}
    ${({ $isHero }) =>
      $isHero &&
      css`
        height: calc(100dvh - 95px);
      `}
  }
  ${({ $isBackgroundPositionBottom }) =>
    $isBackgroundPositionBottom &&
    css`
      background-position-y: bottom;
    `}
`;

export const ContentContainer = styled.div<TContentContainer>`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  z-index: 1;

  align-self: start;

  padding-inline: 16px;

  max-width: 2260px;
  max-height: 100%;

  ${isSmallDesktop} {
    align-self: center;
    padding-inline: 40px;
    ${({ $isHero }) =>
      $isHero &&
      css`
        display: flex;
        align-items: center;
      `}
  }

  ${isDesktop} {
    padding-inline: 250px;
  }

  && {
    ${({ $isCentered }) =>
      $isCentered &&
      css`
        ${isSmallDesktop} {
          padding-inline: 0;
          max-width: 100%;
        }
      `}
  }
`;
