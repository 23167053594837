import { DefaultRootState } from "react-redux";

import { createSelector } from "@reduxjs/toolkit";

import { TrackState } from "../../reducers/track";

export const getTracksSelector = createSelector(
  (state: DefaultRootState) => state.tracks,
  ({ track, albumTracks, isAlbumLoading, isTrackLoading }: TrackState) => ({
    track,
    albumTracks,
    isTrackLoading,
    isAlbumLoading,
  }),
);
