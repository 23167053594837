import { FC } from "react";

import { Step, StepIcon, StepLabel } from "@mui/material";

import { CustomStepConnector, MuiStepper } from "@/components/SignUpDialog/components/Stepper/components";

interface IStepper {
  activeStep: number;
  steps?: string[];
}

const Stepper: FC<IStepper> = ({ activeStep, steps = ["01", "02", "03", "04", "05"] }) => {
  return (
    <>
      <MuiStepper activeStep={activeStep} connector={<CustomStepConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <StepIcon {...props} icon={label} active={props.active} completed={false} />
              )}
            ></StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </>
  );
};

export default Stepper;
