import React, { FC, PropsWithChildren } from "react";

import { AvatarProps, Stack } from "@mui/material";

import * as Styled from "./styles";
import { getComposerAvatar } from "./utils";

type TAvatar = AvatarProps & {
  isSmall?: boolean;
  isGroup?: boolean;
  composerName?: string;
};

type TAvatarGroup = TAvatar & PropsWithChildren;

const AvatarWrapper: FC<TAvatar> = ({ composerName, isSmall, ...restProps }) => (
  <Styled.AvatarWrapper
    src={getComposerAvatar(composerName)}
    imgProps={{ loading: "lazy" }}
    $isSmall={isSmall}
    {...restProps}
  />
);

export const Avatar: FC<TAvatarGroup> = (props) => {
  const { children, isSmall = false, isGroup = false, composerName, ...restProps } = props;
  return isGroup ? (
    <AvatarWrapper composerName={composerName} isSmall={isSmall} {...restProps} />
  ) : (
    <Stack direction="row" alignItems="center" spacing="8px">
      <AvatarWrapper composerName={composerName} isSmall={isSmall} {...restProps} />
      {children}
    </Stack>
  );
};
