import { FC, ReactNode } from "react";

import { SxProps } from "@mui/material";

import { InnerBorderStack, OuterBorderStack } from "@/components/BorderStack/components";

interface IBorderStack {
  children: ReactNode;
  isProfilePage?: boolean;
  sx?: SxProps;
  withoutBorder?: boolean;
  isWhiteBorder?: boolean;
  withPadding?: boolean;
}

const BorderStack: FC<IBorderStack> = ({
  children,
  isProfilePage = false,
  withoutBorder = false,
  isWhiteBorder = false,
  withPadding = false,
}) => {
  return (
    <OuterBorderStack $withoutBorder={withoutBorder} $isWhiteBorder={isWhiteBorder} $withPadding={withPadding}>
      <InnerBorderStack
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        $isProfilePage={isProfilePage}
        $withoutBorder={withoutBorder}
      >
        {children}
      </InnerBorderStack>
    </OuterBorderStack>
  );
};

export default BorderStack;
