import { resetFavoritesState } from "@store/reducers/favorites";

import { ELocalStoreKeys } from "@/models/models";
import { logout } from "@/store/actions/user";
import { useAppDispatch, useAppDispatchUnwrap, useAppSelector } from "@/store/hooks";
import { resetUserState } from "@/store/reducers/user";
import { getMySelector, isAuthorized, loadingUser } from "@/store/selectors/getMeSelector";
import { getWithExpiry } from "@/utils/localStorage";

export const useAuth = () => {
  const isLoggedIn = useAppSelector(isAuthorized);
  const myData = useAppSelector(getMySelector);
  const isLoading = useAppSelector(loadingUser);

  return { isLoggedIn, myData, isLoading };
};

export const useLogOut = () => {
  const dispatch = useAppDispatchUnwrap();
  const syncDispatch = useAppDispatch();

  const accessToken = getWithExpiry(ELocalStoreKeys.TOKEN);
  const refreshToken = getWithExpiry(ELocalStoreKeys.REFRESH_TOKEN);
  const logoutUser = async () => {
    await dispatch(
      logout({
        refreshToken: refreshToken.value,
        accessToken: accessToken.value,
      }),
    );
  };
  const resetUser = () => {
    syncDispatch(resetUserState());
    syncDispatch(resetFavoritesState());
  };
  return { logoutUser, resetUser };
};
