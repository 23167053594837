import { FC, useEffect } from "react";

import { Skeleton, Stack, Typography, TypographyProps } from "@mui/material";

import { ETermsType } from "@/models/inner-models";
import { terms } from "@/store/actions/terms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { getTermsText, isLoadingTerms } from "@/store/selectors/getTermsSelector";
import { formatHtml } from "@/utils/formatHtml";

const CopyrightProtected: FC<TypographyProps> = ({ variant }) => {
  const dispatch = useAppDispatch();
  const headerTerms = useAppSelector(getTermsText);
  const isLoading = useAppSelector(isLoadingTerms);

  const htmlString = (headerTerms?.HEADER as string) || "";

  useEffect(() => {
    const getTerms = async () => {
      await dispatch(terms(ETermsType.HEADER));
    };
    getTerms();
  }, []);

  return (
    <>
      <Typography
        component="span"
        variant={variant}
        textAlign="center"
        sx={{ color: "textColor.common", fontFamily: "Raleway !important" }}
      >
        {isLoading && (
          <Stack justifyContent="center" alignItems="center">
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="50%" />
          </Stack>
        )}
        {formatHtml(htmlString)}
      </Typography>
    </>
  );
};
export default CopyrightProtected;
