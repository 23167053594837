import { combineReducers } from "@reduxjs/toolkit";

import contactUsReducer, { ContactUsState } from "@/store/reducers/contactUs";
import favoritesReducer, { FavoritesState } from "@/store/reducers/favorites";

import albumReducer, { AlbumState } from "../reducers/album";
import catalogueReducer, { CatalogueState } from "../reducers/catalogue";
import dealsReducer, { DealsState } from "../reducers/deals";
import downloadsReducer, { DownloadsState } from "../reducers/downloads";
import filtersReducer, { FiltersState } from "../reducers/filters";
import searchResultsReducer, { SearchResultsState } from "../reducers/searchResults";
import soundExamplesReducer, { SoundExamplesState } from "../reducers/soundExamples";
import termsReducer, { TermsState } from "../reducers/terms";
import trackReducer, { TrackState } from "../reducers/track";
import userReducer, { UserState } from "../reducers/user";

const rootReducer = combineReducers({
  user: userReducer,
  filters: filtersReducer,
  catalogue: catalogueReducer,
  terms: termsReducer,
  contactUs: contactUsReducer,
  favorites: favoritesReducer,
  downloads: downloadsReducer,
  tracks: trackReducer,
  albums: albumReducer,
  searchResults: searchResultsReducer,
  soundExamples: soundExamplesReducer,
  deals: dealsReducer,
});

export default rootReducer;

type TRootState = {
  user: UserState;
  filters: FiltersState;
  catalogue: CatalogueState;
  terms: TermsState;
  contactUs: ContactUsState;
  favorites: FavoritesState;
  downloads: DownloadsState;
  tracks: TrackState;
  albums: AlbumState;
  searchResults: SearchResultsState;
  soundExamples: SoundExamplesState;
  deals: DealsState;
};

declare module "react-redux" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends TRootState {}
}
