import { forwardRef } from "react";

import { TextFieldProps } from "@mui/material";

import * as Styled from "./styles";

type TAdditionalTextField = TextFieldProps;

export const AdditionalTextField = forwardRef<HTMLInputElement, TAdditionalTextField>((props, ref) => {
  return <Styled.TextFieldLib ref={ref} {...props} />;
});
