import { MAX_VOLUME } from "../constants";

const TRACK_VOLUME = "as:track-volume";
const TRACK_MUTED = "as:track-muted";

export const setVolume = (value: number) => {
  localStorage.setItem(TRACK_VOLUME, JSON.stringify(value));
};

export const setMuted = (isMuted: boolean) => {
  localStorage.setItem(TRACK_MUTED, JSON.stringify(isMuted));
};

export const getVolume = () => {
  const volume = JSON.parse(localStorage.getItem(TRACK_VOLUME));
  return volume !== null ? Number(volume) : MAX_VOLUME;
};

export const getIsMuted = () => {
  return Boolean(JSON.parse(localStorage.getItem(TRACK_MUTED))) || false;
};
