import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { useAuth } from "@hooks/auth";

import { PlayerContext, TPlayerContext } from "@layouts/PlayerLayout/utils";
import { useCountdown } from "usehooks-ts";

import { AVAILABLE_TIME, MUSIC_PLAYBACK } from "./constants";

export const useCountDownPlayback = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const { data } = useContext(PlayerContext) as TPlayerContext;
  const [isUserCanPlay, setIsUserCanPlay] = useState<boolean>(true);
  const [cookies, setCookie] = useCookies([MUSIC_PLAYBACK]);

  const initialCountdown = cookies[MUSIC_PLAYBACK] !== undefined ? parseInt(cookies[MUSIC_PLAYBACK]) : AVAILABLE_TIME;
  const [count, { startCountdown, stopCountdown }] = useCountdown({ countStart: initialCountdown });

  const isUserAuthorized = isLoggedIn && !isLoading;

  // Handle playback state based on user authentication status and playback status
  useEffect(() => {
    if (isLoading) return;

    if (isUserAuthorized) {
      setIsUserCanPlay(true);
    } else if (data?.isPlaying) {
      startCountdown();
    } else {
      stopCountdown();
    }
  }, [isUserAuthorized, data?.isPlaying, isLoading]);

  // Update cookie and playback status based on countdown
  useEffect(() => {
    if (isLoading) return;
    if (isUserAuthorized) return;
    if (count === 0) setIsUserCanPlay(false);

    if (count !== initialCountdown) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7);

      setCookie(MUSIC_PLAYBACK, count.toString(), { expires: expiryDate, path: "/" });
    }
  }, [count, isUserAuthorized, initialCountdown, isLoading]);

  // Check playback status on mount
  useEffect(() => {
    if (isLoading) return;
    if (!isUserAuthorized && cookies[MUSIC_PLAYBACK] === "0") {
      setIsUserCanPlay(false);
    }
  }, [cookies, isUserAuthorized, isLoading]);

  return { isUserCanPlay };
};
