import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Stack } from "@mui/material";

import { Button as GradientButton } from "@ui/index";

import { Formik } from "formik";
import { object, ref, string } from "yup";

import FormikInput from "@/components/FormikInput";
import { useIsDesktop } from "@/hooks/layout";
import { ERecovery } from "@/layouts/SecondRootLayout";
import { IError } from "@/models/inner-models";
import { resetPassword, sendVerificationLink } from "@/store/actions/user";
import { useAppDispatchUnwrap } from "@/store/hooks";

const initialValues = {
  email: "",
  password: "",
  passwordConfirmation: "",
};

const validationSchema = (t: (key: string) => string, step: ERecovery) => {
  switch (step) {
    case ERecovery.FirstStep:
      return object({
        email: string().email(t("emailValidation")).required(t("fieldRequired")),
      });
    case ERecovery.ThirdStep:
      return object({
        password: string().min(8, t("passwordValidation")).required(t("fieldRequired")),
        passwordConfirmation: string()
          .oneOf([ref("password"), ""], t("passwordsMatch"))
          .required(t("fieldRequired")),
      });
  }
};

interface IForm {
  step: ERecovery;
  buttonText: string;
  handleNextStep: () => void;
  resetToken?: string | null;
}

const Form: FC<IForm> = ({ step, buttonText, handleNextStep, resetToken }) => {
  const { t } = useTranslation("login");
  const { t: tValidation } = useTranslation("validation");

  const [showPassword, setShowPassword] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isDesktop = useIsDesktop();

  const dispatch = useAppDispatchUnwrap();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => validationSchema(tValidation, step)}
      onSubmit={async (values, { setFieldError }) => {
        if (step === ERecovery.FirstStep) {
          try {
            await dispatch(sendVerificationLink({ email: values.email }));
            localStorage.setItem("email", values.email);
            handleNextStep();
          } catch (err) {
            const message = (err as IError).message;

            setFieldError("email", message);
          }
        }

        if (step === ERecovery.ThirdStep) {
          try {
            if (!resetToken) return null;

            await dispatch(
              resetPassword({
                password: values.password,
                resetToken,
              }),
            );

            searchParams.delete("token");
            setSearchParams(searchParams);
            handleNextStep();
          } catch (err) {
            const message = (err as IError).message;
            setFieldError("password", message);
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%", zIndex: 5 }}>
          <Stack spacing="40px" alignItems="center" width="100%">
            <Stack spacing="16px" width="100%" alignItems="center" justifyContent="center">
              {step === ERecovery.FirstStep && (
                <FormikInput
                  fieldName="email"
                  label={t("email")}
                  placeholder={t("email") ?? ""}
                  type="email"
                  autoComplete="email"
                />
              )}

              {step === ERecovery.ThirdStep && (
                <Stack spacing={isDesktop ? "24px" : "16px"} width="100%">
                  <FormikInput
                    fieldName="password"
                    label={t("password")}
                    placeholder={t("password") ?? ""}
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    InputAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "textColor.highlighted" }} />
                          ) : (
                            <Visibility sx={{ color: "textColor.highlighted" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <FormikInput
                    fieldName="passwordConfirmation"
                    label={t("passwordConfirmation")}
                    placeholder={t("passwordConfirmation") ?? ""}
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    InputAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "textColor.highlighted" }} />
                          ) : (
                            <Visibility sx={{ color: "textColor.highlighted" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Stack>
              )}
            </Stack>

            <Stack spacing="24px" width="100%" justifyContent="center" alignItems="center">
              <GradientButton type="submit" isFullWidth isDisabled={!(isValid && dirty && !isSubmitting)}>
                {buttonText}
              </GradientButton>
            </Stack>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default Form;
