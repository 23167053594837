import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { ShareIcon } from "@assets/svg";
import { CopyLinkIcon } from "@assets/svg/player";

import { useIsDesktop } from "@hooks/layout";
import { useIsClicked } from "@hooks/useIsClicked";

import { MenuWrapper } from "@components/SongMenu/styles";
import { ERoutes } from "@utils/constants";
import { copyLink } from "@utils/helpers";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { handleCloseOnKeyDown, IconButton, Menu } from "@/ui-kit";

import { MenuItem } from "./components";

type TSongMenu = {
  songId: string;
};

export const ShareMenu: FC<TSongMenu> = ({ songId }) => {
  const isDesktop = useIsDesktop();

  const { t } = useTranslation("downloadModal", { keyPrefix: "menuOptions" });

  const popupState = usePopupState({ variant: "popper", popupId: "bottom-player-menu-popover" });

  const { handleClick, isClicked } = useIsClicked(popupState.close);

  if (isDesktop) {
    return (
      <>
        <IconButton {...bindTrigger(popupState)}>
          <ShareIcon width="24px" height="24px" />
        </IconButton>
        <Menu popupState={popupState} offset={[0, 5]} placement="bottom-end">
          <MenuWrapper autoFocus={popupState.isOpen} onKeyDown={(event) => handleCloseOnKeyDown(event, popupState)}>
            <MenuItem
              handleClick={() => {
                handleClick();
                copyLink(ERoutes.Track, songId);
              }}
              icon={<CopyLinkIcon />}
              text={isClicked ? t("copied") : t("copyLink")}
            />
          </MenuWrapper>
        </Menu>
      </>
    );
  }
};
