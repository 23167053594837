import { Typography } from "@mui/material";

import { getTextWidth } from "@ui/typography/Text/utils";

import styled, { css } from "styled-components";

type TText = {
  $isHeading: boolean;
  $isFullWidth: boolean;
  $isBold: boolean;
  size: number;
  color: string;
  $fontWeight: number;
  $lineHeight: number;
};

export const Text = styled(Typography)<TText>`
  && {
    font-family: ${({ $isHeading }) => ($isHeading ? "Kaisei Opti" : "Raleway")};
    letter-spacing: ${({ $isHeading }) => ($isHeading ? "0.015rem" : "normal")};
    font-weight: ${({ $isHeading }) => ($isHeading ? "500" : "400")};
    text-transform: ${({ $isHeading }) => ($isHeading ? "capitalize" : "none")};
    color: ${({ $isHeading, theme }) => ($isHeading ? theme.palette.textColor.header : theme.palette.textColor.common)};
    font-size: ${({ size }) => `${size}rem`};
    max-width: ${({ $isFullWidth, $isHeading }) => getTextWidth($isHeading, $isFullWidth)};

    ${({ $isBold }) =>
      $isBold &&
      css`
        font-weight: 600;
      `}

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}

        ${({ $fontWeight }) =>
      $fontWeight &&
      css`
        font-weight: ${$fontWeight};
      `}

        line-height: 150%;

    ${({ $lineHeight }) =>
      $lineHeight &&
      css`
        line-height: ${$lineHeight}%;
      `}
  }
`;
