import {
  handleErrorResponse,
  handleRequest,
  handleSuccessResponse,
} from '@store/middleware/errorInterceptor';

import axios from 'axios';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
});

export const setupResponseInterceptor = (store: any) => {
  instance.interceptors.request.use(handleRequest);
  instance.interceptors.response.use(
    (response) => handleSuccessResponse(response),
    (error) => handleErrorResponse(error, store)
  );
};

export default instance;
