import { createTheme } from '@mui/material/styles';

import breakpoints from '@/theme/breakpoints';
import components from '@/theme/components';
import palette from '@/theme/palette';
import typography from '@/theme/typography';

const theme = createTheme({
  spacing: 5,
  palette,
  typography,
  components,
  breakpoints,
});

export default theme;
