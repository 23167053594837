import { FC, PropsWithChildren } from "react";

import { Stack } from "@mui/material";

import { useIsDesktop } from "@hooks/layout";

import { FilterButton } from "./styles";

type TFilterButtons = {
  primaryButtonText: string;
  isPrimarySelected: boolean;
  handleSetPrimarySelected: () => void;
} & PropsWithChildren;

export const FilterButtons: FC<TFilterButtons> = ({
  primaryButtonText,
  isPrimarySelected,
  handleSetPrimarySelected,
  children,
}) => {
  const isDesktop = useIsDesktop();
  return (
    <Stack direction="row" alignItems="center" spacing={isDesktop ? "16px" : "8px"}>
      <FilterButton $isSelected={isPrimarySelected} onClick={handleSetPrimarySelected}>
        {primaryButtonText}
      </FilterButton>
      {children}
    </Stack>
  );
};
