import { Stack, Typography } from "@mui/material";

import { isDesktop, isSmallDesktop } from "@hooks/layout";

import styled from "styled-components";

export const BottomPlayer = styled.div`
  z-index: 100;

  background: rgba(75, 55, 39, 1);

  border-top: 2px solid;
  border-image-slice: 1;

  border-image-source: linear-gradient(
    270deg,
    rgba(235, 191, 126, 0.32) 15%,
    rgba(255, 210, 155, 1) 50%,
    rgba(235, 191, 126, 0.32) 85%
  );

  max-width: 100%;
  bottom: 0;

  max-height: 134px;

  ${isSmallDesktop} {
    max-height: 104px;
  }

  position: sticky;
`;

export const SongAuthor = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.subtitle};
  font-family: Kaisei Opti;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-line: underline;
  }

  cursor: pointer;
`;

export const SongTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.highlighted};
  font-family: Kaisei Opti;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration-thickness: 1px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-line: underline;
  }

  cursor: pointer;
`;

export const TimeDuration = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  font-family: Kantumruy Pro;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`;

export const WaveFormStack = styled(Stack)<{ $isCurrent: boolean }>`
  #waveform ::part(cursor) {
    width: ${({ $isCurrent }) => ($isCurrent ? "2px" : "0")};
  }
`;

export const AlbumCover = styled.img`
  border-radius: 4px;
  width: 48px;
  height: auto;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 29px 0px;
  }

  cursor: pointer;
`;

export const SongStack = styled(Stack)`
  max-width: 2260px;
  margin: 0 auto;

  padding: 12px 16px;

  ${isSmallDesktop} {
    padding: 28px 40px;
  }

  ${isDesktop} {
    padding: 28px 250px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -22px;
  right: 16px;

  width: 45px;
  height: 45px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-left-color: rgba(235, 191, 126, 0.32);
  border-top-color: rgba(235, 191, 126, 0.32);
  transform: rotate(45deg);

  cursor: pointer;

  background: rgba(75, 55, 39, 1);

  svg {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    transform: rotate(-45deg);
    -webkit-backface-visibility: initial !important;
    -webkit-transform-origin: 50% 50%;
  }

  path {
    stroke: #ac8d75;

    transition: stroke 0.3s ease-in-out;
  }

  &:hover {
    path {
      stroke: #ffdca8;
    }
  }

  ${isSmallDesktop} {
    right: 40px;
  }

  ${isDesktop} {
    right: 250px;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: relative;
  max-width: 2260px;
  width: 100%;
  margin: 0 auto;
`;
