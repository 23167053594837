import React, { FC, PropsWithChildren } from 'react';

import { Stack } from '@mui/material';

import { WideHorizontalLine } from '@/assets/svg';
import { useIsDesktop } from '@/hooks/layout';

const LinedTitle: FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useIsDesktop();
  return (
    <>
      {isDesktop ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing="40px"
        >
          <WideHorizontalLine />
          {children}
          <WideHorizontalLine />
        </Stack>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LinedTitle;
