import { KeyboardEvent } from "react";

import { PopupState } from "material-ui-popup-state/hooks";

export const handleCloseOnKeyDown = (event: KeyboardEvent, popupState: PopupState) => {
  if (event.key === "Tab") {
    event.preventDefault();
    popupState.close();
  } else if (event.key === "Escape") {
    popupState.close();
  }
};