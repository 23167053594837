import React, { createRef, FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";

import { Stack, Typography } from "@mui/material";

import { InputWrapper } from "@components/SignUpDialog/components/VerifyEmail/components";

import { CheckEmailIcon } from "@/assets/svg";
import { DescriptionTypography } from "@/components/LoginDialog/components";
import { MainTextTypography } from "@/components/RecoveryDialog/components";
import { useIsDesktop } from "@/hooks/layout";
import { sendVerificationCode, verifyCode } from "@/store/actions/user";
import { useAppDispatchUnwrap } from "@/store/hooks";
import { NUMBER_OF_CODE_DIGITS } from "@/utils/constants";

type TVerifyEmail = {
  handleNextStep: () => void;
};

const VerifyEmail: FC<TVerifyEmail> = ({ handleNextStep }) => {
  const { t } = useTranslation("signup", {
    keyPrefix: "verifyEmail",
  });
  const isDesktop = useIsDesktop();
  const authRef = createRef<HTMLInputElement>();
  const [errorText, setErrorText] = useState<string>("");

  const dispatch = useAppDispatchUnwrap();

  useEffect(() => {
    const sendCode = async () => {
      await dispatch(sendVerificationCode());
    };

    sendCode();
  }, []);

  useEffect(() => {
    if (authRef.current !== null) {
      authRef.current.addEventListener("focus", () => {
        window.scrollTo(0, 100);
      });
      authRef.current.focus();
    }
  }, [authRef.current]);

  const handleChange = async (value: string) => {
    if (value.length === NUMBER_OF_CODE_DIGITS) {
      await dispatch(verifyCode({ code: Number(value) }))
        .then(async () => {
          handleNextStep();
        })
        .catch((res) => setErrorText(res.message));
    }
  };

  return (
    <Stack spacing={isDesktop ? "32px" : "24px"} width="100%" justifyContent="center" alignItems="center">
      <Stack spacing={isDesktop ? "32px" : "16px"} width="100%" justifyContent="center" alignItems="center">
        <Stack spacing="16px" height="100%" width="100%" justifyContent="center" alignItems="center">
          <CheckEmailIcon />
          <Stack spacing="16px" justifyContent="center" alignItems="center">
            <MainTextTypography>{t("mainText")}</MainTextTypography>

            <DescriptionTypography>
              <Trans t={t} i18nKey="description" />
            </DescriptionTypography>
          </Stack>
        </Stack>
        <InputWrapper>
          <VerificationInput
            ref={authRef}
            classNames={{
              container: "container",
              character: "character",
              characterSelected: "character-selected",
            }}
            onChange={handleChange}
            placeholder=""
            validChars="0-9"
            inputProps={{
              autoComplete: "one-time-code",
              inputMode: "numeric",
            }}
          />
          {Boolean(errorText.length) && (
            <Typography textAlign="center" color="textColor.common" mt="8px" fontSize="1rem">
              {errorText}
            </Typography>
          )}
        </InputWrapper>
      </Stack>
    </Stack>
  );
};

export default VerifyEmail;
