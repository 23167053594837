import {
  createFavorites,
  deleteFavorites,
  getFavorites,
  getFavoritesPage,
  TFavoritesByPage,
} from "@store/actions/favorites";

import { createSlice } from "@reduxjs/toolkit";

import { ICatalogue, IFavorites } from "@/models/models";

export interface FavoritesState {
  favorites: IFavorites[];
  albums: ICatalogue[];
  favoritesPage?: TFavoritesByPage;
  loading: boolean;
  isFavoritesPageLoading: boolean;
}

const InitialFavorites: IFavorites[] = [];
const InitialAlbums: ICatalogue[] = [];

const initialState: FavoritesState = {
  favorites: InitialFavorites,
  albums: InitialAlbums,
  loading: true,
  isFavoritesPageLoading: true,
};

const { actions, reducer } = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    resetFavoritesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavorites.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.favorites = payload as IFavorites[];
        state.loading = false;
      })
      .addCase(getFavorites.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFavorites.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.favorites = [...state.favorites, payload];
        state.loading = false;
      })
      .addCase(deleteFavorites.fulfilled, (state, { payload, meta }) => {
        if (!payload) {
          return;
        }
        state.favorites = [...state.favorites].filter(({ albumId, trackId }) => {
          return albumId !== meta.arg.albumId || trackId !== meta.arg.trackId;
        });

        state.loading = false;
      })
      .addCase(getFavoritesPage.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }
        state.favoritesPage = payload;
        state.isFavoritesPageLoading = false;
      })
      .addCase(getFavoritesPage.pending, (state) => {
        state.isFavoritesPageLoading = true;
      });
  },
});

export const { resetFavoritesState } = actions;
export default reducer;
