import { createAsyncThunk } from '@reduxjs/toolkit';

import { IContact, IError, ILicensing } from '@/models/inner-models';
import { EContactUs } from '@/store/constants';
import instance from '@/store/middleware/api';

const contact = createAsyncThunk(
  EContactUs.uploadContact,
  async (contactInfo: IContact, thunkAPI) => {
    try {
      const resp = await instance.post('/contact-us/contact', contactInfo);

      return resp?.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const licensing = createAsyncThunk(
  EContactUs.uploadLicensing,
  async (licensingInfo: ILicensing, thunkAPI) => {
    try {
      const resp = await instance.post(
        '/contact-us/music-request',
        licensingInfo
      );

      return resp?.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export { contact, licensing };
