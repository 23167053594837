import { createAsyncThunk } from '@reduxjs/toolkit';

import { ETermsType, IError } from '@/models/inner-models';
import { ETerms } from '@/store/constants';
import instance from '@/store/middleware/api';

const terms = createAsyncThunk(
  ETerms.getTerms,
  async (type: ETermsType, thunkAPI) => {
    try {
      const resp = await instance.get('/terms/by-type', { params: { type } });

      return resp?.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export { terms };
