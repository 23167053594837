import React from "react";

import { Skeleton, Stack } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

export enum TSkeletons {
  AlbumCover = "albumCover",
  PlayButton = "playButton",
  LineProgress = "lineProgress",
  TITLE = "TITLE",
  COMPOSER = "COMPOSER",
  TIME = "TIME",
}

export const getSkeleton = (type: TSkeletons, isDesktop?: boolean) => {
  const map = {
    [TSkeletons.AlbumCover]: (
      <Stack>
        <Skeleton width="48px" height="48px" />
      </Stack>
    ),
    [TSkeletons.PlayButton]: (
      <Stack>
        <Skeleton variant="circular" width={isDesktop ? "32px" : "40px"} height={isDesktop ? "32px" : "40px"} />
      </Stack>
    ),
    [TSkeletons.TITLE]: <Skeleton width="100px" />,
    [TSkeletons.COMPOSER]: <Skeleton width="60px" />,
    [TSkeletons.TIME]: <Skeleton width="32px" />,
    [TSkeletons.LineProgress]: (
      <Stack width="100%" color="textColor.highlighted">
        <LinearProgress color="inherit" />
      </Stack>
    ),
  };

  return map[type] ?? <Skeleton />;
};
