import React, { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '@/hooks/auth';

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn, isLoading } = useAuth();
  const location = useLocation();

  if (!isLoggedIn && !isLoading) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
