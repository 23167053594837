import instance from "@store/middleware/api";

import { IError } from "@models/inner-models";
import { TFilters } from "@models/models";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { TComposersResponse } from "./types";

enum EAComposers {
  GetComposerByName = "GET_COMPOSER_BY_NAME",
}

const BASE_URL = "/composers";

export const searchComposer = createAsyncThunk(EAComposers.GetComposerByName, async (filter: TFilters, thunkAPI) => {
  try {
    const resp = await instance.get<TComposersResponse[]>(BASE_URL + "/search-by-name", { params: filter });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});
