import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPageCount } from "@utils/handleResponseHeaders";

import { IError } from "@/models/inner-models";

import { ECatalogue } from "../constants";
import instance from "../middleware/api";

const catalogue = createAsyncThunk(ECatalogue.getCatalogue, async (data: any, thunkAPI) => {
  try {
    const resp = await instance.get(`/catalog`, {
      params: data,
    });

    return { data: resp.data, pageCount: getPageCount(resp.headers["content-range"], data.limit) };
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const catalogueFilters = createAsyncThunk(ECatalogue.getFilters, async (_, thunkAPI) => {
  try {
    const resp = await instance.get(`/catalog/filters`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export { catalogue, catalogueFilters };
