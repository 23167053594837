import React, { createContext, FC } from 'react';

import GradientDialog from '@/components/Dialog';
import FirstStep from '@/components/RecoveryDialog/components/FirstStep';
import SecondStep from '@/components/RecoveryDialog/components/SecondStep';
import ThirdStep from '@/components/RecoveryDialog/components/ThirdStep';
import { ERecovery } from '@/layouts/SecondRootLayout';

interface IRecoveryDialog {
  open: boolean;
  handleClose: () => void;
  step: ERecovery;
  handleNextStep: () => void;
  resetToken: string | null;
}

interface IHandleRecovery {
  handleNextStep: () => void;
  resetToken: string | null;
}

export const HandleNextContext = createContext<IHandleRecovery>({
  handleNextStep: () => {},
  resetToken: null,
});

const renderDialogContent = (
  step: number,
  handleNextStep: () => void,
  handleClose: () => void,
  resetToken: string | null
) => {
  const currentStep = {
    handleNextStep: step === ERecovery.FirstStep ? handleNextStep : handleClose,
    resetToken,
  };

  switch (step) {
    case ERecovery.FirstStep:
      return (
        <HandleNextContext.Provider value={currentStep}>
          <FirstStep />
        </HandleNextContext.Provider>
      );
    case ERecovery.SecondStep:
      return <SecondStep />;
    case ERecovery.ThirdStep:
      return (
        <HandleNextContext.Provider value={currentStep}>
          <ThirdStep />
        </HandleNextContext.Provider>
      );
    default:
      return null;
  }
};

const RecoveryDialog: FC<IRecoveryDialog> = ({
  handleClose,
  open,
  step,
  handleNextStep,
  resetToken,
}) => {
  return (
    <GradientDialog open={open} handleClose={handleClose}>
      {renderDialogContent(step, handleNextStep, handleClose, resetToken)}
    </GradientDialog>
  );
};

export default RecoveryDialog;
