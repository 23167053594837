import { TextField } from "@mui/material";

import styled from "styled-components";

export const TextFieldLib = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      border-radius: 4px;
      border: none;
      background-color: #3C2D23;
      padding-right: 0;

      transition: background-color .3s ease-in-out;

      &::placeholder {
        color: ${({ theme }) => theme.palette.textColor.subtitle};
      }

      &.Mui-disabled {
        background-color: rgba(99, 68, 45, 0.4);
      }
    }
  }
`;
