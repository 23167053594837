import { FC, PropsWithChildren } from "react";

import { AccordionDetails, AccordionSummary, Stack } from "@mui/material";

import { ArrowDownIcon } from "@assets/svg";

import * as Styled from "./styles";

type TFilterSelection = {
  title: string;
  defaultExpanded?: boolean;
} & PropsWithChildren;

export const FilterSelection: FC<TFilterSelection> = ({ title, children, defaultExpanded }) => {
  return (
    <Styled.Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ArrowDownIcon />}>
        <Styled.AccordionTitle>{title.toUpperCase()}</Styled.AccordionTitle>
      </AccordionSummary>

      <AccordionDetails>
        <Stack>{children}</Stack>
      </AccordionDetails>
    </Styled.Accordion>
  );
};
