import { ITags } from "@models/models";
import { lengthOfId } from "@utils/constants";
import { formatBpmSlider } from "@utils/formatBpmSlider";
import { formatTimeSlider } from "@utils/formatTimeSlider";

import { TFilters } from "../types";
import { FiltersType } from "./constants";

const MIN = "min";

const hasUndefinedKey = (obj: Record<string, unknown>, key: string) => {
  return obj.hasOwnProperty(key) && obj[key] === undefined;
};

const formatFilters = (values: TFilters) =>
  Object.keys(values).flatMap((key) => {
    if (key === FiltersType.LENGTH) {
      return !hasUndefinedKey(values[key], MIN) ? formatTimeSlider(Object.values(values[key])) : undefined;
    }
    if (key === FiltersType.BPM) {
      return !hasUndefinedKey(values[key], MIN) ? formatBpmSlider(Object.values(values[key])) : undefined;
    }
    if (key === FiltersType.IS_FILTERS_OPEN || key === FiltersType.PAGE_NUMBER || key === FiltersType.IS_ALBUMS_TAB) {
      return;
    }
    return values[key].length ? values[key] : undefined;
  });

export const adaptFiltersForDisplay = (values: TFilters, tags: ITags[]) => {
  return formatFilters(values)
    .map((el) => {
      if (!el) return;
      if (el.length === lengthOfId) {
        let formattedValue: string = "";

        tags
          .filter(({ _id }) => el === _id)
          .forEach(({ value }) => {
            formattedValue = value;
          });
        return formattedValue;
      }

      return el;
    })
    .filter((el) => el);
};
