import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Logout } from "@mui/icons-material";
import { Divider, IconButton, ListItemIcon, MenuItem, MenuList, Stack, Typography } from "@mui/material";

import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import { popover } from "@layouts/components/Popover";
import { ERoutes } from "@utils/constants";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { FavoritesIcon, QualificationTestIcon, SettingsIcon } from "@/assets/svg";
import { useAuth, useLogOut } from "@/hooks/auth";
import { useIsDesktop } from "@/hooks/layout";
import { ProfileAvatar } from "@/pages/Profile/components";
import { Button as GradientButton, handleCloseOnKeyDown, Menu } from "@/ui-kit";

const AccountMenu = () => {
  const { t } = useTranslation("navigation");

  const { myData } = useAuth();

  const isDesktop = useIsDesktop();
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);

  const popupState = usePopupState({ variant: "popper", popupId: "account-menu-popover" });

  const { logoutUser, resetUser } = useLogOut();

  const navigate = useNavigate();

  const handleNavigate = (route: ERoutes) => {
    popupState.close();
    setTimeout(() => {
      navigate(route);
    }, 500);
  };

  return (
    <>
      <IconButton size="small" sx={{ padding: 0, justifyContent: "space-around" }} {...bindTrigger(popupState)}>
        <ProfileAvatar
          sx={{
            cursor: "pointer !important",
            width: `${isDesktop ? "40px" : "32px"} !important`,
            height: `${isDesktop ? "40px" : "32px"} !important`,
            fontSize: `${isDesktop ? "1.5rem" : "1.25rem"} !important`,
            "&:hover": {
              borderColor: "#ffdca8",
              backgroundColor: "#5C412A",
            },
          }}
          alt={myData?.firstName.toUpperCase()}
          src="#"
        />
      </IconButton>
      <Menu popupState={popupState} offset={[0, 27]} paperProps={popover} placement="bottom-end">
        <MenuList autoFocus={popupState.isOpen} onKeyDown={(event) => handleCloseOnKeyDown(event, popupState)}>
          {!isDesktop && (
            <MenuItem
              sx={{
                color: "textColor.common",
                minHeight: "32px !important",
                marginLeft: "14px",
                marginRight: "14px",
                paddingLeft: "8px",
                paddingRight: "8px",
                "&:hover": {
                  color: "textColor.header",
                  borderRadius: "6px",
                  backgroundColor: "#5C412A",
                },
              }}
              onClick={() => handleNavigate(ERoutes.Favorites)}
            >
              <ListItemIcon>
                <FavoritesIcon />
              </ListItemIcon>
              {t("favorites")}
            </MenuItem>
          )}
          <Typography
            sx={{
              textTransform: "capitalize",
              fontFamily: "Raleway",
              marginTop: "14px",
              marginLeft: "16px",
              fontSize: "14px",
              marginBottom: "6px",
              fontWeight: "500",
              lineHeight: "14px",
              letterSpacing: "0.01em",
              textAlign: "left",
              color: "textColor.subtitle",
            }}
          >
            {myData?.firstName}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Raleway",
              marginLeft: "16px",
              marginRight: "16px",
              fontSize: "12px",
              fontWeight: "300",
              lineHeight: "12px",
              letterSpacing: "0.01em",
              textAlign: "left",
              color: "textColor.subtitle",
            }}
          >
            {myData?.email}
          </Typography>
          <Stack paddingTop="16px" marginX="16px">
            <GradientButton
              isOutlined
              isFullWidth
              onClick={() => {
                popupState.close();
                setOpenQuestionFlow(true);
              }}
            >
              <Stack direction="row" spacing="8px" alignItems="center" justifyContent="center">
                <QualificationTestIcon />
                <div>{t("freeUseQualificationTest")}</div>
              </Stack>
            </GradientButton>
          </Stack>

          <Divider
            sx={{
              borderColor: "textColor.subtitle",
              width: "288px",
              marginLeft: "16px",
              marginBottom: "8px",
              marginTop: "16px",
            }}
          />

          <MenuItem
            sx={{
              minHeight: "32px",
              marginLeft: "16px",
              marginRight: "16px",
              justifyContent: "start",
              paddingLeft: "4px",
              paddingRight: "0",
              "&:hover": {
                color: "textColor.header",
                borderRadius: "6px",
                backgroundColor: "#5C412A",
              },
            }}
            onClick={() => handleNavigate(ERoutes.Deals)}
          >
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.01em",
                textAlign: "left",
                color: "textColor.highlighted",
              }}
            >
              {t(`bestDeals`)}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{
              minHeight: "32px",
              marginLeft: "16px",
              marginRight: "16px",
              justifyContent: "start",
              paddingLeft: "4px",
              paddingRight: "0",
              "&:hover": {
                color: "textColor.header",
                borderRadius: "6px",
                backgroundColor: "#5C412A",
              },
            }}
            onClick={() => handleNavigate(ERoutes.Home)}
          >
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.01em",
                textAlign: "left",
                color: "textColor.common",
              }}
            >
              {t(`musicCreatorProgram`)}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{
              minHeight: "32px",
              marginLeft: "16px",
              marginRight: "16px",
              justifyContent: "start",
              paddingLeft: "4px",
              paddingRight: "0",
              "&:hover": {
                color: "textColor.header",
                borderRadius: "6px",
                backgroundColor: "#5C412A",
              },
            }}
            onClick={() => handleNavigate(ERoutes.DownloadInstruction)}
          >
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.01em",
                textAlign: "left",
                color: "textColor.common",
              }}
            >
              {t(`downloadInstructions`)}
            </Typography>
          </MenuItem>

          <Divider
            sx={{
              borderColor: "textColor.subtitle",
              width: "158px",
              marginLeft: "16px",
              marginBottom: "8px",
              marginTop: "16px",
            }}
          />

          <MenuItem
            sx={{
              minHeight: "32px",
              color: "textColor.common",
              marginLeft: "16px",
              marginRight: "16px",
              paddingLeft: "8px",
              paddingRight: "8px",
              "&:hover": {
                color: "textColor.header",
                borderRadius: "6px",
                backgroundColor: "#5C412A",
              },
              "&:hover svg": {
                strokeWidth: "0.01rem",
                stroke: "#FBF4EC !important",
              },
            }}
            onClick={() => handleNavigate(ERoutes.Profile)}
          >
            <ListItemIcon sx={{ width: "28px", minWidth: "0 !important" }}>
              <SettingsIcon />
            </ListItemIcon>
            {t("settings")}
          </MenuItem>

          <MenuItem
            sx={{
              minHeight: "32px !important",
              color: "textColor.common",
              marginLeft: "16px",
              marginRight: "16px",
              paddingLeft: "8px",
              paddingRight: "8px",
              "&:hover": {
                color: "textColor.header",
                borderRadius: "6px",
                backgroundColor: "#5C412A",
              },
              "&:hover svg": {
                fill: "#FBF4EC !important",
              },
            }}
            onClick={() => {
              logoutUser();
              resetUser();
            }}
          >
            <ListItemIcon sx={{ width: "28px", minWidth: "0 !important" }}>
              <Logout
                sx={{
                  width: "20px",
                  color: "textColor.common",
                }}
                fontSize="small"
              />
            </ListItemIcon>
            {t("logOut")}
          </MenuItem>
        </MenuList>
      </Menu>
      <QuestionFlowDialog
        open={openQuestionFlow}
        onClose={() => {
          setOpenQuestionFlow(false);
        }}
      />
    </>
  );
};

export default AccountMenu;
