import React, { FC, ReactNode, useState } from 'react';

import { Tooltip } from '@mui/material';

import { TWO_SECONDS } from '@/components/CustomTooltip/constants';
import { useIsDesktop } from '@/hooks/layout';

interface ICustomTooltip {
  children: ReactNode;
  title: NonNullable<ReactNode>;
  disableHoverListener: boolean;
}

const CustomTooltip: FC<ICustomTooltip> = ({
  children,
  disableHoverListener,
  title,
}) => {
  const isDesktop = useIsDesktop();

  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen((prevState) => !prevState);

    setTimeout(() => setOpen((prevState) => !prevState), TWO_SECONDS);
  };

  return isDesktop ? (
    <Tooltip
      disableHoverListener={disableHoverListener}
      placement="top"
      title={title}
    >
      <span>{children}</span>
    </Tooltip>
  ) : (
    <Tooltip
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="top"
      open={open}
      title={title}
    >
      <span onClick={handleTooltipOpen}>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
