import instance from "@store/middleware/api";

import { IError } from "@models/inner-models";
import { TFilters } from "@models/models";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { TAlbumResponse } from "./types";

enum EAlbums {
  GetAlbum = "GET_ALBUM",
  GetAlbumByName = "GET_ALBUM_BY_NAME",
}

const BASE_URL = "/albums";

export const album = createAsyncThunk(EAlbums.GetAlbum, async (albumId: string, thunkAPI) => {
  try {
    const resp = await instance.get<TAlbumResponse>(`${BASE_URL}` + `/${albumId}`);

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export const searchAlbum = createAsyncThunk(EAlbums.GetAlbumByName, async (filter: TFilters, thunkAPI) => {
  try {
    const resp = await instance.get<TAlbumResponse[]>(BASE_URL + "/search-by-name", { params: filter });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});
