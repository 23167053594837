import { FC, ReactNode } from "react";

import { SxProps } from "@mui/material";

import * as Styled from "./styles";

type TButtonType = "submit" | "reset" | "button";

type TGradientButton = {
  children: ReactNode;
  isOutlined?: boolean;
  isDisabled?: boolean;
  type?: TButtonType;
  onClick?: () => void;
  sx?: SxProps;
  isFullWidth?: boolean;
  isBigButton?: boolean;
  isIconButtonLike?: boolean;
  width?: number;
};

export const Button: FC<TGradientButton> = ({
  children,
  isOutlined = false,
  isDisabled = false,
  type,
  onClick,
  sx,
  isFullWidth = false,
  isBigButton = false,
  isIconButtonLike = false,
  width,
}) => {
  return (
    <Styled.Button
      $isBigButton={isBigButton}
      $isOutlined={isOutlined}
      $isFullWidth={isFullWidth}
      $isIconButtonLike={isIconButtonLike}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      width={width && width}
      sx={sx}
    >
      {children}
    </Styled.Button>
  );
};
