import { FiltersType } from "@hooks/useFiltersQuery/utils/constants";

import { ERoutes } from "@utils/constants";
import queryString from "query-string";
import { ArrayParam, encodeQueryParams, ObjectParam } from "use-query-params";

export const navigateToCatalogWithQuery = (query: string, queryType: FiltersType) => {
  const isRange = queryType === FiltersType.LENGTH || queryType === FiltersType.BPM;
  const searchParams = isRange
    ? {
        min: "0",
        max: query,
      }
    : [query];
  const encodedQuery = encodeQueryParams(
    { [queryType]: isRange ? ObjectParam : ArrayParam },
    { [queryType]: searchParams },
  );
  return {
    pathname: ERoutes.Catalog,
    search: queryString.stringify(encodedQuery),
  };
};
