import { Typography } from "@mui/material";

import styled from "styled-components";

export const IconButtonTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  letter-spacing: 0.01rem;
  font-weight: 400;
  text-transform: uppercase;
`;
