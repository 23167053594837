import { Link, Stack, Typography } from '@mui/material';

import styled from 'styled-components';

import { isSmallDesktop } from '@/hooks/layout';

export const InfoStackWrapper = styled(Stack)<{ isSemiTransparent?: boolean }>`
  width: 100%;
  height: 100%;

  border-radius: 8px;

  padding: 16px;

  z-index: 1;

  background-color: ${({ isSemiTransparent }) =>
    isSemiTransparent ? 'rgba(99, 68, 45, 0.40)' : 'rgba(99, 68, 45, 0.70)'};
`;

export const InfoText = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  text-align: center;
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;

  ${isSmallDesktop} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
export const InfoLink = styled(Link)`
  color: ${({ theme }) => theme.palette.textColor.highlighted};

  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  cursor: pointer;

  ${isSmallDesktop} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
