import { Typography } from "@mui/material";

import { isSmallDesktop } from "@hooks/layout";

import styled from "styled-components";

export const Title = styled(Typography)`
  font-family: "Kaisei Opti";
  font-size: 1.125rem;
  color: ${({ theme }) => theme.palette.textColor.header};

  ${isSmallDesktop} {
    font-size: 1.5rem;
  }
`;

export const AlbumCover = styled.img`
  height: 200px;
  width: auto;
  
  border-radius: 8px;
`;
