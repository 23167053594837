import { FC } from "react";

import { Grid, SwitchProps } from "@mui/material";

import { TFunction } from "i18next";

import * as Styled from "./styles";

type TSwitchButton = SwitchProps & { t: TFunction };

export const SwitchButton: FC<TSwitchButton> = (props) => {
  const { t, checked } = props;
  return (
    <Grid component="label" container alignItems="center" spacing="4px">
      <Grid item>
        <Styled.SwitchLabel $isChecked={!checked}>{t("yes")}</Styled.SwitchLabel>
      </Grid>
      <Grid item>
        <Styled.SwitchLib disableRipple checked={checked} {...props} />
      </Grid>
      <Grid item>
        <Styled.SwitchLabel $isChecked={checked}>{t("no")}</Styled.SwitchLabel>
      </Grid>
    </Grid>
  );
};
