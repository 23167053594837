export const formatTimeSlider = (value: number | string[] | number[]) => {
  const formatSingleTime = (time: number) => time.toString().padStart(2, "0");

  if (typeof value === "number") {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${formatSingleTime(minutes)}:${formatSingleTime(seconds)}`;
  }

  if (Array.isArray(value) && value.length === 2) {
    const [startString, endString] = value;
    const start = Number(startString);
    const end = Number(endString);

    const startMinutes = Math.floor(start / 60);
    const startSeconds = start % 60;
    const endMinutes = Math.floor(end / 60);
    const endSeconds = end % 60;
    return `${formatSingleTime(startMinutes)}:${formatSingleTime(startSeconds)} - ${formatSingleTime(
      endMinutes,
    )}:${formatSingleTime(endSeconds)}`;
  }

  return "";
};
