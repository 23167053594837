import { IUser } from "@models/models";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  IError,
  ILogin,
  ILogout,
  IRegister,
  IResetPassword,
  IUpdateEmail,
  IUpdatePassword,
} from "@/models/inner-models";

import { EUser } from "../constants";
import instance from "../middleware/api";

const my = createAsyncThunk<IUser>(EUser.getMy, async (_, thunkAPI) => {
  try {
    const resp = await instance.get("/users/my");

    if (!resp?.data) {
      return thunkAPI.rejectWithValue({ error: resp.data.result.message });
    }

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const updateMy = createAsyncThunk(EUser.patchMy, async (data: Record<string, unknown>, thunkAPI) => {
  try {
    const resp = await instance.patch("/users/my", data);

    if (!resp?.data) {
      return thunkAPI.rejectWithValue({ error: resp.data.result.message });
    }

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const deleteMy = createAsyncThunk(EUser.deleteMy, async (_, thunkAPI) => {
  try {
    const resp = await instance.delete("/users/my");

    if (!resp?.data) {
      return thunkAPI.rejectWithValue({ error: resp.data.result.message });
    }

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const sendVerificationCode = createAsyncThunk(EUser.sendVerificationCode, async (_, thunkAPI) => {
  try {
    const resp = await instance.post("/users/my/send-verification-code");

    if (!resp?.data) {
      return thunkAPI.rejectWithValue({ error: resp.data.result.message });
    }

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const verifyCode = createAsyncThunk(EUser.verifyCode, async (data: Record<string, unknown>, thunkAPI) => {
  try {
    const resp = await instance.post("/users/my/verify-code", data);

    if (!resp?.data) {
      return thunkAPI.rejectWithValue({ error: resp.data.result.message });
    }

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const refreshToken = createAsyncThunk(
  EUser.refreshToken,
  async (data: { headers: { "Refresh-Token": string } }, thunkAPI) => {
    try {
      const resp = await instance.post("/auth/refresh-session", undefined, data);

      return resp?.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  },
);
const login = createAsyncThunk(EUser.loginUser, async (loginInfo: ILogin, thunkAPI) => {
  try {
    const resp = await instance.post("/auth/login", loginInfo);

    window.location.href = "/catalog";

    return resp?.data;
  } catch (err) {
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const register = createAsyncThunk(EUser.registerUser, async (registerInfo: IRegister | ILogin, thunkAPI) => {
  try {
    const resp = await instance.post("/auth/register", registerInfo);

    return resp?.data;
  } catch (err) {
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const logout = createAsyncThunk(EUser.logOutUser, async (logoutInfo: ILogout, thunkAPI) => {
  try {
    const resp = await instance.post("/auth/logout", logoutInfo);

    return resp?.data;
  } catch (err) {
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const updatePassword = createAsyncThunk(EUser.updatePassword, async (data: IUpdatePassword, thunkAPI) => {
  try {
    const resp = await instance.post("/users/my/change-password", data);

    return resp?.data;
  } catch (err) {
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const updateEmail = createAsyncThunk(EUser.updateEmail, async (data: IUpdateEmail, thunkAPI) => {
  try {
    const resp = await instance.post("/users/my/change-email", data);

    return resp?.data;
  } catch (err) {
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

const sendVerificationLink = createAsyncThunk(
  EUser.sendVerificationLink,
  async (email: Record<string, unknown>, thunkAPI) => {
    try {
      const resp = await instance.post("/auth/send-verification-link-password", email);

      return resp?.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const resetPassword = createAsyncThunk(EUser.resetPassword, async (newPassword: IResetPassword, thunkAPI) => {
  try {
    const resp = await instance.patch("/auth/reset-password", newPassword);

    return resp?.data;
  } catch (err) {
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export {
  login,
  register,
  logout,
  refreshToken,
  my,
  updateMy,
  sendVerificationCode,
  verifyCode,
  sendVerificationLink,
  updatePassword,
  updateEmail,
  resetPassword,
  deleteMy,
};
