import { Accordion as LibAccordion, Typography } from "@mui/material";

import styled from "styled-components";

export const Accordion = styled(LibAccordion)`
  && {
    border: none;
    background: rgba(75, 55, 39, 0.8);

    .MuiAccordionSummary-root {
      &.Mui-expanded {
        min-height: 48px;
      }

      .MuiAccordionSummary-content {
        &.Mui-expanded {
          margin: 12px 0;
        }
      }
    }
  }
`;

export const AccordionTitle = styled(Typography)`
  font-family: "Kaisei Opti", sans-serif;
  font-size: 0.75rem;

  color: #e3bda0;
`;
