import React, { FC, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Stack } from "@mui/material";

import { Button as GradientButton, Link } from "@ui/index";
import { Text } from "@ui/typography";

import { COPY_TEXT, TEXT_FONT_WEIGHT } from "@pages/FreeUseTerms/constants";

import { useAuth } from "@hooks/auth";

import BorderStack from "@components/BorderStack";
import { VerticalConnector } from "@components/components";
import { QuestionFlowDialog } from "@components/QuestionFlowDialog";
import RequireLicense from "@components/RequireLicense";
import SocialsCarousel from "@components/SocialsCarousel";
import { HandleOpenDialog } from "@layouts/SecondRootLayout";
import { ERoutes } from "@utils/constants";
import { scrollToAnchor } from "@utils/scrollToAnchor";

import { CopyIcon } from "@/assets/svg";
import { InfoLink } from "@/components/InfoStack/components";
import LinedTitle from "@/components/LinedTitle";
import RestrictionItem from "@/components/RestrictionItem";
import { useIsDesktop } from "@/hooks/layout";

type TFreeUseTermsComponent = {
  isPage?: boolean;
};

const FreeUseTermsComponent: FC<TFreeUseTermsComponent> = ({ isPage = true }) => {
  const { t } = useTranslation("terms");

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [openQuestionFlow, setOpenQuestionFlow] = useState<boolean>(false);
  const navigate = useNavigate();

  const { isLoggedIn } = useAuth();

  const isDesktop = useIsDesktop();

  const handleOpenLoginDialog = useContext(HandleOpenDialog);

  useEffect(() => {
    if (isCopied) {
      const countdownInterval = setInterval(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearInterval(countdownInterval);
    }
  }, [isCopied]);

  return (
    <>
      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack withoutBorder={!isPage}>
          <Stack
            width={isDesktop ? "75%" : "100%"}
            spacing={isDesktop ? "40px" : "24px"}
            marginBottom={!isDesktop ? "-16px" : undefined}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("howToUse.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans
                  t={t}
                  i18nKey="howToUse.description"
                  components={[
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        scrollToAnchor("creditRequirement");
                      }}
                    />,
                    <InfoLink
                      underline="hover"
                      onClick={() => {
                        scrollToAnchor("usesThatRequireLicense");
                      }}
                    />,
                  ]}
                />
              </Text>
            </Stack>

            <SocialsCarousel />
          </Stack>
        </BorderStack>
      </Stack>

      {isPage && <VerticalConnector />}

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack withoutBorder={!isPage}>
          <Stack width={isDesktop ? "75%" : "100%"} spacing={isDesktop ? "40px" : "24px"}>
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("checkLicenseEligibility.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="checkLicenseEligibility.description" />
              </Text>
            </Stack>

            {isPage && (
              <Stack alignItems="center" justifyContent="center">
                <GradientButton isFullWidth={!isDesktop} width={227} onClick={() => setOpenQuestionFlow(true)}>
                  {t("checkLicenseEligibility.button")}
                </GradientButton>
              </Stack>
            )}
          </Stack>
        </BorderStack>
      </Stack>

      {isPage && <VerticalConnector />}

      <Stack id="creditRequirement" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack withoutBorder={!isPage}>
          <Stack width={isDesktop ? "75%" : "100%"} spacing={isDesktop ? "40px" : "24px"}>
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("download.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="download.description" />
              </Text>
            </Stack>

            {isPage && (
              <Stack alignItems="center" justifyContent="center">
                <GradientButton
                  isFullWidth={!isDesktop}
                  width={227}
                  isDisabled={isLoggedIn}
                  onClick={() => handleOpenLoginDialog(false)}
                >
                  {t("download.button")}
                </GradientButton>
              </Stack>
            )}
          </Stack>
        </BorderStack>
      </Stack>

      {isPage && <VerticalConnector />}

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack withoutBorder={!isPage}>
          <Stack
            width={isDesktop ? "75%" : "100%"}
            spacing={isDesktop ? "40px" : "24px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <LinedTitle>
                <Text
                  size={isDesktop ? 1.5 : 1.125}
                  isHeading
                  isFullWidth
                  textAlign="center"
                  fontWeight={TEXT_FONT_WEIGHT}
                >
                  {t("credit.mainText")}
                </Text>
              </LinedTitle>

              <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                <Trans t={t} i18nKey="credit.description" />
              </Text>
            </Stack>

            <Stack spacing={isDesktop ? "16px" : "8px"} width="100%" alignItems="center" justifyContent="center">
              <Stack width={isDesktop ? "55%" : "100%"}>
                <BorderStack withoutBorder={!isPage}>
                  <Stack
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    spacing={isDesktop ? "24px" : "16px"}
                    zIndex={5}
                  >
                    <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                      <Trans t={t} i18nKey="credit.creditText" components={[<span style={{ color: "#FBF4EC" }} />]} />
                    </Text>

                    <GradientButton
                      isFullWidth={!isDesktop}
                      isOutlined
                      width={234}
                      onClick={() => {
                        setIsCopied(true);
                        navigator.clipboard.writeText(COPY_TEXT);
                      }}
                    >
                      <Stack direction="row" spacing="8px" alignItems="center" justifyContent="center">
                        <CopyIcon />
                        <Box>{t(isCopied ? "credit.buttonClicked" : "credit.button")}</Box>
                      </Stack>
                    </GradientButton>
                  </Stack>
                </BorderStack>
              </Stack>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      {isPage && <VerticalConnector />}

      <Stack id="usesThatRequireLicense" width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack withoutBorder={!isPage}>
          <Stack
            width="100%"
            spacing={isDesktop ? "40px" : "24px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <LinedTitle>
              <Text
                size={isDesktop ? 1.5 : 1.125}
                isHeading
                isFullWidth
                textAlign="center"
                fontWeight={TEXT_FONT_WEIGHT}
              >
                {t("licenseRequire.mainText")}
              </Text>
            </LinedTitle>

            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <RequireLicense>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="licenseRequire.first" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RequireLicense>
              <RequireLicense>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="licenseRequire.second" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RequireLicense>
              <RequireLicense>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="licenseRequire.third" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RequireLicense>
            </Stack>
            <Stack alignItems="center" justifyContent="center">
              <GradientButton
                isFullWidth={!isDesktop}
                width={169}
                onClick={() => navigate(ERoutes.CatalogLandingLicensing)}
              >
                {t("licenseRequire.button")}
              </GradientButton>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      {isPage && <VerticalConnector />}

      <Stack width="100%" spacing={isDesktop ? "40px" : "24px"}>
        <BorderStack withoutBorder={!isPage}>
          <Stack
            width="100%"
            spacing={isDesktop ? "40px" : "24px"}
            alignItems="center"
            justifyContent="center"
            zIndex={5}
          >
            <LinedTitle>
              <Text
                size={isDesktop ? 1.5 : 1.125}
                isHeading
                isFullWidth
                textAlign="center"
                fontWeight={TEXT_FONT_WEIGHT}
              >
                {t("neverAllowed.mainText")}
              </Text>
            </LinedTitle>

            <Stack width="100%" spacing={isDesktop ? "24px" : "16px"}>
              <RestrictionItem>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="neverAllowed.first" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RestrictionItem>
              <RestrictionItem>
                <Text size={isDesktop ? 1 : 0.875} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
                  <Trans t={t} i18nKey="neverAllowed.second" components={[<InfoLink underline="hover" />]} />
                </Text>
              </RestrictionItem>
            </Stack>
          </Stack>
        </BorderStack>
      </Stack>

      {isDesktop && (
        <Stack textAlign="center" marginY={isDesktop ? "80px" : "40px"}>
          (
          <Text size={isDesktop ? 1.3 : 1} isFullWidth textAlign="center" fontWeight={TEXT_FONT_WEIGHT}>
            <Trans
              t={t}
              i18nKey="questions"
              components={[
                <Link
                  component="button"
                  size="inherit"
                  onClick={() => {
                    scrollToAnchor("musicFAQ");
                  }}
                />,
                <Link size="inherit" href={ERoutes.Contact} />,
              ]}
            />
          </Text>
        </Stack>
      )}
      <QuestionFlowDialog open={openQuestionFlow} onClose={() => setOpenQuestionFlow(false)} />
    </>
  );
};

export default FreeUseTermsComponent;
