import { FC } from "react";

import { SongPauseButtonIcon, SongPlayButtonIcon } from "@assets/svg";

import * as Styled from "./styles";

type TPlayButton = {
  isPlaying: boolean;
  onClick: () => void;
  isBigSize?: boolean;
  isSoundExample?: boolean;
  isMobileBottomPlayer?: boolean;
};

export const PlayButton: FC<TPlayButton> = ({
  isPlaying,
  onClick,
  isBigSize = false,
  isSoundExample = false,
  isMobileBottomPlayer = false,
}) => {
  return (
    <Styled.Button
      onClick={onClick}
      $isBigSize={isBigSize}
      $isSoundExample={isSoundExample}
      $isMobileBottomPlayer={isMobileBottomPlayer}
    >
      {isPlaying ? <SongPauseButtonIcon height="100%" /> : <SongPlayButtonIcon height="100%" />}
    </Styled.Button>
  );
};
