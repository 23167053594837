import { IconButton } from "@mui/material";

import styled from "styled-components";

export const Button = styled(IconButton)`
  &.MuiIconButton-root {
    p {
      transition: color 0.3s ease-in-out;
    }

    path {
      stroke: #ffdca8;
      transition: stroke 0.3s ease-in-out;
    }

    &:hover {
      p {
        color: #ffe7c4;
      }

      path {
        stroke: #ffe7c4;
      }

      background-color: transparent;
    }
  }
`;
