import { FC } from "react";

import { PaginationProps } from "@mui/material";

import * as Styled from "./styles";

type TPagination = PaginationProps;

export const Pagination: FC<TPagination> = (props) => {
  return <Styled.Wrapper {...props} shape="rounded" />;
};
