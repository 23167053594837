import { createSlice } from "@reduxjs/toolkit";

import { bottomTrack, TAlbum, track, trackAlbum, TTrack } from "../../actions/track";

export type TrackState = {
  track?: TTrack;
  albumTracks?: TAlbum;
  bottomTrack?: TTrack;
  isTrackLoading: boolean;
  isAlbumLoading: boolean;
  isBottomLoading: boolean;
};

const initialState: TrackState = {
  isTrackLoading: true,
  isAlbumLoading: true,
  isBottomLoading: true,
};

const { reducer } = createSlice({
  name: "tracks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(track.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.track = payload;
      state.isTrackLoading = false;
    });
    builder.addCase(trackAlbum.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.albumTracks = payload;
      state.isAlbumLoading = false;
    });
    builder.addCase(bottomTrack.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.bottomTrack = payload;
      state.isBottomLoading = false;
    });
    builder.addCase(track.pending, (state) => {
      state.isTrackLoading = true;
    });
    builder.addCase(trackAlbum.pending, (state) => {
      state.isAlbumLoading = true;
    });
    builder.addCase(bottomTrack.pending, (state) => {
      state.isBottomLoading = true;
    });
  },
});

export default reducer;
