import React, { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@hooks/auth";
import { useIsDesktop } from "@hooks/layout";
import { useCountDownPlayback } from "@hooks/useCountDownPlayback";
import { FiltersType, navigateToCatalogWithQuery } from "@hooks/useFiltersQuery/utils";
import { useWavesurfer } from "@hooks/wafeform";

import { createFavorites, deleteFavorites } from "@store/actions/favorites";
import { bottomTrack } from "@store/actions/track";
import { useAppDispatchUnwrap, useAppSelector } from "@store/hooks";
import { getFavoritesState } from "@store/selectors/getFavorites";
import { getBottomTrackSelector } from "@store/selectors/tracks";

import { AvailableFor, TPlayerProps } from "@components/BottomPlayer/components/types";
import { PlayerContext } from "@layouts/PlayerLayout/utils";
import { IFavorites } from "@models/models";
import { ERoutes } from "@utils/constants";
import { makePath, makePathForTrack } from "@utils/makePath";

import NewDownloadModal, { EDownloadModal } from "../../../DownloadModal";
import * as Styled from "../../styles";
import {
  EWaveSurferPlayer,
  getIsMuted,
  getVolume,
  MAX_VOLUME,
  MUTE_VOLUME,
  setMuted,
  setVolume,
  waveSurferPlayerReducer,
} from "../../utils";
import { DesktopLayout } from "../DesktopLayout/DesktopLayout";
import { MobileLayout } from "../MobileLayout";

export const Player = () => {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  const { setPlay, data, setMedia, setOpenLoginDialog } = useContext(PlayerContext);
  const { isUserCanPlay } = useCountDownPlayback();

  const { id, isPlaying } = data;

  const dispatch = useAppDispatchUnwrap();

  const containerRef = useRef<HTMLDivElement>(null);

  const favoritesState = useAppSelector(getFavoritesState);
  const { bottomTrack: bottomTrackData } = useAppSelector(getBottomTrackSelector);

  const getAvailableFor = useMemo(
    () => bottomTrackData?.tags?.filter(({ tagTypeId }) => tagTypeId.name === AvailableFor.AVAILABLE_FOR),
    [id, bottomTrackData],
  );

  const isAvailableFor = useCallback(
    (availableFor: AvailableFor) => {
      if (!getAvailableFor) return false;
      return getAvailableFor.some(({ value }) => value === availableFor);
    },
    [id, getAvailableFor],
  );

  const getFavoritesTracks = (favorites: IFavorites[]) => {
    return new Set(favorites.map((obj) => obj.trackId));
  };

  const favoritesTracks = getFavoritesTracks(favoritesState);

  const wavesurfer = useWavesurfer({
    containerRef,
    url: bottomTrackData?.streamingFileUrl || bottomTrackData?.lowQualityFileUrl,
    peaks: bottomTrackData?.peaks,
  });

  const [state, dispatchReducer] = useReducer(waveSurferPlayerReducer, {
    isFavorite: false,
    isLoading: true,
    activeStep: 1,
    openModal: false,
    isPlaying: false,
    isCopyright: false,
    volume: getVolume(),
    isMuted: getIsMuted(),
  });
  const { isLoggedIn } = useAuth();

  const titleForDownloading = `${bottomTrackData?.composer ? bottomTrackData?.composer : "Paste Artist"} - ${
    bottomTrackData?.name
  }`;

  const isVolumeTouched = getVolume() !== MAX_VOLUME && !getIsMuted();

  const onPlayClick = useCallback(() => {
    if (!isUserCanPlay) return setOpenLoginDialog();
    if (!wavesurfer) return;

    wavesurfer.playPause();
  }, [wavesurfer, isUserCanPlay]);

  const getTrack = async () => {
    await dispatch(bottomTrack(id));
  };

  useEffect(() => {
    if (!id) return;
    getTrack();
  }, [id]);

  useEffect(() => {
    dispatchReducer({
      type: EWaveSurferPlayer.IsLoading,
      payload: true,
    });
  }, [bottomTrackData?.lowQualityFileUrl, bottomTrackData?.streamingFileUrl]);

  useEffect(() => {
    dispatchReducer({
      type: EWaveSurferPlayer.IsPlaying,
      payload: isPlaying,
    });
    if (isPlaying) {
      wavesurfer?.play();
    } else {
      wavesurfer?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!wavesurfer) return;

    const onReady = () => {
      dispatchReducer({ type: EWaveSurferPlayer.IsLoading, payload: false });
      if (isPlaying) wavesurfer.play();
      dispatchReducer({ type: EWaveSurferPlayer.IsPlaying, payload: wavesurfer.isPlaying() });
    };

    const onPlay = () => {
      dispatchReducer({ type: EWaveSurferPlayer.IsPlaying, payload: true });
      setMedia((prev) => {
        const newMedia = wavesurfer.getMediaElement();
        if (!prev || prev !== newMedia) {
          if (prev) {
            prev.pause();
            prev.currentTime = 0;
          }
          return newMedia;
        }
        return prev;
      });
      setPlay(true);
    };

    const onPause = () => {
      dispatchReducer({ type: EWaveSurferPlayer.IsPlaying, payload: false });
      setPlay(false);
    };

    const subscriptions = [
      wavesurfer.on("ready", onReady),
      wavesurfer.on("play", onPlay),
      wavesurfer.on("pause", onPause),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer, bottomTrackData?._id]);

  useEffect(() => {
    dispatchReducer({
      type: EWaveSurferPlayer.IsFavorite,
      payload: favoritesTracks.has(bottomTrackData?._id),
    });
  }, [favoritesState.length, bottomTrackData?._id]);

  useEffect(() => {
    if (!wavesurfer) return;

    if (!state.isMuted) {
      return wavesurfer.setVolume(state.volume);
    }

    wavesurfer.setVolume(MUTE_VOLUME);
  }, [state.isMuted, state.volume, wavesurfer]);

  useEffect(() => {
    if (!isUserCanPlay) {
      wavesurfer.pause();
      setOpenLoginDialog();
    }
    if (!wavesurfer) return;
  }, [isUserCanPlay]);

  const onVolumeChange = (_e: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
    if (Array.isArray(value)) return;

    dispatchReducer({
      type: EWaveSurferPlayer.IsMuted,
      payload: !value,
    });
    setMuted(!value);

    dispatchReducer({
      type: EWaveSurferPlayer.Volume,
      payload: value,
    });
    setVolume(value);
  };

  const handleVolumeMute = () => {
    dispatchReducer({
      type: EWaveSurferPlayer.Volume,
      payload: state.isMuted ? 1 : 0,
    });
    setVolume(state.isMuted ? 1 : 0);
    dispatchReducer({
      type: EWaveSurferPlayer.IsMuted,
      payload: !state.isMuted,
    });
    setMuted(!state.isMuted);
  };

  const handleNext = () => {
    dispatchReducer({
      type: EWaveSurferPlayer.ActiveStep,
      payload: state.activeStep + 1,
    });
  };

  const handleDownload = () => {
    if (!isLoggedIn) {
      return setOpenLoginDialog();
    }
    dispatchReducer({
      type: EWaveSurferPlayer.IsCopyright,
      payload: false,
    });
    dispatchReducer({
      type: EWaveSurferPlayer.OpenModal,
      payload: !state.openModal,
    });
    dispatchReducer({
      type: EWaveSurferPlayer.ActiveStep,
      payload: EDownloadModal.FirstStep,
    });
  };

  const handleLicensing = () => navigate(makePathForTrack(ERoutes.Licensing, bottomTrackData?._id));

  const handleNavigate = (route: ERoutes, id: string) => {
    if (state.isLoading || !bottomTrackData) return;
    if (!isLoggedIn) {
      return setOpenLoginDialog();
    }
    navigate(makePath(route, id));
  };

  const handleNavigateComposer = (composerName: string) => {
    if (state.isLoading || !bottomTrackData) return;
    navigate(navigateToCatalogWithQuery(composerName, FiltersType.COMPOSER));
  };

  const handleLike = async () => {
    if (!isLoggedIn) {
      return setOpenLoginDialog();
    }
    if (state.isFavorite) {
      await dispatch(deleteFavorites({ trackId: bottomTrackData?._id }));
    } else {
      await dispatch(createFavorites({ trackId: bottomTrackData?._id }));
    }
    dispatchReducer({
      type: EWaveSurferPlayer.IsFavorite,
      payload: !state.isFavorite,
    });
  };

  const playerProps: TPlayerProps = useMemo(
    () => ({
      bottomTrackData,
      containerRef,
      state,
      isVolumeTouched,
      isAvailableFor,
      dispatchReducer,
      handleLicensing,
      handleNavigateComposer,
      handleDownload,
      handleNavigate,
      handleVolumeMute,
      onVolumeChange,
      onPlayClick,
      handleLike,
    }),
    [
      bottomTrackData,
      containerRef,
      state,
      isVolumeTouched,
      isAvailableFor,
      dispatchReducer,
      handleLicensing,
      handleNavigateComposer,
      handleDownload,
      handleNavigate,
      handleVolumeMute,
      onVolumeChange,
      onPlayClick,
      handleLike,
    ],
  );

  return (
    <>
      <Styled.SongStack
        direction={isDesktop ? "row" : "column"}
        spacing={isDesktop ? "16px" : "8px"}
        width="100%"
        alignItems="center"
      >
        {isDesktop ? <DesktopLayout {...playerProps} /> : <MobileLayout {...playerProps} />}
      </Styled.SongStack>
      <NewDownloadModal
        open={state.openModal}
        song={bottomTrackData}
        onClose={() =>
          dispatchReducer({
            type: EWaveSurferPlayer.OpenModal,
            payload: !state.openModal,
          })
        }
        copyrightModal={state.isCopyright}
        activeStep={state.activeStep}
        handleNext={handleNext}
        composer={titleForDownloading}
        videoUrl={bottomTrackData?.videoUrl}
        cover={bottomTrackData?.coverUrl}
      />
    </>
  );
};
