import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button as GradientButton } from "@ui/index";

import { updateMy } from "@store/actions/user";
import { useAppDispatchUnwrap } from "@store/hooks";

import CustomTooltip from "@components/CustomTooltip";
import { UserMetadataForm } from "@components/UserMetadataForm";
import { CONTENT_CLASSIFICATION, METADATA_OTHER } from "@utils/constants";

const Form = () => {
  const { t } = useTranslation("signup", {
    keyPrefix: "classifyContent",
  });

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [otherField, setOtherField] = useState<string>("");

  const dispatch = useAppDispatchUnwrap();

  const handleSave = async () => {
    const otherIndex = selectedCategories.findIndex((element) => element === METADATA_OTHER);
    if (otherIndex !== -1) {
      selectedCategories[otherIndex] = otherField;
    }
    await dispatch(
      updateMy({
        categories: selectedCategories,
      }),
    );
  };

  const isButtonValid = () => {
    const isOtherInArray = selectedCategories.includes(METADATA_OTHER)
    if (isOtherInArray) {
      return !otherField;
    }

    return !selectedCategories.length
  }

  return (
    <UserMetadataForm
      t={t}
      selected={selectedCategories}
      setSelected={setSelectedCategories}
      data={CONTENT_CLASSIFICATION}
      otherField={otherField}
      setOtherField={setOtherField}
    >
      <CustomTooltip disableHoverListener={!!selectedCategories.length} title={<Trans t={t} i18nKey="toolTip" />}>
        <GradientButton onClick={handleSave} isDisabled={isButtonValid()} isFullWidth>
          {t("buttonText")}
        </GradientButton>
      </CustomTooltip>
    </UserMetadataForm>
  );
};

export default Form;
