import { lazy } from "react";

import { ERoutes } from "@utils/constants";

import AuthGuard from "@/guards/AuthGuard";
import SecondRootLayout from "@/layouts/SecondRootLayout";

const Album = lazy(() => import("@/pages/Album"));
const HomePage = lazy(() => import("@/pages/HomePage"));
const Catalog = lazy(() => import("@/pages/Catalog"));
const CatalogLanding = lazy(() => import("@/pages/CatalogLanding"));
const Conditions = lazy(() => import("@/pages/Conditions"));
const ContactAndLicensing = lazy(() => import("@/pages/ContactAndLicensing"));
const FreeUseTerms = lazy(() => import("@/pages/FreeUseTerms"));
const Imprint = lazy(() => import("@/pages/Imprint"));
const Newsletter = lazy(() => import("@/pages/Newsletter"));
const NotFound = lazy(() => import("@/pages/NotFound"));
const Privacy = lazy(() => import("@/pages/Privacy"));
const Profile = lazy(() => import("@/pages/Profile"));
const ResultsPage = lazy(() => import("@/pages/Results"));
const Favorites = lazy(() => import("@/pages/Favorites"));
const Submit = lazy(() => import("@/pages/Submit"));
const TransitPage = lazy(() => import("@/pages/TransitPage"));
const Track = lazy(() => import("@pages/Track"));
const Deals = lazy(() => import("@/pages/Deals"));
const DownloadInstruction = lazy(() => import("@/pages/DownloadInstruction"));

const privateRoutes = [
  {
    path: ERoutes.Catalog,
    children: [
      {
        path: "",
        element: (
          <SecondRootLayout>
            <Catalog />
          </SecondRootLayout>
        ),
      },
      {
        path: ":albumId",
        element: (
          <AuthGuard>
            <SecondRootLayout>
              <Album />
            </SecondRootLayout>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: ERoutes.Favorites,
    element: (
      <AuthGuard>
        <SecondRootLayout>
          <Favorites />
        </SecondRootLayout>
      </AuthGuard>
    ),
  },
  {
    path: ERoutes.Track,
    element: (
      <AuthGuard>
        <SecondRootLayout>
          <Track />
        </SecondRootLayout>
      </AuthGuard>
    ),
  },
  {
    path: ERoutes.Search,
    element: (
      <AuthGuard>
        <SecondRootLayout>
          <ResultsPage />
        </SecondRootLayout>
      </AuthGuard>
    ),
  },
  {
    path: ERoutes.Profile,
    element: (
      <AuthGuard>
        <SecondRootLayout>
          <Profile />
        </SecondRootLayout>
      </AuthGuard>
    ),
  },
];

const publicRoutes = [
  {
    path: ERoutes.ContactLicensing,
    element: (
      <SecondRootLayout>
        <ContactAndLicensing />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.FreeUseTerms,
    element: (
      <SecondRootLayout>
        <FreeUseTerms />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Conditions,
    element: (
      <SecondRootLayout>
        <Conditions />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Submit,
    element: (
      <SecondRootLayout>
        <Submit />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Newsletter,
    element: (
      <SecondRootLayout isNewsletter={false}>
        <Newsletter />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Privacy,
    element: (
      <SecondRootLayout>
        <Privacy />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Imprint,
    element: (
      <SecondRootLayout>
        <Imprint />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Home,
    element: (
      <SecondRootLayout>
        <HomePage />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.TransitPage,
    element: (
      <SecondRootLayout>
        <TransitPage />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.CatalogLanding,
    element: (
      <SecondRootLayout>
        <CatalogLanding />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.DownloadInstruction,
    element: (
      <SecondRootLayout>
        <DownloadInstruction />
      </SecondRootLayout>
    ),
  },
  {
    path: ERoutes.Deals,
    element: (
      <SecondRootLayout>
        <Deals />
      </SecondRootLayout>
    ),
  },
];
const routes = [
  {
    path: "*",
    element: (
      <SecondRootLayout>
        <NotFound />
      </SecondRootLayout>
    ),
  },

  ...privateRoutes,
  ...publicRoutes,
];
export default routes;
