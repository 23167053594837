import { useTranslation } from "react-i18next";

import { TButtons } from "../../components";

export const formatButtonsData = (
  title: string,
  handleLeftChoice: () => void,
  handleRightChoice: () => void,
): TButtons => {
  const { t } = useTranslation("questionFlow", { keyPrefix: "basicSteps" });
  return {
    firstButton: {
      title: t(`${title}.firstOption.title`),
      description: t(`${title}.firstOption.description`),
      onClick: handleLeftChoice,
    },
    secondButton: {
      title: t(`${title}.secondOption.title`),
      description: t(`${title}.secondOption.description`),
      onClick: handleRightChoice,
    },
  };
};
