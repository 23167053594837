import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack, Typography } from "@mui/material";

import { Button as GradientButton } from "@ui/index";
import { Text } from "@ui/typography";

import { HeartIcon } from "@assets/svg/copy-modal";

import { TAlbum } from "@store/actions/track";

import { CopyIcon } from "@/assets/svg";
import CreditText from "@/components/CreditText";
import { useIsDesktop } from "@/hooks/layout";
import { useIsClicked } from "@/hooks/useIsClicked";
import { creditText } from "@/utils/creditText";

interface ICopyPage {
  item?: TAlbum;
  composer: string;
  videoUrl: string;
  copyrightModal?: boolean;
}

const CopyPage: React.FC<ICopyPage> = ({ item, composer, videoUrl = "", copyrightModal = false }) => {
  const { t } = useTranslation("downloadModal");
  const isDesktop = useIsDesktop();

  const { isClicked, handleClick } = useIsClicked();

  const handleDonationClick = () => {
    document.getElementById("donation_popup_button").click();
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography
        variant={isDesktop ? "h3" : "h5"}
        textAlign="center"
        sx={{
          fontWeight: 500,
          color: "textColor.header",
          fontFamily: "Kaisei Opti",
          marginBottom: "24px",
        }}
      >
        {copyrightModal ? t("copyrightStep") : t("secondStep")}
      </Typography>

      <Typography
        textAlign="center"
        sx={{
          maxWidth: `100%`,
          fontSize: `${isDesktop ? "16px" : "14px"}`,
          fontWeight: 400,
          color: "textColor.common",
          fontFamily: "Raleway",
          marginBottom: "24px",
        }}
      >
        {t("creditText")}
      </Typography>

      <Stack
        sx={{
          maxWidth: `100%`,
          width: "100%",
          minHeight: "238px",
          border: "double 1px transparent",
          borderRadius: "8px",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",
          backgroundImage:
            "linear-gradient(#3d2e24, #3d2e24), linear-gradient(93deg, #BA905C 0.82%, #FFD29B 33.73%, #FFE0B1 47.65%, #FFD29B 61.53%, #BA905C 99.48%)",
        }}
      >
        <Stack width="100%" padding="24px" alignItems="center" spacing="24px">
          <Typography
            variant="h1"
            sx={{
              fontSize: `${isDesktop ? "16px" : "12px"}`,
              alignSelf: "center",
              textAlign: "center",
              lineHeight: "125%",
            }}
            fontWeight={400}
            color="textColor.common"
          >
            {item ? (
              <CreditText composer={composer} songName={item.name} videoUrl={videoUrl} />
            ) : (
              <CreditText composer={composer.split(" - ")[0]} songName={composer.split(" - ")[1]} videoUrl={videoUrl} />
            )}
          </Typography>

          <GradientButton
            isOutlined
            isFullWidth={!isDesktop}
            onClick={() => {
              handleClick();
              if (item) {
                return navigator.clipboard.writeText(creditText(`${composer} - ${item.name}`, videoUrl));
              }
              navigator.clipboard.writeText(creditText(composer, videoUrl));
            }}
            width={234}
          >
            <Stack direction="row" spacing="8px" justifyContent="center" alignItems="center">
              <CopyIcon />
              <Box>{t(isClicked ? "clicked" : "clickToCopy")}</Box>
            </Stack>
          </GradientButton>
        </Stack>
      </Stack>
      <Stack
        marginTop="24px"
        bgcolor="#1b9c93"
        padding="24px"
        spacing="24px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        borderRadius="8px"
      >
        <Text size={1.5} isHeading>
          {t("voluntaryHeader")}
        </Text>
        <Text isFullWidth color="rgb(255 247 237)" textAlign="center">
          {t("voluntaryDescription")}
        </Text>
        <GradientButton isFullWidth={!isDesktop} width={234} onClick={handleDonationClick}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing="8px">
            <HeartIcon />
            <Stack width="max-content">{t("voluntaryButton")}</Stack>
          </Stack>
        </GradientButton>
      </Stack>
    </Stack>
  );
};

export default CopyPage;
