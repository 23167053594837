export function getWithExpiry(key: string): {
  value: string | null;
  expiry: number | null;
} {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return { value: null, expiry: null };
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return { value: null, expiry: null };
  }
  return item;
}

export function setWithExpiry(
  key: string,
  value: string,
  secondToExpiration: number
): void {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + secondToExpiration,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
