import { getSoundExamplesByPage, TSoundExamplesByPage } from "@store/actions/soundExamples";

import { createSlice } from "@reduxjs/toolkit";
import { SoundExamplesPage } from "@utils/constants";

export type SoundExamplesState = {
  soundExamplesHomepage?: TSoundExamplesByPage;
  soundExamplesFreeMusic?: TSoundExamplesByPage;
  soundExamplesLicensing?: TSoundExamplesByPage;
  isLoading: boolean;
};

const initialState: SoundExamplesState = {
  isLoading: true,
};

const { reducer } = createSlice({
  name: "soundExamples",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSoundExamplesByPage.fulfilled, (state, { payload, meta }) => {
        switch (meta.arg) {
          case SoundExamplesPage.Home:
            state.soundExamplesHomepage = payload;
            break;
          case SoundExamplesPage.FreeUseLanding:
            state.soundExamplesFreeMusic = payload;
            break;
          case SoundExamplesPage.Licensing:
            state.soundExamplesLicensing = payload;
            break;
          default:
            break;
        }
        state.isLoading = false;
      })
      .addCase(getSoundExamplesByPage.pending, (state) => {
        state.isLoading = true;
      });
  },
});

export default reducer;
