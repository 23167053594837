import { IError } from "@models/inner-models";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SoundExamplesPage } from "@utils/constants";

import instance from "../../middleware/api";
import { TSoundExamplesByPageResponse } from "./types";

enum SoundExamples {
  GetByPage = "GET_BY_PAGE",
}

const BASE_URL = "/sound-examples";

export const getSoundExamplesByPage = createAsyncThunk(SoundExamples.GetByPage, async (page: SoundExamplesPage, thunkAPI) => {
  try {
    const resp = await instance.get<TSoundExamplesByPageResponse>(`${BASE_URL}/by-page`, { params: { page } });

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});
