import React, { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";

import { FormControl, FormControlLabel, Stack } from "@mui/material";

import { useIsDesktop } from "@hooks/layout";

import { EMetaDataType, METADATA_OTHER } from "@utils/constants";
import { TFunction } from "i18next";
import uniqueId from "lodash/uniqueId";

import { AdditionalTextField, Checkbox } from "@/ui-kit";

type TUserMetadataForm = {
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  t: TFunction;
  data: string[];
  otherField: string;
  setOtherField: (field: string) => void;
  metaDataType?: EMetaDataType;
} & PropsWithChildren;

export const UserMetadataForm: FC<TUserMetadataForm> = ({
  children,
  setSelected,
  selected,
  t,
  data,
  setOtherField,
  otherField,
  metaDataType = EMetaDataType.ClassifyContent,
}) => {
  const isDesktop = useIsDesktop();

  const maxHeight = metaDataType === EMetaDataType.ClassifyContent ? "354px" : "240px";

  const handleSetCategory = (category: string, isChecked: boolean) => {
    if (isChecked) {
      setSelected((prevState) => [...prevState, category]);
    } else {
      setSelected((prevState) => prevState.filter((value) => value !== category));
    }
  };

  const isChecked = (category: string) => {
    return !!selected.find((value) => category === value);
  };
  const isOtherSelected = !!selected.find((value) => value === METADATA_OTHER);

  return (
    <Stack width="100%" spacing={isDesktop ? "40px" : "24px"} overflow="hidden">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems={isDesktop ? "center" : "start"}
        flexWrap={isDesktop ? "wrap" : "nowrap"}
        maxHeight={isDesktop ? maxHeight : "100%"}
      >
        {data.map((category) => (
          <FormControl key={uniqueId("metadata_")} sx={{ width: isDesktop ? "60%" : "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(_, checked) => {
                    handleSetCategory(category, checked);
                  }}
                  checked={isChecked(category)}
                />
              }
              label={category}
              labelPlacement="end"
            />
          </FormControl>
        ))}
        <Stack marginTop={isDesktop ? "8px" : "16px"} width={isDesktop ? "40%" : "100%"} alignSelf="self-start">
          {isOtherSelected && (
            <AdditionalTextField
              value={otherField}
              onChange={(event) => setOtherField(event.target.value)}
              placeholder={t("other")}
            />
          )}
        </Stack>
      </Stack>

      <Stack width="100%" zIndex={10}>
        {children}
      </Stack>
    </Stack>
  );
};
