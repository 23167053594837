import { FC } from "react";

import { CircularProgress } from "@mui/material";

import * as Styled from "./styles";

type TBackdropComponent = {
  isLoading?: boolean;
};

export const LoaderComponent: FC<TBackdropComponent> = ({ isLoading = true }) => {
  if (isLoading) {
    return (
      <Styled.LoaderWrapper>
        <CircularProgress color="inherit" />
      </Styled.LoaderWrapper>
    );
  }
};
