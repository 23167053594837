import { Typography } from '@mui/material';

import styled from 'styled-components';

import { isSmallDesktop } from '@/hooks/layout';

export const MainTextTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.header};
  font-family: Kaisei Opti;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.125rem */
  letter-spacing: 0.01125rem;
  text-transform: capitalize;

  ${isSmallDesktop} {
    line-height: 140%; /* 2.1rem */
    letter-spacing: 0.015rem;
    font-size: 1.5rem;
  }
`;

export const AdditionalTextTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.textColor.common};
  text-align: center;
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */

  ${isSmallDesktop} {
    font-size: 1rem;
    line-height: 150%; /* 1.5rem */
  }
`
