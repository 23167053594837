import { FC, PropsWithChildren } from "react";

import { Stack } from "@mui/material";

import { ArrowRightIco } from "@/assets/svg";
import { useIsDesktop } from "@/hooks/layout";

const RequireLicense: FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useIsDesktop();

  return (
    <Stack
      width="100%"
      height="100%"
      direction="row"
      alignItems={isDesktop ? "center" : "start"}
      justifyContent="start"
      spacing={isDesktop ? "16px" : "8px"}
    >
      <Stack
        padding="4px"
        height="26px"
        sx={{ backgroundColor: "#3C2D23", borderRadius: "50%" }}
        alignItems="center"
        justifyContent="center"
      >
        <ArrowRightIco />
      </Stack>
      {children}
    </Stack>
  );
};

export default RequireLicense;
