import { Typography } from "@mui/material";

import styled from "styled-components";

import { isSmallDesktop, isSmallMobile } from "@/hooks/layout";

export const HeroMainTextTypography = styled(Typography)<{
  $isHighlighted?: boolean;
}>`
  font-family: Kaisei Opti;
  font-size: 3rem;
  line-height: 135%;
  letter-spacing: 0.03rem;
  text-transform: capitalize;
  font-weight: 400;
  max-width: 750px;

  color: ${({ theme }) => theme.palette.textColor.header};

  span {
    color: #ffdca8;
  }

  ${isSmallDesktop} {
    font-weight: 500;
    font-size: 5rem;
    line-height: 110%;
    letter-spacing: 0.05rem;
  }

  ${isSmallMobile} {
    font-size: 2.5rem;
  }
`;

export const HeroDescriptionTypography = styled(Typography)`
  font-family: Raleway;
  font-size: 0.875rem;
  line-height: 1.31rem;
  font-weight: 400;

  color: ${({ theme }) => theme.palette.textColor.common};

  ${isSmallDesktop} {
    font-size: 1.125rem;
    line-height: 1.69rem;
  }
`;

export const MainTextTypography = styled(Typography)<{
  $isHighlighted?: boolean;
}>`
  max-width: 800px;
  font-family: Kaisei Opti;
  font-size: 1.75rem;
  line-height: 2.45rem;
  letter-spacing: 0.0175rem;
  text-transform: capitalize;

  color: ${({ $isHighlighted, theme }) =>
          $isHighlighted
                  ? theme.palette.textColor.header
                  : theme.palette.textColor.common};

  ${isSmallDesktop} {
    font-size: 2.25rem;
    line-height: 3.15rem;
    letter-spacing: 0.0225rem;
  }
`;

export const DescriptionTypography = styled(Typography)`
  max-width: 950px;
  font-family: Raleway;
  font-size: 0.875rem;
  line-height: 1.31rem;
  font-weight: 400;

  color: ${({ theme }) => theme.palette.textColor.common};

  ${isSmallDesktop} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const SubMainTextTypography = styled(Typography)<{
  $isHighlighted?: boolean;
}>`
  font-family: Kaisei Opti;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.01125rem;

  text-align: center;
  text-transform: capitalize;

  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted
      ? theme.palette.textColor.header
      : theme.palette.textColor.common};

  ${isSmallDesktop} {
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
  }
`;
