import React, { FC } from "react";

import { ChipProps } from "@mui/material";

import { DeleteFilterIcon } from "@assets/svg";

import * as Styled from "./styles";

type TChip = ChipProps;

export const Chip: FC<TChip> = (props) => {
  return <Styled.Chip {...props} deleteIcon={<DeleteFilterIcon width="21px" height="21px" />} />;
};
