import { BreakpointsOptions } from '@mui/material';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 1250,
    lg: 1810,
    xl: 2260,
  },
};

export default breakpoints;
