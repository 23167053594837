import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "@ui/typography";

import { Button } from "@/ui-kit";

import * as Styled from "../styles";
import { THandleNextStepProp } from "../utils";

export const FindOut: FC<Pick<THandleNextStepProp, "handleLeftChoice">> = ({ handleLeftChoice }) => {
  const { t } = useTranslation("questionFlow", { keyPrefix: "basicSteps.start" });
  return (
    <Styled.ContentWrapper>
      <Text isHeading size={1.5} isFullWidth textAlign="center" mb="17px" lineHeight={140}>
        {t("title")}
      </Text>
      <Text isFullWidth textAlign="center" mb="32px">
        {t("description")}
      </Text>
      <Button isBigButton isFullWidth onClick={() => handleLeftChoice()}>
        {t("buttonText")}
      </Button>
    </Styled.ContentWrapper>
  );
};
