import {
  getDeals,
  getDealTags,
  getSliderDeals,
  TDealResponse,
  TDealTagsResponse,
  TSliderDealResponse,
} from "@store/actions/deals";

import { createSlice } from "@reduxjs/toolkit";

export type DealsState = {
  deals?: TDealResponse;
  sliderDeals?: TSliderDealResponse;
  isDealsLoading: boolean;
  isSliderDealsLoading: boolean;
  dealTags?: TDealTagsResponse;
  isDealTagsLoading: boolean;
};

const initialState: DealsState = {
  isDealsLoading: true,
  isSliderDealsLoading: true,
  isDealTagsLoading: true,
};

const { reducer } = createSlice({
  name: "deals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeals.fulfilled, (state, { payload }) => {
        state.deals = payload;
        state.isDealsLoading = false;
      })
      .addCase(getSliderDeals.fulfilled, (state, { payload }) => {
        state.sliderDeals = payload;
        state.isSliderDealsLoading = false;
      })
      .addCase(getDealTags.fulfilled, (state, { payload }) => {
        state.dealTags = payload;
        state.isDealTagsLoading = false;
      })
      .addCase(getDeals.pending, (state) => {
        state.isDealsLoading = true;
      })
      .addCase(getSliderDeals.pending, (state) => {
        state.isSliderDealsLoading = true;
      })
      .addCase(getDealTags.pending, (state) => {
        state.isDealTagsLoading = true;
      });
  },
});

export default reducer;
