import { createAsyncThunk } from "@reduxjs/toolkit";

import { IError, INewsletter } from "@/models/inner-models";

import { ENewsletter } from "../constants";
import instance from "../middleware/api";

export const newsletter = createAsyncThunk(
  ENewsletter.uploadNewsletter,
  async (newsletterContactInfo: INewsletter, thunkAPI) => {
    try {
      return await instance.post("/contact-us/newsletter", newsletterContactInfo);
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  },
);
