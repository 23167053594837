import React, {  useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import { DescriptionTypography } from '@/components/LoginDialog/components';
import {  HandleNextContext } from '@/components/RecoveryDialog';
import { MainTextTypography } from '@/components/RecoveryDialog/components';
import Form from '@/components/RecoveryDialog/Form';
import { useIsDesktop } from '@/hooks/layout';
import { ERecovery } from '@/layouts/SecondRootLayout';

const FirstStep = () => {
  const { t } = useTranslation('login', {
    keyPrefix: 'passwordRecovery.firstStep',
  });

  const isDesktop = useIsDesktop();

  const currentStep = useContext(HandleNextContext);

  return (
    <Stack
      spacing={isDesktop ? '40px' : '24px'}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={isDesktop ? '24px' : '16px'} height="100%" width="100%">
        <Stack spacing="16px" justifyContent="center" alignItems="center">
          <MainTextTypography>{t('mainText')}</MainTextTypography>
          <DescriptionTypography>
            <Trans t={t} i18nKey="description" />
          </DescriptionTypography>
        </Stack>
      </Stack>

      <Form
        step={ERecovery.FirstStep}
        buttonText={t('buttonText')}
        handleNextStep={currentStep?.handleNextStep}
      />
    </Stack>
  );
};

export default FirstStep;
