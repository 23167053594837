import { MenuItem,MenuList } from "@mui/material";

import styled from "styled-components";

export const MenuWrapper = styled(MenuList)`
  && {
    background-color: #4b3727;
    border: 1px solid #ffdca8;
    padding: 8px;
    border-radius: 4px;
  }
`;

export const MenuItemWrapper = styled(MenuItem)`
    display: flex;
    align-items: center;
    gap: 8px;

    svg,
    img {
        width: 20px;
        height: 20px;
    }
`;
