import { IconButton } from "@mui/material";

import styled, { css } from "styled-components";

type TButton = {
  $isBigSize: boolean;
  $isSoundExample: boolean;
  $isMobileBottomPlayer: boolean;
};

export const Button = styled(IconButton)<TButton>`
  padding: 0;
  width: ${({ $isBigSize }) => ($isBigSize ? "64px" : "32px")};
  height: ${({ $isBigSize }) => ($isBigSize ? "64px" : "32px")};

  ${({ $isSoundExample }) =>
    $isSoundExample &&
    css`
      width: 80px;
      height: 80px;
    `}
  ${({ $isMobileBottomPlayer }) =>
    $isMobileBottomPlayer &&
    css`
      width: 40px;
      height: 40px;
    `}

  border-radius: 100px;

  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;

  &:hover {
    box-shadow: 0 2px 20px 0 rgba(255, 215, 163, 0.5);
  }
`;
