import { logout, refreshToken } from "@store/actions/user";
import instance from "@store/middleware/api";

import { ELocalStoreKeys } from "@models/models";
import { AxiosError, AxiosResponse } from "axios";

const getToken = () => {
  const itemStr = localStorage.getItem("accessToken");
  return itemStr ? JSON.parse(itemStr).value : "";
};

const getRefreshToken = () => {
  const itemStr = localStorage.getItem(ELocalStoreKeys.REFRESH_TOKEN);
  return itemStr ? JSON.parse(itemStr).value : "";
};

export const handleRequest = (config: any) => {
  const accessToken = getToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

export const handleSuccessResponse = (response: AxiosResponse) => {
  return response;
};

let isRefreshing = false;
let failedQueue: Array<any> = [];
const processQueue = (error: AxiosError | Error | null | unknown, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const handleErrorResponse = (error: any, store: any) => {
  const { response } = error;
  const refreshTokenStr = getRefreshToken();
  const token = getToken();

  if (!response) {
    return Promise.reject(error);
  }
  const errorMsg = {
    status: response.status,
    message: response.data.message || "",
    statusText: response.statusText,
  };

  if (response.status >= 500 && response.status < 600) {
    errorMsg.statusText = "Internal server error";
    errorMsg.message = "Server response with error, please try again later";
  }
  if (response.status === 401) {
    if (!refreshTokenStr || response.config.url === "/auth/refresh-session") {
      store.dispatch(logout({ accessToken: token, refreshToken: refreshTokenStr }));
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(() => instance(originalRequest))
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      store
        .dispatch(
          refreshToken({
            headers: { "Refresh-Token": `${refreshTokenStr}` },
          }),
        )
        .then(() => {
          const updatedAccessToken = getToken();

          processQueue(null, updatedAccessToken);
          resolve(instance(originalRequest));
        })
        .catch(async (err: unknown) => {
          processQueue(err, null);
          await store.dispatch(logout({ accessToken: token, refreshToken: refreshTokenStr }));
          reject(err);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error.response.data);
};
