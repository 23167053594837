enum EUser {
  loginUser = 'auth/login',
  getMy = 'users/get-my',
  deleteMy = 'delete/my',
  patchMy = 'users/patch-my',
  sendVerificationCode = 'users/send-verification-code',
  verifyCode = 'users/verify-code',
  registerUser = 'auth/register',
  logOutUser = 'auth/log-out',
  refreshToken = 'auth/refresh-token',
  sendVerificationLink = 'auth/send-verification-link',
  updatePassword = 'auth/update-password',
  resetPassword = 'auth/reset-password',
  updateEmail = 'auth/update-email',
}

export default EUser;
