import { Typography } from "@mui/material";

import styled from "styled-components";

export const SecondHeaderContainer = styled.header`
  width: 100%;
  background-color: #59412e;
  position: relative;
`;

export const SecondaryBurgerMenuNavLink = styled.p`
  text-decoration: none;
  font-size: 24px;
  letter-spacing: 0.01rem;
  text-transform: uppercase !important;
  line-height: 1.4rem;
  font-family: Raleway;

  font-weight: 400;

  color: ${({ theme }) => theme.palette.header.textColor};

  svg {
    path {
      fill: ${({ theme }) => theme.palette.header.textColor};
    }
  }
`;

export const CopyrightTypography = styled(Typography)`
  color: rgb(199 193 189 / 70%);
  font-family: Raleway;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.225rem;
  letter-spacing: 0.00875rem;
  text-transform: capitalize;
  text-align: center;

  a {
    color: rgb(199 193 189 / 70%);
    font-family: Raleway;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.225rem;
    letter-spacing: 0.00875rem;
    text-transform: capitalize;
  }
`;
