import { Button, Stack } from "@mui/material";

import { isMobile, isSmallDesktop } from "@hooks/layout";

import styled, { css } from "styled-components";

type TText = {
  $isToggleFirstElement: boolean;
  $isCatalog: boolean;
};

export const Wrapper = styled(Stack)<Omit<TText, "$isToggleFirstElement"> & { $isCatalogLanding: boolean }>`
  border-radius: 8px;
  background: ${({ theme, $isCatalog }) =>
    $isCatalog ? "rgba(75, 55, 39, 0.5)" : theme.palette.gradientButton.mainGradient};
  min-width: 311px;
  min-height: 48px;

  width: 100%;
  height: 100%;

  ${isMobile} {
    border-radius: ${({ $isCatalogLanding }) => $isCatalogLanding && 0};
  }

  ${isSmallDesktop} {
    min-width: 485px;
    max-width: ${({ $isCatalog }) => ($isCatalog ? "100%" : 600)};
    min-height: 56px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 4px;
  gap: 4px;
  width: 100%;
  height: 100%;
`;

export const LeftText = styled.div<TText>`
  z-index: 1;

  border-radius: 8px;

  width: 50%;

  padding: 13px 12px;

  font-family: Kaisei Opti;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0.00875rem;

  background: ${({ $isToggleFirstElement }) => ($isToggleFirstElement ? "#4B3727" : "transparent")};

  ${({ $isCatalog, $isToggleFirstElement }) =>
    $isCatalog
      ? css`
          color: ${$isToggleFirstElement ? "#FFDCA8" : "#AC8D75"};
        `
      : css`
          color: ${$isToggleFirstElement ? "#FBF4EC" : "#4B3628"};
        `}
  &:hover {
    color: ${({ $isCatalog }) => ($isCatalog ? "#FFDCA8" : "#fbf4ec")};

    background: ${({ $isToggleFirstElement }) => ($isToggleFirstElement ? "#4B3727" : "rgba(75, 55, 39, 0.5)")};
  }

  ${isSmallDesktop} {
    padding: 14px 25px;
    font-size: 1.125rem;
    line-height: 1.25rem;
    letter-spacing: 0.01125rem;
  }
`;

export const RightText = styled.div<TText>`
  z-index: 1;

  border-radius: 8px;

  width: 50%;

  padding: 13px 12px;

  font-family: Kaisei Opti;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0.00875rem;

  background: ${({ $isToggleFirstElement }) => ($isToggleFirstElement ? "#transparent" : "#4B3727")};

  ${({ $isCatalog, $isToggleFirstElement }) =>
    $isCatalog
      ? css`
          color: ${$isToggleFirstElement ? "#AC8D75" : "#FFDCA8"};
        `
      : css`
          color: ${$isToggleFirstElement ? "#4B3628" : "#FBF4EC"};
        `}
  &:hover {
    color: ${({ $isCatalog }) => ($isCatalog ? "#FFDCA8" : "#fbf4ec")};

    background: ${({ $isToggleFirstElement }) => ($isToggleFirstElement ? "rgba(75, 55, 39, 0.5)" : "#4B3727")};
  }

  ${isSmallDesktop} {
    padding: 14px 25px;
    font-size: 1.125rem;
    line-height: 1.25rem;
    letter-spacing: 0.01125rem;
  }
`;
