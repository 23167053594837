import { forwardRef } from "react";

import { Stack } from "@mui/material";

import { Text } from "@ui/typography";

import * as Styled from "../styles";

type TChoiceButton = {
  title: string;
  description: string;
  onClick: () => void;
};

export type TButtons = {
  firstButton: TChoiceButton;
  secondButton: TChoiceButton;
};

type TChoiceButtons = {
  buttons: TButtons;
};

export const ChoiceButtons = forwardRef<HTMLDivElement, TChoiceButtons>((props, ref) => {
  const buttons = Object.values(props.buttons);

  return (
    <Styled.ChoiceButtonsWrapper ref={ref}>
      {buttons.map(({ description, title, onClick }, index) => (
        <Styled.ChoiceButton key={index} onClick={onClick}>
          <Stack spacing="8px" height="100%" alignItems="center">
            <Text size={1.125} fontWeight={600} isHeading>
              {title}
            </Text>
            <Text size={0.875} isFullWidth>{description}</Text>
          </Stack>
        </Styled.ChoiceButton>
      ))}
    </Styled.ChoiceButtonsWrapper>
  );
});
